export type dashboardCard = {
  header: string;
  body: string;
  url: string;
  urlText: string;
  newTab?: boolean;
};

const managerDashboardLinks: dashboardCard[] = [
  {
    header: "說明頁",
    body: "閱讀關於如何使用我們的工具來安全地儲存參與者信息、管理調查以及為參與者創建圖表。",
    url: "/tw/manager/guide",
    urlText: "幫助頁",
  },
  {
    header: "參與者中心",
    body: "建立新的參與者ID，產生新的調查連結，並取得所有中心參與者的一般資料。",
    url: "/tw/manager/pid-browser",
    urlText: "PID 中心",
  },
  {
    header: "圖表瀏覽器",
    body: "查看您中心的所有參與者圖表，與早期的調查進行比較，查看總得分，再執行進階搜尋。",
    url: "/tw/manager/graph-browser",
    urlText: "圖表瀏覽器",
  },
  {
    header: "示範調查",
    body: "基於示範的目的，請填寫並提交一份蘭德SF-36調查表，這份表單使用後將不予保存。",
    url: "/demo",
    urlText: "示範",
    newTab: true,
  },
];

export default managerDashboardLinks;
