import React from "react";

export default () => {
  return (
    <div className="instructionsCard">
      <h1>Routine Outcome monitoring Instructions</h1>
      <div>
        <h2>Words</h2>
        <div>
          <h4>PID:</h4>
          <p>
            Participant Identification Number, a four-digit number assigned to a
            participant by our system to identify them for surveys using the
            Routine Outcome Monitoring tools
          </p>
        </div>
        <div>
          <h4>RAND SF-36:</h4>
          <p>
            A 36-question survey created by RAND as part of the Medical Outcome
            Study.
          </p>
        </div>
      </div>
      <div>
        <h2>Introduction</h2>
        <p>
          The Routine Outcome Monitoring tool provides practitioners and survey
          participants a way to track their quality of life in eight major
          categories. Site Managers are able to anonymously sign up new
          participants and have them fill out the survey and generate instant
          graphic representation of the results.{" "}
        </p>
      </div>
      <div>
        <h2>Manager Dashboard</h2>
        <p>
          The Manager Dashboard is the central hub for your account. It has
          links to the PID Browser, where you can create PIDs to assign to
          participants and initiate surveys for them to fill out, the Graph
          Browser, to create graphs outlining your particiapnts' progress, and a
          quick access to the demo page of the survey, so you can show new or
          perspective clients how the survey and graphing process works.
        </p>
      </div>
      <div>
        <h2>PID Center</h2>
        <p>
          The PID Center provides many functions for you, the Site Manager, of
          your portal. When you first use the page, you will only see a single
          button to "Generate new PID". Clicking on this will create a random
          4-digit Participant Id number for you to use. You assign this number
          to someone going through your program and you will use this number to
          track them through the WOM system. For confidentiality purposes, WOM
          does not allow Site Managers to store personal information regarding
          their particiapnts in the application, other than their survey data.
          Because of this, it is up to YOU to securely store information as to
          who is associated with which PID.
        </p>
        <p>
          For participants to start filling out a survey, you must generate a
          survey link first. To create a survey link for a specific participant,
          simply press the green GENERATE button associated with the PID on the
          PID table. This will then create a unique, one-time only URL for you
          to use. You can left-click on this link and it will open a new tab in
          your web browser with the RAND SF-36 survey for a participant to fill
          out on-location and on your computer. This survey is uniquely tied TO
          THAT PID. Filling it out will create a new survey in the database for
          that participant only.{" "}
        </p>
        <p>
          Alternatively, instead of left-clicking on the link, you can
          right-click on the link and copy it to your clipboard. You can then
          paste this link into an email and send it to the participant. Upon
          receiving your email, they can click on the link themselves and it
          will open up the survey on their computer or smartphone, and they can
          fill the survey out on their own. This is most helpful for follow-up
          surveys for participants that cannot come in to fill out. As with
          on-site surveys, this link is uniquely tied to that participant, and
          is a one-time use URL.
        </p>
        <p>
          When a participant is finished filling out their survey, a thank you
          message will appear, telling them the survey is complete. Unlike in
          the demonstration version of the survey, their results will NOT
          immediately show up on their screen. This is to ensure they have an
          opprotunity to consult with you over the survey results.
        </p>
      </div>
      <div>
        <h2>Survey Center</h2>
        <p>
          The Survey Center allows you to view and save graphs taken from
          participant survey data across the eight RAND SF-36 survey catagories.
          When in the Survey Center, select a PID from the drop-down menu which
          you wish to view, and a graph will come up with their results. The
          default surveys displayed are the RAND US population norms, and any
          surveys the selected participant has filled out. Clicking the labels
          at the bottom of the graph will disable that survey and hide it from
          the graph. Clicking a disabled survey will re-enable it again and
          display it on the graph. This gives you full control of comparing
          whichever surveys you want to for optimal analysis.
        </p>
        <p>
          To save graphs on your local machine, select the hamburger menu in the
          top right of the graph frame (the three veritical bars) and choose
          which format you would live to save the results as.
        </p>
      </div>
      <div>
        <h2>Account Settings</h2>
        <p>
          {" "}
          The Account Settings page allows you to update your profile settings,
          such as password for your account.
        </p>
      </div>
    </div>
  );
};
