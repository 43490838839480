import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "../../../components/admin/Select";
import LoadingMsg from "../../../components/LoadingMsg";
import GraphBrowser from "../../../components/admin/GraphBrowser";

interface Director {
  name: string;
  id: string;
  email: string;
  role: string;
  createdAt: Date;
  updatedAt: Date;
}

export const GraphBrowserPage = () => {
  const [directorId, setDirectorId] = useState("");
  const [directorName, setDirectorName] = useState("");
  const [loadedDirectors, setLoadedDirectors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDirectorChange = async (e: any) => {
    const directorId = e.target.value;
    const directorName: Director = loadedDirectors.filter(
      (director: { value: string }) => director.value === directorId
    )[0];
    console.log("DIRECTOR: ", directorName);
    if (directorId === "defaultValue") return;
    setDirectorId(directorId);
    setDirectorName(directorName.name || "NULL");
  };

  const fetchDirectors = async () => {
    const query = "/api/v1/directors/?select=name";
    setIsLoading(true);
    const res = await axios.get(query);
    const directors = res.data.data.map((director: Director) => {
      return { value: director.id, name: director.name };
    });
    setLoadedDirectors(directors);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDirectors();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingMsg lang="" />
      ) : (
        <>
          <Select
            arr={loadedDirectors}
            selectedItem={directorId}
            handleChange={(e: any) => handleDirectorChange(e)}
            defaultMsg="Select Region"
            defaultLabel="Select Region"
          />
          {!!directorId && (
            <GraphBrowser dirId={directorId} dirName={directorName} />
          )}
        </>
      )}
    </>
  );
};
