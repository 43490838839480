import axios from "axios";
import {
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

// Load User
export const loadAdmin = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/api/v1/adminAuth/me");
    dispatch({
      type: USER_LOADED,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = ({
  email = null,
  password = null,
  role = null,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password, role });

  try {
    const res = await axios.post("/api/v1/adminAuth/register", body, config);
    return { success: true, data: res };
  } catch (err) {
    const errors = err.response.data.error.split(",");

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error, "danger")));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login Admin
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/v1/adminAuth/login", body, config);
    if (!res.data.success) {
      dispatch(setAlert(res.data.error, "danger"));
      dispatch({
        type: LOGIN_FAIL,
      });
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      dispatch(loadAdmin());
    }
  } catch (err) {
    const errors = err.response.data.error.split(",");

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout / Clear Profile
export const logout = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  await axios.get("/api/v1/auth/logout", config);

  dispatch({ type: LOGOUT });
};
