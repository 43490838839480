import React, { useState, useEffect } from "react";
import axios from "axios";

import { User } from "./PIDBrowserWrapper";

import DeleteUserModal from "./DeleteUserModal";
import ManagePermissionsModal from "./ManagePermissionsModal";

// Style components
import Modal from "../../modal/modal.component";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

interface Props {
  user: User;
  fetchUsersFunc?: () => void;
}

export default ({ user, fetchUsersFunc }: Props) => {
  const [surveyURL, setSurveyURL] = useState("");
  const [loadingSurveyURL, setLoadingSurveyURL] = useState(false);
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  useEffect(() => {
    if (user.surveyToken) {
      const url = convertTokenToURL(user.surveyToken);
      setSurveyURL(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSurveyToken = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoadingSurveyURL(true);
    const surveyToken = await axios.post(`/api/v1/auth/survey/${user.id}`);
    const surveyTokenURL = convertTokenToURL(surveyToken.data.data);
    setLoadingSurveyURL(false);
    setSurveyURL(surveyTokenURL);
  };

  const convertTokenToURL = (tokenURL: string) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const url = `${protocol}//${host}/survey/${tokenURL}`;
    return url;
  };

  const generateBtn = () => {
    if (loadingSurveyURL) {
      return (
        <Button>
          <Spinner
            animation="border"
            role="loading"
            size="sm"
            aria-hidden="true"
          />
        </Button>
      );
    }
    return (
      <Button onClick={(e) => fetchSurveyToken(e)}>
        <span className="btn__text">產生</span>
      </Button>
    );
  };

  const ModalBody = () => (
    <div>
      <h3>PID：{user.pid}</h3>
      <h5>下一份調查 {user.surveyCount + 1}</h5>
      <p>請按下方「產生」按鍵來生成一個連結</p>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text>網址</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={surveyURL ? surveyURL : ""} />
        <InputGroup.Append>
          {!surveyURL ? (
            generateBtn()
          ) : (
            <Button disabled variant="success">
              完畢！
            </Button>
          )}
        </InputGroup.Append>
        {surveyURL && (
          <Form.Text muted>
            此參與者現在是 <strong>開始調查</strong>.
            的狀態。這表示他們可以利用上面的連結去填寫及提交調查表，當他們提交調查表後，他們將被標示為{" "}
            <strong>關閉調查</strong>
            ，除非你再為他們創建一份新的調查表連結，否則他們無法再提交任何調查表。
          </Form.Text>
        )}
      </InputGroup>
    </div>
  );

  return (
    <>
      <Modal
        show={show}
        header={"生成PID調查表"}
        setShow={setShow}
        JSXComponent={ModalBody}
        lang="tw"
        rAlign={true}
      />
      <Modal
        show={showDeleteModal}
        header={"刪除PID"}
        setShow={setShowDeleteModal}
        onHide={fetchUsersFunc}
        JSXComponent={() => <DeleteUserModal user={user} />}
        lang="tw"
        rAlign={true}
      />
      <Modal
        show={showPermissionsModal}
        header={"同意權限"}
        setShow={setShowPermissionsModal}
        JSXComponent={() => <ManagePermissionsModal user={user} />}
        lang="tw"
        rAlign={true}
      />
      <tr className={"table__row" + (user.isNew ? " new" : "")}>
        <td>
          {user.pid}{" "}
          {user.surveyCount === 0 && <Badge variant="secondary">新的</Badge>}
        </td>
        <td>{user.surveyCount + 1}</td>
        <td>
          <Button onClick={() => setShow(true)} size="sm">
            創建調查鏈接
          </Button>
        </td>
        {/* <td>
          <Button
            onClick={() => setShowPermissionsModal(true)}
            size="sm"
            variant="info"
          >
            Gestionar
          </Button>
        </td> */}
        <td>
          <Button
            onClick={() => setShowDeleteModal(true)}
            size="sm"
            variant="danger"
            className="m-auto"
          >
            刪除
          </Button>
        </td>
      </tr>
    </>
  );
};
