import React from "react";

export const Message = ({ msg, setMsg }) => {
  return (
    <div className="alert">
      {msg}
      <span
        onClick={() => setMsg("")}
        style={{
          float: "right",
          cursor: "pointer"
        }}
      >
        x
      </span>
    </div>
  );
};
