import React, { useState, ChangeEvent } from "react";
import { randQuestionsAndAnswersAllLangs } from "../../data/rand36QuestionsBase";
import { questionAndAnswers, header } from "../../data/randsf36";
import { Question } from "./Question.component";
import { Select } from "./utils";
import { Link } from "react-router-dom";
import Pdf from "../consents/sample.pdf";

import losmolPdf from "../consents/losmel.pdf";
import mediPdf from "../consents/medi.pdf";
import taiwanPdf from "../consents/taiwan.pdf";
import yunlinPdf from "../consents/yunlin.pdf";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

type consentRegionType =
  | "general"
  | "eu"
  | "losmol"
  | "medi"
  | "yunlin"
  | "taiwan";

interface Props {
  onSubmit: (answers: number[], consented: boolean) => void;
  consentRegion?: consentRegionType;
}

/**
 * Rand SF 36 survey.
 * @param {function(number[])} onSubmit callback to run when form is submitted, must take in array of numbers submit. Passes through answers array
 * @param {consentRegionType} consentRegion optional param for consent region used in survey. Defaults to "general"
 * @version 1.1.0 added Taiwanese compatability
 */
const Rand36 = ({ onSubmit, consentRegion }: Props) => {
  const [questions, setQuestions] = useState<Array<number>>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentHeader, setCurrentHeader] = useState<number | null>();
  const [language, setLanguage] = useState<questionAndAnswers[]>([]);
  const [lang, setLang] = useState<string>("");
  const [headers, setHeaders] = useState<header[]>([]);
  const [consented, setConsented] = useState(false);

  const consentPDF = () => {
    return consentRegion === "eu"
      ? Pdf
      : consentRegion === "losmol"
      ? losmolPdf
      : consentRegion === "medi"
      ? mediPdf
      : consentRegion === "taiwan"
      ? taiwanPdf
      : consentRegion === "yunlin"
      ? yunlinPdf
      : `/consent/${lang}`;
  };

  const displayConsentForm = () => {
    return (
      <div style={{ marginTop: "1.5rem" }}>
        <input
          name="consentBox"
          type="checkbox"
          checked={consented}
          onChange={() => setConsented(!consented)}
        />{" "}
        I have read and agree to the{" "}
        <Link to={() => consentPDF()} target="_blank">
          Consent to Release Health Information form
        </Link>
        <div>
          The SF 36 was developed at RAND as part of the Medical Outcome Study.
        </div>
      </div>
    );
  };

  const selectAnswer = (i: number, value: number) => {
    let Qs: number[] = [];
    questions.forEach((question) => {
      Qs.push(question);
    });
    Qs[i] = value;
    setQuestions(() => Qs);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion === Qs.length && nextQuestion < language.length) {
      setCurrentQuestion(nextQuestion);
      changeCurrentHeader(nextQuestion);
    }
  };

  const changeQuestion = (value: number) => {
    const question = currentQuestion + value;
    if (question >= 0 && question <= questions.length) {
      setCurrentQuestion((state) => question);
      changeCurrentHeader(question);
    }
  };

  const changeCurrentHeader = (currentQ: number) => {
    const currentHeader =
      currentQ >= 2 && currentQ < 12
        ? 0
        : currentQ >= 12 && currentQ < 16
        ? 1
        : currentQ >= 16 && currentQ < 19
        ? 2
        : currentQ >= 22 && currentQ < 31
        ? 3
        : currentQ >= 32 && currentQ < 36
        ? 4
        : null;
    setCurrentHeader(currentHeader);
  };

  const displayQuestions = () => {
    const i = currentQuestion;
    const question = language[i];
    const currentValue = questions[i];
    return (
      <Question
        key={i}
        questionIndex={i}
        questionText={question.questionText}
        answers={question.answers}
        currentValue={currentValue}
        selectAnswer={selectAnswer}
      />
    );
  };

  const options = randQuestionsAndAnswersAllLangs.map((l, key) => ({
    value: key,
    label: l.language,
  }));

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selection: number = +e.target.value;
    if (typeof selection === "number") {
      setLanguage(
        randQuestionsAndAnswersAllLangs[selection].questionsAndAnswers
      );
      setLang(randQuestionsAndAnswersAllLangs[selection].language);
      setHeaders(randQuestionsAndAnswersAllLangs[selection].headers);
    }
  };

  return (
    <Container className="my-5">
      <div style={{ marginBottom: "2rem" }}>
        <h1>Routine Outcome Monitoring Survey</h1>
        <h2>RAND Short-Form 36</h2>
      </div>
      {language.length === 0 ? (
        <div
          style={{ display: "block", margin: "1rem auto", textAlign: "center" }}
        >
          <Select
            options={options}
            currentSelection={lang}
            handleChange={handleLanguageChange}
            defaultValue={"Select Language"}
            style={{ margin: "1rem auto", padding: "0.5rem 1rem" }}
          />
        </div>
      ) : (
        <div className="randBody">
          {
            <div className="questionHeader">
              {currentHeader != null && headers[currentHeader]}
            </div>
          }
          {displayQuestions()}
          {
            <div className="controlButtonsMenu">
              {currentQuestion > 0 && (
                <Button className="mr-3" onClick={() => changeQuestion(-1)}>
                  Back
                </Button>
              )}
              {currentQuestion < questions.length &&
              currentQuestion < language.length - 1 ? (
                <Button className="" onClick={() => changeQuestion(1)}>
                  Next
                </Button>
              ) : (
                questions.length > 35 && (
                  <Button
                    className=""
                    onClick={() => onSubmit(questions, consented)}
                  >
                    Submit Survey
                  </Button>
                )
              )}
            </div>
          }
          {questions.length > 35 && displayConsentForm()}
        </div>
      )}
    </Container>
  );
};

export default Rand36;
