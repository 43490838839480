import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
// import GraphBrowser from "../admin/GraphBrowser";
import { RegisterDirector } from "../register-director/index";
import AdminLogin from "../../pages/admin/login.component";

import Quickbar from "../../components/routes/AdminQuickbar.component";

// styles components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// pages
import AdminDashboard from "../../pages/admin/adminDashboard";
import settings from "../../pages/admin/settings.component";
import instructions from "../../pages/admin/instructions.component";
import { GraphBrowserPage } from "../../pages/admin/graph-browser/index.ts";

const AdminRoute = ({ auth }) => {
  const isLoggedIn = !!auth.user;
  const isAdmin = isLoggedIn ? auth.user.role === "admin" : false;
  const isDirector = isLoggedIn ? auth.user.role === "director" : false;

  if (!isAdmin && !auth.loading) {
    return <AdminLogin />;
  } else if (isAdmin || isDirector) {
    return (
      <Router>
        <Container className="mt-5">
          <Row>
            <Col xs lg="3">
              <Route path={"/admin"} component={Quickbar} />
            </Col>
            <Col lg="8">
              <Switch>
                <Route
                  path="/admin"
                  render={(props) => (
                    <AdminDashboard permissions={auth.user.role} />
                  )}
                  exact
                />
                {/* <Route path="/admin/graph-browser" component={GraphBrowser} /> */}
                <Route path="/admin/graph-browser" component={GraphBrowserPage} />
                {/* <Route
                  path="/admin/register-manager"
                  component={RegisterManager}
                /> */}
                {/* <Route
                  path="/admin/register-center"
                  component={RegisterCenter}
                /> */}
                <Route
                  path="/admin/register-director"
                  component={RegisterDirector}
                />
                <Route path="/admin/settings" component={settings} />
                <Route path="/admin/guide" component={instructions} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </Router>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AdminRoute);
