import axios from "axios";

type PID = {
  center: string;
  createdAt: string;
  id: string;
  pid: string;
  role?: string;
  surveyCount: number;
  surveyToken?: string;
  surveyTokenExpire?: string;
};

export const fetchUsers = async (centerId: string): Promise<PID[]> => {
  const query = `/api/v1/centers/${centerId}/users`;
  const res = await axios.get(query);
  const users: PID[] = res.data.data;
  return users;
};
