import React, { useState, useEffect } from "react";
import RandChart from "../../rand36/EsRandChart";
import axios from "axios";
import LoadingMsg from "../../LoadingMsg";
import { useSelector } from "react-redux";

// Styles components
import Form from "react-bootstrap/Form";

const GraphBrowser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [users, setUsers] = useState({});
  const [selectedUsers, setSelectedUsers] = useState({});

  const store = useSelector((store) => store);
  console.log("USER: ", store.auth.user);
  // const { users, addUsers } = useContext(ParticipantsSurveysContext);

  const fetchUsers = async (center) => {
    setIsLoading(true);
    const query = `/api/v1/centers/${store.auth.user.id}/users`;
    const res = await axios.get(query);
    let users = {};
    res.data.data.forEach(
      (user) => user.surveyCount > 0 && (users[user.id] = user.pid)
    );
    setUsers(users);
    setSelectedUsers(users);
    await fetchSurveys(store.auth.user.id);
    setIsLoading(false);
  };

  const selectAllUsers = () => {
    if (Object.entries(selectedUsers).length === Object.entries(users).length) {
      setSelectedUsers({});
      setSelectedSurveys([]);
    } else {
      const aggregatedSurveys = aggregateSurveys(surveys);
      setSelectedUsers(users);
      setSelectedSurveys(aggregatedSurveys);
    }
  };

  const aggregateSurveys = (arrayOfSurveys) => {
    // const numOfQuestions = 36;
    const numOfSurveys = findLargestSurveyInArray(arrayOfSurveys);
    let arr = [];
    for (let i = 0; i < numOfSurveys; i++) {
      let totalSurveys = 0;
      arr.push({ surveyNo: i + 1, answers: [], isVisible: true });
      arrayOfSurveys.forEach((obj) => {
        if (obj.surveyNo === i + 1) {
          totalSurveys += 1;
          obj.answers.forEach((answer, j) => {
            if (!arr[i].answers[j]) {
              arr[i].answers[j] = 0;
            }
            arr[i].answers[j] += answer;
          });
        }
      });
      arr[i].answers.forEach((answer, k) => {
        arr[i].answers[k] = arr[i].answers[k] / totalSurveys;
      });
    }
    return arr;
  };

  const findLargestSurveyInArray = (arrayOfSurveys) => {
    let num = 0;
    arrayOfSurveys.forEach((surveyObj) => {
      if (surveyObj.surveyNo > num) num = surveyObj.surveyNo;
    });
    return num;
  };

  const fetchSurveys = async (id) => {
    setIsLoading(true);

    const query = `/api/v1/surveys?center=${id}`;
    const res = await axios.get(query);
    const surveys = res.data.data.map((survey) => ({
      answers: survey.answers,
      isVisible: true,
      surveyNo: survey.surveyNo,
      user: survey.user,
    }));

    console.log("surveys: ", surveys);

    setSurveys(surveys);
    const aggregatedSurveys = aggregateSurveys(surveys);
    setSelectedSurveys(aggregatedSurveys);
    setIsLoading(false);
  };

  const toggleSelectedPID = (selectedEntry) => {
    const newUsers = { ...selectedUsers };
    if (newUsers[selectedEntry[0]]) delete newUsers[selectedEntry[0]];
    else newUsers[selectedEntry[0]] = selectedEntry[1];
    setSelectedUsers(newUsers);
    updateSelectedSurveys(newUsers);
  };

  const updateSelectedSurveys = (users) => {
    let newSurveys = surveys.filter((survey) => {
      return users[survey.user] && survey;
    });
    newSurveys = aggregateSurveys(newSurveys);
    setSelectedSurveys(newSurveys);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingMsg lang="es" />
      ) : (
        <>
          {Object.entries(users).length > 0 && (
            <>
              <p>PIDS disponibles:</p>

              <Form.Check
                checked={
                  Object.entries(selectedUsers).length ===
                  Object.entries(users).length
                }
                onClick={selectAllUsers}
                label="Seleccionar todos los usuarios"
              />

              {Object.entries(users).map((entry) => (
                <span onClick={() => toggleSelectedPID(entry)}>
                  <Form.Check
                    key={entry[0]}
                    checked={selectedUsers[entry[0]] === entry[1]}
                    type="checkbox"
                    label={entry[1]}
                    inline
                  />
                </span>
              ))}
              <RandChart
                title={`Encuestas por ${store.auth.user.name}`}
                answers={selectedSurveys}
              />
            </>
          )}
          {!Object.entries(users).length && (
            <>
              <h1>Sin gráficos.</h1>
              <p>
                Aún no se han enviado encuestas para extraer datos de gráficos.
                Compruebe el navegador PID para crear nuevos PID y emitir
                encuestas para Participantes.
              </p>
            </>
          )}
        </>
      )}
    </>
  );
};

export default GraphBrowser;
