import React from "react";

const DisplayCenters = ({ centers, selectedCenter, handleChange }) => {
  return (
    <select
      value={selectedCenter ? selectedCenter.name : "Select Center"}
      onChange={handleChange}
      className="form__select"
    >
      <option defaultValue>Select Center</option>
      {centers.map((center, key) => {
        return (
          <option key={key} value={center.name}>
            {center.name}
          </option>
        );
      })}
    </select>
  );
};

export default DisplayCenters;
