import React, { useState, useContext } from "react";
import { useForm } from "../../hooks/useForm";
import axios from "axios";
import { AlertContext } from "../../context/AlertContext";

// Styles
import { Card, Form, Button } from "react-bootstrap";

export default () => {
  const [values, handleChange] = useForm({
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { addMsg } = useContext(AlertContext);

  const [passwordLoading, setPasswordLoading] = useState(false);
  // const user = useSelector((state) => state.auth.user);

  // TEMPS
  // const [avatarLoading, setAvatarLoading] = useState(false);
  // const [file, setFile] = useState("");
  // const [fileName, setFilename] = useState("Choose File");
  // const [uploadedFile, setUploadedFile] = useState({});

  // Email validation function
  // const isValidEmail = (email) => {
  //   const re = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  //   return re.test(email.toLowerCase());
  // };

  const updatePassword = async () => {
    setPasswordLoading(true);
    const arePasswordsValidated = validatePasswords();
    if (!arePasswordsValidated.success) {
      addMsg(arePasswordsValidated.msg);
    } else {
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      const res = await axios.put("/api/v1/auth/updatepassword", body, config);
      console.log(res);
      if (res.data.success) addMsg("Password updated successfully!");
      else addMsg(res.data.error);
    }
    setPasswordLoading(false);
  };

  const validatePasswords = () => {
    if (
      !values.currentPassword ||
      !values.newPassword ||
      !values.confirmPassword
    ) {
      return { success: false, msg: "Please fill out all password fields" };
    }
    if (values.newPassword !== values.confirmPassword) {
      return {
        success: false,
        msg: "New password does not match confirmation password",
      };
    } else {
      return { success: true };
    }
  };

  // TEMPS

  // const fileChange = (e) => {
  //   if (e.target.files[0]) {
  //     setFile(e.target.files[0]);
  //     setFilename(e.target.files[0].name);
  //   }
  // };

  // const updateProfilePicture = async (e) => {
  //   setAvatarLoading(true);
  //   e.preventDefault();
  //   if (!file) {
  //     setAvatarLoading(false);
  //     return addMsg("No file selected!");
  //   }
  //   const formData = new FormData();
  //   formData.append("upload", file);
  //   try {
  //     const path = `/api/v1/centers/${user.id}/avatar`;
  //     const res = await axios.put(path, formData);
  //     const { fileName, filePath } = res.data;
  //     setUploadedFile({ fileName, filePath });
  //     addMsg("Profile picture updated!");
  //   } catch (err) {
  //     if (err.response.status === 500) {
  //       addMsg("There was a problem with the server");
  //     } else {
  //       addMsg(err.response.data.msg);
  //     }
  //   }
  //   setAvatarLoading(false);
  // };

  return (
    <>
      <Card
        header="Change Password"
        isLoading={passwordLoading}
        className="p-3"
        align="right"
      >
        <Card.Title>重新設定密碼</Card.Title>
        <Form>
          <Form.Group controlId="formBasicPassword">
            <Form.Control
              placeholder="目前的密碼"
              className="mb-3"
              value={values.currentPassword}
              name="currentPassword"
              type="password"
              onChange={handleChange}
            />
            <Form.Control
              placeholder="新密碼"
              className="mb-3"
              value={values.newPassword}
              name="newPassword"
              type="password"
              onChange={handleChange}
            />
            <Form.Control
              placeholder="再次確認新密碼"
              className="mb-3"
              value={values.confirmPassword}
              name="confirmPassword"
              type="password"
              onChange={handleChange}
            />
          </Form.Group>
          <Button onClick={updatePassword} className="button__flat">
            密碼更新…
          </Button>
        </Form>
      </Card>
      {/* <Card className="p-3 my-3 mx-auto" style={{ maxWidth: "25rem" }}>
        <Form>
          <form className="menu__cardContent" encType="multipart/form-data">
            <Card.Title>UPDATE PROFILE PICTURE</Card.Title>
            <Form.Group>
              <div className="form--card__fieldset">
                <div className="form__section">
                  <Form.Group>
                    <Form.File
                      type="file"
                      name="file"
                      id="file"
                      accept="image/*"
                      onChange={fileChange}
                      className="form__fileInput"
                    />
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
            <Button onClick={updateProfilePicture} isLoading={avatarLoading}>
              Update Profile Picture
            </Button>
          </form>
        </Form>
      </Card> */}
    </>
  );
};
