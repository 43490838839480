/**
 *
 * @param arr - array of 36 answers. Returns array of values for the 8 rand categories
 */
export const calculateRandScores = (arr: number[]) => {
  const newArr = [
    Math.round(
      ((arr[2] || 0) +
        (arr[3] || 0) +
        (arr[4] || 0) +
        (arr[5] || 0) +
        (arr[6] || 0) +
        (arr[7] || 0) +
        (arr[8] || 0) +
        (arr[9] || 0) +
        (arr[10] || 0) +
        (arr[11] || 0)) /
        10
    ) || 1,
    Math.round(
      ((arr[12] || 0) + (arr[13] || 0) + (arr[14] || 0) + (arr[15] || 0)) / 4
    ) || 1,
    Math.round(((arr[16] || 0) + (arr[17] || 0) + (arr[18] || 0)) / 3 || 1),
    Math.round(
      ((arr[22] || 0) + (arr[26] || 0) + (arr[28] || 0) + (arr[30] || 0)) / 4
    ) || 1,
    Math.round(
      ((arr[23] || 0) +
        (arr[24] || 0) +
        (arr[25] || 0) +
        (arr[27] || 0) +
        (arr[29] || 0)) /
        5
    ) || 1,
    Math.round(((arr[19] || 0) + (arr[31] || 0)) / 2 || 1),
    Math.round(((arr[20] || 0) + (arr[21] || 0)) / 2 || 1),
    Math.round(
      ((arr[0] || 0) +
        (arr[32] || 0) +
        (arr[33] || 0) +
        (arr[34] || 0) +
        (arr[35] || 0)) /
        5
    ) || 1,
  ];
  return newArr;
};
