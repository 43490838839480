import React, { useState } from "react";
import { Rand36, RandChart } from "../../components/rand36";
import { graphObj } from "../../components/rand36/types";

// type graphObj = {
//   isVisible: true;
//   answers: number[];
// };

/**
 * Page that sets initiates demo for RAND. Has user go through
 * and then populates graph based on results.
 */
const Demo = () => {
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [answers, setAnswers] = useState<Array<graphObj>>([]);

  const onSubmit = (answers: number[], constented: boolean) => {
    setAnswers([{ surveyNo: 1, isVisible: true, answers }]);
    setSurveySubmitted(true);
  };

  return (
    <>
      {!surveySubmitted && <Rand36 onSubmit={onSubmit} />}
      {surveySubmitted && (
        <RandChart answers={answers} title={"Demo Survey Results"} />
      )}
    </>
  );
};

export default Demo;
