/**
 * Takes arrays of surveys and averages them into one survey
 */

type survey = {
  answers: number[];
  surveyNo: number;
  isVisible: boolean;
  user?: string;
  totalSurveys?: number;
};

export const aggregateSurveys = (arrayOfSurveys: survey[]) => {
  // const numOfQuestions = 36;
  const numOfSurveys = findLargestSurveyInArray(arrayOfSurveys);
  let arr = [] as survey[];
  for (let i = 0; i < numOfSurveys; i++) {
    let totalSurveys = 0;
    arr.push({ surveyNo: i + 1, answers: [], isVisible: true });
    arrayOfSurveys.forEach((survey) => {
      if (survey.surveyNo === i + 1) {
        totalSurveys += 1;
        survey.answers.forEach((answer, j) => {
          if (!arr[i].answers[j]) arr[i].answers[j] = 0;
          arr[i].answers[j] += answer;
        });
      }
    });
    arr[i].answers.forEach((answer, k) => {
      arr[i].answers[k] = arr[i].answers[k] / totalSurveys;
    });
    arr[i].totalSurveys = totalSurveys;
  }
  return arr;
};

const findLargestSurveyInArray = (arrayOfSurveys: survey[]) => {
  let num = 0;
  arrayOfSurveys.forEach((surveyObj) => {
    if (surveyObj.surveyNo > num) num = surveyObj.surveyNo;
  });
  return num;
};
