import React, { useState } from "react";
import { useForm } from "../hooks/useForm";
import { Message } from "./layout/Message";
import axios from "axios";

// Style components
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

export default () => {
  const [values, handleChange] = useForm({ email: "" });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const isValidEmail = () => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]\?\w+)*(\.\w{2,3})+$/;
    if (values.email.match(regex)) return true;
    else return false;
  };

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    if (isValidEmail()) await submitPwdResetToken();
    else setMessage("Please enter a valid email address");
    setLoading(false);
  };

  const submitPwdResetToken = async () => {
    try {
      const path = "/api/v1/adminAuth/forgotpassword";
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({
        email: values.email,
        host: window.location.host
      });

      const res = await axios.post(path, body, config);
      if (res.data.success) {
        handleChange({ target: { name: "email", value: "" } });
        setMessage(`A recovery email has been sent to ${values.email}`);
      } else {
        setMessage("An error occured with the server");
      }
    } catch (err) {
      setMessage("There is no user with that email", "danger");
    }
  };

  return (
    <>
      {message && <Message msg={message} setMsg={setMessage} />}
      <Form className="px-3 py-4">
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={values.email}
            name="email"
            onChange={handleChange}
          />
          <Form.Text className="text-muted p-1">
            Enter the email associated with your account above and we will send
            an email to you with instructions on how to reset your account
            password
          </Form.Text>
        </Form.Group>
        <Button
          variant="success"
          type="submit"
          disabled={loading}
          onClick={e => handleSubmit(e)}
        >
          <span>Send recovery email</span>
        </Button>{" "}
        {loading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}{" "}
      </Form>
    </>
  );
};
