import React from "react";
import { Link } from "react-router-dom";
import { Container, Nav, Row } from "react-bootstrap";
import CenterAvatar from "../layout/CenterAvatar";

export default () => {
  const links = [
    {
      body: "儀錶盤",
      url: "/tw/manager"
    },
    {
      body: "指示",
      url: "/tw/manager/guide"
    },
    {
      body: "PID瀏覽器",
      url: "/tw/manager/pid-browser"
    },
    {
      body: "圖形瀏覽器",
      url: "/tw/manager/graph-browser"
    },
    {
      body: "帳號設定",
      url: "/tw/manager/settings"
    }
  ];
  return (
    <Container className="mx-auto">
      <Row className="mx-auto">
        <CenterAvatar />
        <Nav
          variant="pills"
          defaultActiveKey="/manager"
          className="flex-column mx-auto"
        >
          <h2 className="text-center">管理儀表板</h2>
          {links.map(link => (
            <Nav.Item key={link.url}>
              <Nav.Link
                as={Link}
                href={link.url}
                to={link.url}
                className="text-center"
              >
                {link.body}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Row>
    </Container>
  );
};
