import { dashboardCard } from "./managerDashboardLinks";

export const directorDashboardLinks: dashboardCard[] = [
  {
    header: "Register Center",
    body: "Register center & center manager to database",
    url: "/director/register-center",
    urlText: "Register Center",
  },
  {
    header: "Graph Browser",
    body: "Browse graphs from centers around the world",
    url: "/director/graph-browser",
    urlText: "Graph Browser",
  },
  {
    header: "Account Settings",
    body: "Reset password for your account",
    url: "/director/settings",
    urlText: "Account settings",
  },
];
