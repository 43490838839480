import React from "react";

export default () => {
  return (
    <div className="consentContainer">
      <h1>Routine Outcome Monitoring</h1>
      <h2>Consent to Release Health Information</h2>
      <p>
        As part of the outcome monitoring and reporting, ROM wishes to share
        program participant health survey information with:
      </p>
      <p>
        Foundation for Advancements in Science and Education (FASE)
        <br />
        2400 N. Lincoln Ave.
        <br />
        Altadena, CA 91001
      </p>
      <p>
        I understand the shared data will not identify me by name or include any
        information that could lead to my identification consistent with U.S.
        national regulations and international standards governing the transfer
        or sharing of such protected information. FASE in turn will share this
        de-identified data with VAVA Centers, VAVA National, ABLE and other
        scientific investigators interested in this important work.
      </p>
      <p>
        I understand that the purpose of releasing this information is solely to
        analyze combined results and thereby contribute to knowledge regarding
        the effects of drug abuse and toxic exposures and the use of the
        Narconon Program to address these effects.
      </p>
      <p>
        I understand that if any data is published, such as in case studies,
        scientific papers, or conference presentations no identifiable
        information will be included that can be traced to my participation.
      </p>
      <p>
        I understand that I am still eligible to participate in the Narconon
        program even if I decline release of this health survey, as outlined.
      </p>
      <p>
        I understand this authorization will be in effect from the time I begin
        receiving services from Narconon until such time in the future that I
        provide written notice to discontinue my participation in this
        monitoring program.
      </p>
      <p>
        I understand I may revoke this authorization at any time in writing. If
        I revoke this authorization, it applies only to data not yet provided as
        data previously submitted may already be combined in published reports
        that reflect combined results of multiple program participants.
      </p>
      <p>
        You can revoke this authorization at any time. To revoke the
        authorization, please notify your local Narconon representative, and
        they can assist you.
      </p>
    </div>
    // <div className="consentContainer">
    //   <h1>Narconon</h1>
    //   <h2>Consent to Release Health Information</h2>
    //   <p>
    //     As part of Narconon’s outcome monitoring and reporting Narconon wishes
    //     to share program participant health survey information with:
    //   </p>
    //   <p>
    //     Foundation for Advancements in Science and Education (FASE)
    //     <br />
    //     2400 N. Lincoln Ave.
    //     <br />
    //     Altadena, CA 91001
    //   </p>
    //   <p>
    //     I understand the shared data will not identify me by name or include any
    //     information that could lead to my identification consistent with U.S.
    //     national regulations and international standards governing the transfer
    //     or sharing of such protected information.
    //   </p>
    //   <p>
    //     I understand that the purpose of releasing this information is solely to
    //     analyze combined results and thereby contribute to knowledge regarding
    //     the effects of drug abuse and toxic exposures and the use of the
    //     Narconon Program to address these effects.
    //   </p>
    //   <p>
    //     I understand that if any data is published, such as in case studies,
    //     scientific papers, or conference presentations no identifiable
    //     information will be included that can be traced to my participation.
    //   </p>
    //   <p>
    //     I understand that I am still eligible to participate in the Narconon
    //     program even if I decline release of this health survey, as outlined.
    //   </p>
    //   <p>
    //     I understand this authorization will be in effect from the time I begin
    //     receiving services from Narconon until such time in the future that I
    //     provide written notice to discontinue my participation in this
    //     monitoring program.
    //   </p>
    //   <p>
    //     I understand I may revoke this authorization at any time in writing. If
    //     I revoke this authorization, it applies only to data not yet provided as
    //     data previously submitted may already be combined in published reports
    //     that reflect combined results of multiple program participants.
    //   </p>
    //   <p>
    //     You can revoke this authorization at any time. To revoke the
    //     authorization, please notify your local Narconon representative, and
    //     they can assist you.
    //   </p>
    // </div>
  );
};
