import React, { useState } from "react";
import axios from "axios";
// import axios from "axios";

import { User } from "./PIDBrowserWrapper";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

interface Props {
  user: User;
}

const DeleteUserModal = ({ user }: Props) => {
  const [pidField, setPidField] = useState("");
  const [confirmPid, setConfirmPid] = useState("");
  const [secondConfirmPid, setSecondConfirmPid] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const PidsMatch = () =>
    pidField === user.pid.toString() &&
    confirmPid === user.pid.toString() &&
    secondConfirmPid === user.pid.toString();

  const deletePID = async () => {
    setLoading(true);
    const res = await axios.delete(`/api/v1/users/${user.id}`);
    console.log("RES: ", res);
    setSuccess(res.data.success);
    setLoading(false);
  };

  return (
    <div>
      {success === true ? (
        <div>
          <p>
            PID eliminado correctamente. Cierre esta ventana y la página se
            actualizará.
          </p>
        </div>
      ) : (
        <div>
          <h3>¿Está seguro de que desea eliminar al siguiente usuario?</h3>
          <h5>PID: {user.pid}</h5>
          <p>
            Esta acción no se puede deshacer y elimina el PID y todas las
            encuestas completadas por ellos. Si está seguro, confirme ingresando
            el PID especificado a continuación:
          </p>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={"Ingrese PID"}
              value={pidField}
              onChange={(e) => setPidField(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={"Confirmar PID"}
              value={confirmPid}
              onChange={(e) => setConfirmPid(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={"Confirmar PID nuevamente"}
              value={secondConfirmPid}
              onChange={(e) => setSecondConfirmPid(e.target.value)}
            />
          </InputGroup>
          {PidsMatch() && (
            <Button variant="danger" onClick={(_) => deletePID()}>
              {loading ? (
                <Spinner
                  animation="border"
                  role="loading"
                  size="sm"
                  aria-hidden="true"
                />
              ) : (
                <span>ELIMINAR</span>
              )}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default DeleteUserModal;
