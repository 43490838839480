import React, { useState, useEffect } from "react";
import axios from "axios";

import { User } from "./PIDBrowserWrapper";

import DeleteUserModal from "./DeleteUserModal";
import ManagePermissionsModal from "./ManagePermissionsModal";

// Style components
import Modal from "../modal/modal.component";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

interface Props {
  user: User;
  fetchUsersFunc?: () => void;
}

export default ({ user, fetchUsersFunc }: Props) => {
  const [surveyURL, setSurveyURL] = useState("");
  const [loadingSurveyURL, setLoadingSurveyURL] = useState(false);
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  useEffect(() => {
    if (user.surveyToken) {
      const url = convertTokenToURL(user.surveyToken);
      setSurveyURL(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSurveyToken = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoadingSurveyURL(true);
    const surveyToken = await axios.post(`/api/v1/auth/survey/${user.id}`);
    const surveyTokenURL = convertTokenToURL(surveyToken.data.data);
    setLoadingSurveyURL(false);
    setSurveyURL(surveyTokenURL);
  };

  const convertTokenToURL = (tokenURL: string) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const url = `${protocol}//${host}/survey/${tokenURL}`;
    return url;
  };

  const generateBtn = () => {
    if (loadingSurveyURL) {
      return (
        <Button>
          <Spinner
            animation="border"
            role="loading"
            size="sm"
            aria-hidden="true"
          />
        </Button>
      );
    }
    return (
      <Button onClick={(e) => fetchSurveyToken(e)}>
        <span className="btn__text">Generate</span>
      </Button>
    );
  };

  const ModalBody = () => (
    <div>
      <h3>PID: {user.pid}</h3>
      <h5>Next survey: {user.surveyCount + 1}</h5>
      <p>To generate a link, press the generate button below.</p>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text>URL</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={surveyURL ? surveyURL : ""} />
        <InputGroup.Append>
          {!surveyURL ? (
            generateBtn()
          ) : (
            <Button disabled variant="success">
              DONE!
            </Button>
          )}
        </InputGroup.Append>
        {surveyURL && (
          <Form.Text muted>
            This participant is <strong>open for survey</strong>. This means
            they can use the above link to fill out a survey and submit it.
            After they submit their survey, they will be flagged as{" "}
            <strong>closed for survey</strong>, and won't be able to submit
            another survey until you create a new survey link for them.
          </Form.Text>
        )}
      </InputGroup>
    </div>
  );

  return (
    <>
      <Modal
        show={show}
        header={"Generate PID Survey"}
        setShow={setShow}
        JSXComponent={ModalBody}
      />
      <Modal
        show={showDeleteModal}
        header={"Delete PID"}
        setShow={setShowDeleteModal}
        onHide={fetchUsersFunc}
        JSXComponent={() => <DeleteUserModal user={user} />}
      />
      <Modal
        show={showPermissionsModal}
        header={"Consent Permissions"}
        setShow={setShowPermissionsModal}
        JSXComponent={() => <ManagePermissionsModal user={user} />}
      />
      <tr>
        <td>
          {user.pid}{" "}
          {user.surveyCount === 0 && <Badge variant="secondary">New</Badge>}
        </td>
        <td>{user.surveyCount + 1}</td>
        <td className="justify-content-md-center">
          <Button onClick={() => setShow(true)} size="sm" block>
            Create
          </Button>
        </td>
        {/* <td>
          <Button
            onClick={() => setShowPermissionsModal(true)}
            size="sm"
            variant="info"
            block
          >
            Manage
          </Button>
        </td> */}
        <td>
          <Button
            onClick={() => setShowDeleteModal(true)}
            size="sm"
            variant="danger"
            block
          >
            Delete
          </Button>
        </td>
      </tr>
    </>
  );
};
