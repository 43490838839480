import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BSAlert from "react-bootstrap/Alert";

const Alert = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    <BSAlert key={alert.id} variant={alert.alertType ? alert.alertType : "info"} className="my-3">
      {alert.msg}
    </BSAlert>
  ));

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alert);
