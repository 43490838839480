import React, { useState, useEffect } from "react";
import { RandChart } from "../rand36/index.tsx";
import { useSelector } from "react-redux";
import axios from "axios";
import LoadingMsg from "../LoadingMsg";

// Styles components
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// Components
import { CentersReportTable } from "../centers-report-table/index.ts";
import Calendar from "react-calendar";

const GraphBrowser = ({ dirId, dirName }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedCenters, setLoadedCenters] = useState({});
  const [selectedCenters, setSelectedCenters] = useState({});
  const [loadedSurveys, setLoadedSurveys] = useState([]);
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [aggregatedSurveys, setAggregatedSurveys] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date(Date.parse("02 Jan 2019 00:00:00 GMT"))
  );
  const [endDate, setEndDate] = useState(new Date());
  const changeStartDate = (date) => {
    setStartDate(date);
    calculateFilteredSurveys(selectedSurveys, selectedCenters, date);
  };
  const changeEndDate = (date) => {
    setEndDate(date);
    calculateFilteredSurveys(selectedSurveys, selectedCenters, startDate, date);
  };

  const [largestSurveyNo, setLargestSurveyNo] = useState(0);

  const storeDirId = useSelector((state) => state.auth.user.id);
  const storeDirName = useSelector((state) => state.auth.user.name);

  const directorId = dirId ? dirId : storeDirId;
  const directorName = dirName ? dirName : storeDirName;

  const [filteredSurveys, setFilteredSurveys] = useState([]);

  /**
   *
   * @param {*} selectedEntry -- arr of [selectedCenterID, selectedCenterName]
   *
   */
  const toggleSelectedCenter = (centerId, centerName) => {
    const newCenters = { ...selectedCenters };
    if (newCenters[centerId]) delete newCenters[centerId];
    else newCenters[centerId] = centerName;
    setSelectedCenters(newCenters);
    updateSelectedSurveys(newCenters);
  };

  const calculateFilteredSurveys = (selectedSurveys, optional, sd, ed) => {
    const sDate = sd || startDate;
    const eDate = ed || endDate;
    const newSurveys = selectedSurveys.filter((survey) => {
      const inRange =
        Date.parse(survey.createdAt) >= Date.parse(sDate) &&
        Date.parse(survey.createdAt) <= Date.parse(eDate);
      const centers = optional || selectedCenters;
      return centers[survey.center] && inRange && survey;
    });
    setFilteredSurveys(newSurveys);
    return newSurveys;
  };

  const fetchCenters = async () => {
    const query = `/api/v1/centers/?select=name&director=${directorId}`;
    // const query = `/api/v1/centers/?select=name,director`;
    setIsLoading(true);
    const res = await axios.get(query);
    console.log("CENTERS: ", res);
    let centers = {};
    res.data.data.forEach((center) => (centers[center.id] = center.name));
    console.log("CENTERS:", centers);
    setLoadedCenters(centers);
    await setSelectedCenters(centers);
    await fetchSurveys(centers);
    setIsLoading(false);
  };

  const selectAllCenters = () => {
    if (
      Object.entries(selectedCenters).length ===
      Object.entries(loadedCenters).length
    ) {
      setSelectedCenters({});
      setSelectedSurveys([]);
      setAggregatedSurveys([]);
    } else {
      const aggregatedSurveys = aggregateSurveys(
        calculateFilteredSurveys(loadedSurveys)
      );
      setSelectedCenters(loadedCenters);
      setSelectedSurveys(loadedSurveys);
      setAggregatedSurveys(aggregatedSurveys);
    }
  };

  const aggregateSurveys = (arrayOfSurveys) => {
    // const numOfQuestions = 36;
    const numOfSurveys = findLargestSurveyInArray(arrayOfSurveys);
    let arr = [];
    for (let i = 0; i < numOfSurveys; i++) {
      let totalSurveys = 0;
      arr.push({ surveyNo: i + 1, answers: [], isVisible: true });
      arrayOfSurveys.forEach((obj) => {
        if (obj.surveyNo === i + 1) {
          totalSurveys += 1;
          obj.answers.forEach((answer, j) => {
            if (!arr[i].answers[j]) {
              arr[i].answers[j] = 0;
            }
            arr[i].answers[j] += answer;
          });
        }
      });
      arr[i].answers.forEach((answer, k) => {
        arr[i].answers[k] = arr[i].answers[k] / totalSurveys;
      });
    }

    return arr;
  };

  const findLargestSurveyInArray = (arrayOfSurveys) => {
    let num = 0;
    arrayOfSurveys.forEach((surveyObj) => {
      if (surveyObj.surveyNo > num) num = surveyObj.surveyNo;
    });
    setLargestSurveyNo(num);
    return num;
  };

  const fetchSurveys = async (optional) => {
    setIsLoading(true);

    const query = `/api/v1/surveys?director=${directorId}`;
    const res = await axios.get(query);
    const surveys = res.data.data.map((survey) => ({
      answers: survey.answers,
      isVisible: survey.consented,
      surveyNo: survey.surveyNo,
      center: survey.center,
      createdAt: survey.createdAt,
    }));

    setLoadedSurveys(surveys);
    setSelectedSurveys(surveys);
    const aggregatedSurveys = aggregateSurveys(
      calculateFilteredSurveys(surveys, optional)
    );
    setAggregatedSurveys(aggregatedSurveys);
    setIsLoading(false);
  };

  const updateSelectedSurveys = (centers) => {
    // loops through all surveys and checks if survey's center
    // is in param center array. Adds to new surveys, if so.
    const newSurveys = loadedSurveys.filter((survey) => {
      return centers[survey.center] && survey;
    });
    setSelectedSurveys(newSurveys);
    const aggregatedSurveys = aggregateSurveys(
      calculateFilteredSurveys(newSurveys)
    );
    setAggregatedSurveys(aggregatedSurveys);
  };

  useEffect(() => {
    fetchCenters();
  }, []);

  // useEffect(() => {
  //   setIsLoading(true);
  //   fetchCenters();
  //   setIsLoading(false);
  // }, [dirId]);

  useEffect(() => {
    const aggregatedSurveys = aggregateSurveys(filteredSurveys);
    setAggregatedSurveys(aggregatedSurveys);
  }, [startDate, endDate]);

  useEffect(() => {
    updateSelectedSurveys(selectedCenters);
  }, [selectedCenters]);

  return (
    <>
      {isLoading ? (
        <LoadingMsg />
      ) : (
        <>
          {Object.entries(loadedCenters).length > 0 && (
            <>
              <p>Select Center(s):</p>

              <Form.Check
                checked={
                  Object.entries(selectedCenters).length ===
                  Object.entries(loadedCenters).length
                }
                onClick={selectAllCenters}
                label="Select all centers"
              />

              {Object.entries(loadedCenters).map(([centerId, centerName]) => (
                <span
                  onClick={() => toggleSelectedCenter(centerId, centerName)}
                >
                  <Form.Check
                    key={centerId}
                    checked={selectedCenters[centerId] === centerName}
                    type="checkbox"
                    label={centerName}
                    inline
                  />
                </span>
              ))}
            </>
          )}
          <Container center className="my-3">
            <Row center>
              <Col xs="6">
                <Calendar onChange={changeStartDate} value={startDate} />
              </Col>
              <Col xs="6">
                <Calendar onChange={changeEndDate} value={endDate} />
              </Col>
            </Row>
          </Container>

          {selectedCenters && (
            <RandChart
              title={`Surveys for ${directorName}`}
              answers={aggregatedSurveys}
            />
          )}
        </>
      )}
      <CentersReportTable
        centers={selectedCenters}
        surveys={filteredSurveys}
        maxSurveyNo={largestSurveyNo}
      />
    </>
  );
};

export default GraphBrowser;
