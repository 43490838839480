import React from "react";
import { Link } from "react-router-dom";
import { Container, Nav, Row } from "react-bootstrap";
import CenterAvatar from "../layout/CenterAvatar";
import { permissions } from "../../data/types/permissions";

export interface quickbarLink {
  body: string;
  url: string;
}

interface Props {
  role: permissions;
  links: quickbarLink[];
}

export const Quickbar: React.FC<Props> = ({ links, role }) => {
  const roleCapitalized = role.charAt(0).toUpperCase() + role.slice(1);
  return (
    <Container className="mx-auto">
      <Row className="mx-auto">
        <CenterAvatar />
        <Nav
          variant="pills"
          defaultActiveKey="/manager"
          className="flex-column mx-auto"
        >
          <h2 className="text-center">{roleCapitalized} Dashboard</h2>
          {links.map((link) => (
            <Nav.Item key={link.url}>
              <Nav.Link
                as={Link}
                href={link.url}
                to={link.url}
                className="text-center"
              >
                {link.body}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Row>
    </Container>
  );
};
