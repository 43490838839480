import React from "react";
import Form from "react-bootstrap/Form";
// import Spinner from "react-bootstrap/Spinner";

const Select = ({
  arr,
  selectedItem,
  handleChange,
  isFetching = false,
  defaultLoadingMsg = "",
  defaultMsg = "",
  defaultLabel = "Select PID:",
  ...rest
}) => {
  return (
    <Form.Group>
      <Form.Label>{defaultLabel}</Form.Label>
      <Form.Control
        as="select"
        onChange={handleChange}
        value={selectedItem ? selectedItem.name : defaultMsg}
        {...rest}
      >
        {isFetching ? (
          <option defaultValue value="defaultValue">
            {defaultLoadingMsg}
          </option>
        ) : (
          <option defaultValue value="defaultValue">
            {defaultMsg}
          </option>
        )}
        {arr.map((item, key) => {
          return (
            <option key={key} value={item.value} disabled={item.disabled}>
              {item.name}
            </option>
          );
        })}
      </Form.Control>
    </Form.Group>
  );
};

export default Select;
