import React from "react";
import { Link } from "react-router-dom";

const ComingSoon = () => {
  return (
    <div>
      This section is coming soon
      <br />
      <Link to="/">Return to main menu</Link>
    </div>
  );
};

export default ComingSoon;
