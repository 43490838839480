import React from "react";

import managerDashboardLinks, {
  dashboardCard,
} from "../../../data/twManagerDashboardLinks";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

export default () => {
  return (
    <Container>
      <Row>
        {managerDashboardLinks.map((link: dashboardCard) => {
          return (
            <Card
              className="mb-5 mx-3"
              style={{ width: "25rem" }}
              key={link.url}
            >
              <Card.Header as="h2">{link.header}</Card.Header>
              <Card.Body>
                <Card.Text>{link.body}</Card.Text>
                <Card.Link
                  as={Link}
                  href={link.url}
                  to={link.url}
                  target={link.newTab ? "_blank" : "_self"}
                >
                  {link.urlText}
                </Card.Link>
              </Card.Body>
            </Card>
          );
        })}
      </Row>
    </Container>
  );
};
