export const directorLinks = [
  {
    body: "Dashboard",
    url: "/director",
  },
  {
    body: "Instructions",
    url: "/director/guide",
  },
    {
      body: "Register Center",
      url: "/director/register-center",
    },
  {
    body: "Graph Browser",
    url: "/director/graph-browser",
  },
  {
    body: "Account Settings",
    url: "/director/settings",
  },
];
