import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Rand36 } from "../rand36/index.tsx";
// const crypto = require("crypto");

export const Surveys = ({ match }) => {
  const [user, setUser] = useState(null);
  const [consentRegion, setConsentRegion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noUser, setNoUser] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const fetchUser = async () => {
    setLoading(true);
    const surveyId = match.params.surveyId;
    const surveyToken = surveyId;
    // const surveyToken = crypto
    //   .createHash("sha256")
    //   .update(surveyId)
    //   .digest("hex");
    const res = await axios.get(`/api/v1/users/surveys/${surveyToken}`);
    console.log("res2: ", res);
    if (!res.data.success) setNoUser(true);
    else {
      setUser(res);
      fetchCenter(res.data.data);
    }
    setLoading(false);
  };

  const fetchCenter = async (user) => {
    console.log("user: ", user);
    const res = await axios.get(`/api/v1/centers/${user.center}`);
    if (!res.data.data.consentRegion) return;
    else setConsentRegion(res.data.data.consentRegion);
  };

  const onSubmit = async (answers, consented) => {
    const surveyId = match.params.surveyId;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      answers,
      consented,
      surveyNo: user.data.data.surveyCount + 1,
    });

    const res = await axios.post(`/api/v1/surveys/${surveyId}`, body, config);
    if (res.data.success) {
      return setFormSubmitted(true);
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {noUser && <Redirect to="/" />}
      {!formSubmitted ? (
        <>
          {!loading ? (
            <>
              <Rand36
                onSubmit={onSubmit}
                lang={"english"}
                consentRegion={consentRegion || "general"}
              />
            </>
          ) : (
            <div>
              <h3>Loading...</h3>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            style={{ display: "block", margin: "4rem auto", fontSize: "3rem" }}
          >
            Thank you!
          </div>
          <div
            style={{
              display: "block",
              margin: "3rem auto",
              fontSize: "1.7rem",
            }}
          >
            Your survey is now complete. You are all done.
          </div>
        </>
      )}
    </>
  );
};
