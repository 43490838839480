import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth";
import { Link } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";

export default () => {
  const store = useSelector(store => store);
  const dispatch = useDispatch();
  const loggedIn = !!store.auth.user;

  return (
    <Navbar bg="dark" variant="dark" expand="md">
      <Container>
        <Navbar.Brand as={Link} to="/" href="/">
          Routine Outcome Monitoring
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {!loggedIn && <Nav.Link href="/login">login</Nav.Link>}
            {/* {loggedIn && (
              <Nav.Link
                as={Link}
                to="/"
                href="/"
                className="bg-primary rounded px-2 text-light"
              >
                Your Dashboard
              </Nav.Link>
            )} */}
            {loggedIn && (
              <Nav.Link href="/login" onClick={() => dispatch(logout())}>
                logout
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
