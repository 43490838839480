import React from "react";

export default () => {
  return (
    <div className="instructionsCard">
      <h1>Instrucciones de seguimiento de rutina de los resultados</h1>
      <div>
        <h2>Palabras</h2>
        <div>
          <h4>PID:</h4>
          <p>
            Número de identificación del participante, un número de cuatro
            dígitos asignado a un participante por nuestro sistema para
            identificarlos para encuestas utilizando el Herramientas de
            seguimiento de resultados de rutinas
          </p>
        </div>
        <div>
          <h4>RAND SF-36:</h4>
          <p>
            Una encuesta de 36 preguntas creada por RAND como parte del Medical
            Outcome Study.
          </p>
        </div>
      </div>
      <div>
        <h2>Introducción</h2>
        <p>
          La herramienta de seguimiento de resultados de rutina proporciona a
          los profesionales y encuestas participantes una forma de hacer un
          seguimiento de su calidad de vida en ocho principales categorías. Los
          administradores del sitio pueden registrarse de forma anónima
          participantes y pídales que completen la encuesta y generen
          representación gráfica de los resultados.{" "}
        </p>
      </div>
      <div>
        <h2>Panel del administrador</h2>
        <p>
          El Panel del administrador es el eje central de su cuenta. Tiene
          enlaces al navegador PID, donde puede crear PID para asignar a
          participantes e iniciar encuestas para que las llenen, el gráfico
          Navegador, para crear gráficos que describan el progreso de sus
          participantes, y un acceso rápido a la página de demostración de la
          encuesta, para que pueda mostrar nuevos o Perspectiva de los clientes
          cómo funciona el proceso de encuesta y gráficos.
        </p>
      </div>
      <div>
        <h2>Centro PID</h2>
        <p>
          El Centro PID proporciona muchas funciones para usted, el
          Administrador del sitio, de su portal. Cuando use la página por
          primera vez, solo verá una para "Generar nuevo PID". Al hacer clic en
          esto, se creará un Número de identificación de participante de 4
          dígitos para su uso. Usted asigna este número a alguien que esté
          pasando por su programa y utilizará este número para rastrearlos a
          través del sistema WOM. Por motivos de confidencialidad, WOM no
          permite que los administradores del sitio almacenen información
          personal sobre sus participantes en la aplicación, además de los datos
          de la encuesta. Debido a esto, depende de USTED almacenar de forma
          segura la información quién está asociado con qué PID.
        </p>
        <p>
          Para que los participantes comiencen a completar una encuesta, primero debe generar un enlace de encuesta. Para crear un enlace de encuesta para un participante específico, simplemente presione el botón verde GENERAR asociado con el PID en la tabla PID. Esto creará una URL única y única para su uso. Puede hacer clic con el botón izquierdo en este enlace y se abrirá una nueva pestaña en su navegador web con la encuesta RAND SF-36 para que un participante la complete en el lugar y en su computadora. Esta encuesta está vinculada exclusivamente a ESE PID. Al completarlo, se creará una nueva encuesta en la base de datos solo para ese participante.
        </p>
        <p>
          Alternativamente, en lugar de hacer clic izquierdo en el enlace, puede
          hacer clic derecho en el enlace y copiarlo en su portapapeles. Luego
          puede pegar este enlace en un correo electrónico y enviárselo al
          participante. Al recibir su correo electrónico, ellos mismos pueden
          hacer clic en el enlace y se abrirá la encuesta en su computadora o
          teléfono inteligente, y pueden completar la encuesta por su cuenta.
          Esto es muy útil para las encuestas de seguimiento para los
          participantes que no pueden venir a completar. Al igual que con las
          encuestas en el sitio, este enlace está vinculado de manera única a
          ese participante y es una URL de uso único.
        </p>
        <p>
          Cuando un participante haya terminado de completar su encuesta,
          aparecerá un mensaje de agradecimiento indicándole que la encuesta
          está completa. A diferencia de la versión de demostración de la
          encuesta, sus resultados NO aparecerán inmediatamente en su pantalla.
          Esto es para asegurarse de que tengan la oportunidad de consultarle
          sobre los resultados de la encuesta.
        </p>
      </div>
      <div>
        <h2>Centro de encuestas</h2>
        <p>
          El Centro de encuestas le permite ver y guardar gráficos tomados de
          los datos de la encuesta de los participantes en las ocho categorías
          de encuestas RAND SF-36. Cuando esté en el Centro de encuestas,
          seleccione un PID del menú desplegable que desee ver y aparecerá un
          gráfico con sus resultados. Las encuestas predeterminadas que se
          muestran son las normas de población de RAND US y cualquier encuesta
          que haya completado el participante seleccionado. Al hacer clic en las
          etiquetas en la parte inferior del gráfico, se desactivará esa
          encuesta y se ocultará del gráfico. Al hacer clic en una encuesta
          deshabilitada, se volverá a habilitar y se mostrará en el gráfico.
          Esto le brinda control total para comparar las encuestas que desee
          para un análisis óptimo.
        </p>
        <p>
          Para guardar gráficos en su máquina local, seleccione el menú de
          hamburguesas en la parte superior derecha del marco del gráfico (las
          tres barras veríticas) y elija en qué formato viviría para guardar los
          resultados.
        </p>
      </div>
      <div>
        <h2>Configuraciones de la cuenta</h2>
        <p>
          La página Configuración de la cuenta le permite actualizar la
          configuración de su perfil, como la contraseña de su cuenta..
        </p>
      </div>
    </div>
  );
};
