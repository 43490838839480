import React, { useContext } from "react";
import { AlertContext } from "../../context/AlertContext";

import Alert from "react-bootstrap/Alert";

export default () => {
  const { msg, delMsg } = useContext(AlertContext);
  // const {msg, setMsg} = context
  // console.log(context);
  if (msg) {
    return (
      <Alert variant="info" className="my-3">
        {msg}
        <span
          onClick={() => delMsg()}
          style={{
            float: "right",
            cursor: "pointer",
          }}
        >
          x
        </span>
      </Alert>
    );
  } else return "";
};
