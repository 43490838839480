import React, { useState, useEffect } from "react";
import axios from "axios";

import { User } from "./PIDBrowserWrapper";

import DeleteUserModal from "./DeleteUserModal";
import ManagePermissionsModal from "./ManagePermissionsModal";

// Style components
import Modal from "../../modal/modal.component";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

interface Props {
  user: User;
  fetchUsersFunc?: () => void;
}

export default ({ user, fetchUsersFunc }: Props) => {
  const [surveyURL, setSurveyURL] = useState("");
  const [loadingSurveyURL, setLoadingSurveyURL] = useState(false);
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  useEffect(() => {
    if (user.surveyToken) {
      const url = convertTokenToURL(user.surveyToken);
      setSurveyURL(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSurveyToken = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoadingSurveyURL(true);
    const surveyToken = await axios.post(`/api/v1/auth/survey/${user.id}`);
    const surveyTokenURL = convertTokenToURL(surveyToken.data.data);
    setLoadingSurveyURL(false);
    setSurveyURL(surveyTokenURL);
  };

  const convertTokenToURL = (tokenURL: string) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const url = `${protocol}//${host}/survey/${tokenURL}`;
    return url;
  };

  const generateBtn = () => {
    if (loadingSurveyURL) {
      return (
        <Button>
          <Spinner
            animation="border"
            role="loading"
            size="sm"
            aria-hidden="true"
          />
        </Button>
      );
    }
    return (
      <Button onClick={(e) => fetchSurveyToken(e)}>
        <span className="btn__text">Generar</span>
      </Button>
    );
  };

  const ModalBody = () => (
    <div>
      <h3>PID: {user.pid}</h3>
      <h5>Siguiente encuesta: {user.surveyCount + 1}</h5>
      <p>Para generar un enlace, presione el botón generar a continuación.</p>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text>URL</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={surveyURL ? surveyURL : ""} />
        <InputGroup.Append>
          {!surveyURL ? (
            generateBtn()
          ) : (
            <Button disabled variant="success">
              ¡HECHO!
            </Button>
          )}
        </InputGroup.Append>
        {surveyURL && (
          <Form.Text muted>
            Este participante está <strong> abierto a la encuesta </strong>.
            Esto significa pueden usar el enlace anterior para completar una
            encuesta y enviarla. Una vez que envíen su encuesta, se les marcará
            como {""}
            <strong> cerrado por encuesta </strong> y no se podrá enviar otra
            encuesta hasta que cree un nuevo enlace de encuesta para ellos.
          </Form.Text>
        )}
      </InputGroup>
    </div>
  );

  return (
    <>
      <Modal
        show={show}
        header={"Generar encuesta PID"}
        setShow={setShow}
        JSXComponent={ModalBody}
        lang="es"
      />
      <Modal
        show={showDeleteModal}
        header={"Eliminar PID"}
        setShow={setShowDeleteModal}
        onHide={fetchUsersFunc}
        JSXComponent={() => <DeleteUserModal user={user} />}
        lang="es"
      />
      <Modal
        show={showPermissionsModal}
        header={"Permisos de consentimiento"}
        setShow={setShowPermissionsModal}
        JSXComponent={() => <ManagePermissionsModal user={user} />}
        lang="es"
      />
      <tr className={"table__row" + (user.isNew ? " new" : "")}>
        <td>
          {user.pid}{" "}
          {user.surveyCount === 0 && <Badge variant="secondary">Nuevo</Badge>}
        </td>
        <td>{user.surveyCount + 1}</td>
        <td>
          <Button onClick={() => setShow(true)} size="sm">
            Crear enlace de encuesta
          </Button>
        </td>
        {/* <td>
          <Button
            onClick={() => setShowPermissionsModal(true)}
            size="sm"
            variant="info"
          >
            Gestionar
          </Button>
        </td> */}
        <td>
          <Button
            onClick={() => setShowDeleteModal(true)}
            size="sm"
            variant="danger"
            className="m-auto"
          >
            Eliminar
          </Button>
        </td>
      </tr>
    </>
  );
};
