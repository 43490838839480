import React, { createContext, useState } from "react";

export const ParticipantsSurveysContext = createContext();

const ParticipantsSurveysContextProvider = props => {
  const [users, setUsers] = useState([]);
  const addUser = user => setUsers([user, ...users]);
  const addUsers = users => setUsers(users);
  const delUser = user => setUsers(users.filter(u => u.id !== user.id));
  return (
    <ParticipantsSurveysContext.Provider
      value={{ users, addUser, addUsers, delUser }}
    >
      {props.children}
    </ParticipantsSurveysContext.Provider>
  );
};

export default ParticipantsSurveysContextProvider;
