import React from "react";

// Style components
import Pagination from "react-bootstrap/Pagination";

interface Props {
  usersPerPage: number;
  totalUsers: number;
  currentPage: number;
  paginate: (n: number) => void;
}

export default ({ usersPerPage, totalUsers, currentPage, paginate }: Props) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Pagination>
      {pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      ))}
    </Pagination>
  );
};
