import React from "react";
import Card from "react-bootstrap/Card";
import ForgotPassword from "../components/ForgotPassword.jsx";

export default () => {
  return (
    <>
      <Card className="mt-5">
        <Card.Header as="h1">FORGOT PASSWORD</Card.Header>
        <Card.Body>
          <ForgotPassword />
        </Card.Body>
      </Card>
    </>
  );
};
