import React, { useState } from "react";

// styles components
import BSAlert from "react-bootstrap/Alert";

export type variant =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";

interface Props {
  message: string;
  idx?: number;
  dismissible?: boolean;
  variant?: variant;
}

export const Alert = ({ message, idx, dismissible, variant }: Props) => (
  <BSAlert dismissible={false} key={idx || 1} variant={variant}>
    {message}
  </BSAlert>
);
