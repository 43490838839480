import React from "react";

export default () => {
  return (
    <div className="instructionsCard" style={{ textAlign: "right" }}>
      <h1>常規成果監測說明</h1>
      <div>
        <h2>字</h2>
        <div>
          <h4>參與者識別碼</h4>
          <p>
            參與者識別碼，是由我們的系統分配給參與者的一個四位數字，這是為了在使用常規成果監測工具做調查時讓系統能辨識他們。
          </p>
        </div>
        <div>
          <h4>蘭德SF-36調查表</h4>
          <p>
            一份由36個問題所組成的調查表，它是由美國蘭德公司所創，做為醫學成果研究的一部份。
          </p>
        </div>
      </div>
      <div>
        <h2>介紹</h2>
        <p>
          常規成果監測工具為業者和參與調查者提供了一種在八個主要範疇中追踪其生活質量的方法。網站管理者可以利用匿名的方式註冊新的參與者，透過讓他們填寫問卷調查而產生即時圖表來顯示結果。
        </p>
      </div>
      <div>
        <h2>管理儀表板</h2>
        <p>
          管理儀表板是您帳戶的中央樞紐，它具有指向PID瀏覽器的連結，您可以在此創建多個PID以分配給多位參與者，並讓他們填寫問卷，還可以透過圖表瀏覽器建立圖表以概述參與者的進展，且可快速訪問調查的示範頁面，因此您可以向您的新客戶或準客戶展示此調查以及圖表的製作過程是如何運作的。
        </p>
      </div>
      <div>
        <h2>PID中心</h2>
        <p>
          PID中心為您的入口網站管理員提供許多功能。當您第一次使用這個頁面，您將只會看到「生成新的PID」這個按鈕，按下按鈕將會隨機產生四位數的參與者ID號碼供您使用。您可將這組號碼分配給某位正在進行計劃的人，而且您將使用這組號碼透過ROM系統去追蹤他們。為了個資保密之故，除參加者的調查數據之外，
          ROM不允許網站管理員儲存參與者的個資。因此，您有責任安全的儲存有關誰與哪個PID關聯的信息。
        </p>
        <p>
          為了讓參與者開始填寫調查表，首先你必須要為參與者生成一個調查表的連結。要為某位特定的參與者創建一個調查表的連結，只要在PID一覽表上，按下與那個PID關聯的、綠色的產生鍵即可。它將立即建立一個唯一的、一次性的網址供您使用。您可以在連結上單擊滑鼠左鍵，就會在你的網頁瀏覽器展開一個新的蘭德
          SF-36調查表，供您的參與者可以就地使用您的電腦來填寫。此調查表只綁定該PID。填寫完畢，資料庫會為該參與者生成一個新的調查表。
        </p>
        <p>
          或者，不用滑鼠左鍵單擊該連結，你也可以在該連結上單擊滑鼠右鍵，將其複製到您的剪貼簿裡。接下來您就可以在電子郵件裡貼上該連結，寄給您的參與者。當接到郵件，他們可用自己的電腦或是智慧型手機開啟該連結，然後自行填寫健康調查量表。這對於無法進中心填寫後續調查的參與者很有幫助，與現場調查一樣，此連結是只為該參與者綁定且為一次性使用的網址。
        </p>
        <p>
          當參與者填完調查表後，會出現一則感謝的訊息，表示該調查表已完成。與示範調查表不同的是，參與者的調查表結果不會即刻出現在螢幕上，這是要確保他們在結果出來後有機會跟您進行諮詢。
        </p>
      </div>
      <div>
        <h2>調查中心</h2>
        <p>
          調查中心允許您查看並儲存從蘭德
          SF-36調查表的八個面向的參與者調查數據中所獲取的圖表。您可以從「調查中心」的下拉式選單中選取您要查看的PID，其圖表會伴隨調查的結果顯示出來。顯示的預設調查結果是依據蘭德公司針對美國人所做的總體標準值以及您所挑選的參與者已填寫的調查表製成。點擊圖表下方的標籤，將會禁用那個調查項目且這個項目會在圖表中隱藏起來。若再次點擊禁用的項目，則可重新啟用並顯示在圖表中。這可讓您完全掌控任何您想要進行比較或進行最佳分析的調查。
        </p>
        <p>
          您可以透過圖形框架右上方的漢堡選單(三條橫槓)，選擇您要的形式將圖表儲存在您的本地端電腦裡。
        </p>
      </div>
      <div>
        <h2>帳號設定</h2>
        <p>帳號設定頁面允許您更新您的簡介設定，例如您的帳號密碼。</p>
      </div>
    </div>
  );
};
