import React from "react";
import randQuestionnaire from "./randsf36";

// Questionnaires
import spanishQuestionnaire from './randsf36-Questionnaires/spanish'
import chineseQuestionnaire from './randsf36-Questionnaires/chinese'

export const randQuestionsAndAnswersAllLangs: randQuestionnaire[] = [
  {
    language: "English",
    questionsAndAnswers: [
      {
        questionText: <span>In general, would you say your health is:</span>,
        answers: [
          {
            answerText: "Excellent",
            value: 100,
          },
          {
            answerText: "Very good",
            value: 75,
          },
          {
            answerText: "Good",
            value: 50,
          },
          {
            answerText: "Fair",
            value: 25,
          },
          {
            answerText: "Poor",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Compared to one year ago</b>, how would you rate your health in
            general <b>now</b>?
          </span>
        ),
        answers: [
          {
            answerText: "Much better than one year ago",
            value: 100,
          },
          {
            answerText: "Somewhat better than one year ago",
            value: 75,
          },
          {
            answerText: "About the same",
            value: 50,
          },
          {
            answerText: "Somewhat worse now than one year ago",
            value: 25,
          },
          {
            answerText: "Much worse now than one year ago",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Vigorous activities</b>, such as running, lifting heavy objects,
            participating in strenuous sports
          </span>
        ),
        answers: [
          {
            answerText: "Yes, limited a lot",
            value: 0,
          },

          {
            answerText: "Yes, limited a little",
            value: 50,
          },
          {
            answerText: "No, not limited at all",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Moderate activities</b>, such as moving a table, pushing a vacuum
            cleaner, bowling, or playing golf
          </span>
        ),
        answers: [
          {
            answerText: "Yes, limited a lot",
            value: 0,
          },

          {
            answerText: "Yes, limited a little",
            value: 50,
          },
          {
            answerText: "No, not limited at all",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Lifting or carrying groceries</span>,
        answers: [
          {
            answerText: "Yes, limited a lot",
            value: 0,
          },

          {
            answerText: "Yes, limited a little",
            value: 50,
          },
          {
            answerText: "No, not limited at all",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Climbing <b>several</b> flights of stairs
          </span>
        ),
        answers: [
          {
            answerText: "Yes, limited a lot",
            value: 0,
          },

          {
            answerText: "Yes, limited a little",
            value: 50,
          },
          {
            answerText: "No, not limited at all",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Climbing <b>one</b> flight of stairs
          </span>
        ),
        answers: [
          {
            answerText: "Yes, limited a lot",
            value: 0,
          },

          {
            answerText: "Yes, limited a little",
            value: 50,
          },
          {
            answerText: "No, not limited at all",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Bending, kneeling, or stooping</span>,
        answers: [
          {
            answerText: "Yes, limited a lot",
            value: 0,
          },

          {
            answerText: "Yes, limited a little",
            value: 50,
          },
          {
            answerText: "No, not limited at all",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Walking <b>more than a mile</b>
          </span>
        ),
        answers: [
          {
            answerText: "Yes, limited a lot",
            value: 0,
          },

          {
            answerText: "Yes, limited a little",
            value: 50,
          },
          {
            answerText: "No, not limited at all",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Walking <b>several blocks</b>
          </span>
        ),
        answers: [
          {
            answerText: "Yes, limited a lot",
            value: 0,
          },

          {
            answerText: "Yes, limited a little",
            value: 50,
          },
          {
            answerText: "No, not limited at all",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Walking <b>one block</b>
          </span>
        ),
        answers: [
          {
            answerText: "Yes, limited a lot",
            value: 0,
          },

          {
            answerText: "Yes, limited a little",
            value: 50,
          },
          {
            answerText: "No, not limited at all",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Bathing</b> or dressing yourself
          </span>
        ),
        answers: [
          {
            answerText: "Yes, limited a lot",
            value: 0,
          },

          {
            answerText: "Yes, limited a little",
            value: 50,
          },
          {
            answerText: "No, not limited at all",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Cut down on the <b>amount of time</b> you spent on work or other
            activities
          </span>
        ),
        answers: [
          {
            answerText: "Yes",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Accomplished less</b> than you would like
          </span>
        ),
        answers: [
          {
            answerText: "Yes",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Were limited in the <b>kind</b> of work or other activities
          </span>
        ),
        answers: [
          {
            answerText: "Yes",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Had <b>difficulty</b> performing the work or other activities (for
            example, it took extra effort)
          </span>
        ),
        answers: [
          {
            answerText: "Yes",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Cut down the <b>amount of time</b> you spent on work or other
            activities
          </span>
        ),
        answers: [
          {
            answerText: "Yes",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Accomplished less</b> than you would like
          </span>
        ),
        answers: [
          {
            answerText: "Yes",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Didn't do work or other activities as <b>carefully</b> as usual
          </span>
        ),
        answers: [
          {
            answerText: "Yes",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            During the <b>past 4 weeks,</b> to what extent has your physical
            health or emotional problems interfered with your normal social
            activities with family, friends, neighbors, or groups?
          </span>
        ),
        answers: [
          {
            answerText: "Not at all",
            value: 100,
          },
          {
            answerText: "Slightly",
            value: 75,
          },
          {
            answerText: "Moderately",
            value: 50,
          },
          {
            answerText: "Quite a bit",
            value: 25,
          },
          {
            answerText: "Extremely",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            How much <b>bodily</b> pain have you had during the{" "}
            <b>past 4 weeks?</b>
          </span>
        ),
        answers: [
          {
            answerText: "None",
            value: 100,
          },
          {
            answerText: "Very mild",
            value: 80,
          },
          {
            answerText: "Mild",
            value: 60,
          },
          {
            answerText: "Moderate",
            value: 40,
          },
          {
            answerText: "Severe",
            value: 20,
          },
          {
            answerText: "Very severe",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            During the <b>past 4 weeks</b>, how much did <b>pain</b> interfere
            with your normal work (including both work outside the home and
            housework)?
          </span>
        ),
        answers: [
          {
            answerText: "Not at all",
            value: 100,
          },
          {
            answerText: "Slightly",
            value: 75,
          },
          {
            answerText: "Moderately",
            value: 50,
          },
          {
            answerText: "Quite a bit",
            value: 25,
          },
          {
            answerText: "Extremely",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Did you feel full of pep?</span>,
        answers: [
          {
            answerText: "All of the time",
            value: 100,
          },
          {
            answerText: "Most of the time",
            value: 80,
          },
          {
            answerText: "A good bit of the time",
            value: 60,
          },
          {
            answerText: "Some of the time",
            value: 40,
          },
          {
            answerText: "A little of the time",
            value: 20,
          },
          {
            answerText: "None of the time",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Have you been a very nervous person?</span>,
        answers: [
          {
            answerText: "All of the time",
            value: 0,
          },
          {
            answerText: "Most of the time",
            value: 20,
          },
          {
            answerText: "A good bit of the time",
            value: 40,
          },
          {
            answerText: "Some of the time",
            value: 60,
          },
          {
            answerText: "A little of the time",
            value: 80,
          },
          {
            answerText: "None of the time",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Have you felt so down in the dumps that nothing could cheer you up?
          </span>
        ),
        answers: [
          {
            answerText: "All of the time",
            value: 0,
          },
          {
            answerText: "Most of the time",
            value: 20,
          },
          {
            answerText: "A good bit of the time",
            value: 40,
          },
          {
            answerText: "Some of the time",
            value: 60,
          },
          {
            answerText: "A little of the time",
            value: 80,
          },
          {
            answerText: "None of the time",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Have you felt calm and peaceful?</span>,
        answers: [
          {
            answerText: "All of the time",
            value: 100,
          },
          {
            answerText: "Most of the time",
            value: 80,
          },
          {
            answerText: "A good bit of the time",
            value: 60,
          },
          {
            answerText: "Some of the time",
            value: 40,
          },
          {
            answerText: "A little of the time",
            value: 20,
          },
          {
            answerText: "None of the time",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Did you have a lot of energy?</span>,
        answers: [
          {
            answerText: "All of the time",
            value: 100,
          },
          {
            answerText: "Most of the time",
            value: 80,
          },
          {
            answerText: "A good bit of the time",
            value: 60,
          },
          {
            answerText: "Some of the time",
            value: 40,
          },
          {
            answerText: "A little of the time",
            value: 20,
          },
          {
            answerText: "None of the time",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Have you felt downhearted and blue?</span>,
        answers: [
          {
            answerText: "All of the time",
            value: 0,
          },
          {
            answerText: "Most of the time",
            value: 20,
          },
          {
            answerText: "A good bit of the time",
            value: 40,
          },
          {
            answerText: "Some of the time",
            value: 60,
          },
          {
            answerText: "A little of the time",
            value: 80,
          },
          {
            answerText: "None of the time",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Did you feel worn out?</span>,
        answers: [
          {
            answerText: "All of the time",
            value: 0,
          },
          {
            answerText: "Most of the time",
            value: 20,
          },
          {
            answerText: "A good bit of the time",
            value: 40,
          },
          {
            answerText: "Some of the time",
            value: 60,
          },
          {
            answerText: "A little of the time",
            value: 80,
          },
          {
            answerText: "None of the time",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Have you been a happy person?</span>,
        answers: [
          {
            answerText: "All of the time",
            value: 100,
          },
          {
            answerText: "Most of the time",
            value: 80,
          },
          {
            answerText: "A good bit of the time",
            value: 60,
          },
          {
            answerText: "Some of the time",
            value: 40,
          },
          {
            answerText: "A little of the time",
            value: 20,
          },
          {
            answerText: "None of the time",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Did you feel tired?</span>,
        answers: [
          {
            answerText: "All of the time",
            value: 0,
          },
          {
            answerText: "Most of the time",
            value: 20,
          },
          {
            answerText: "A good bit of the time",
            value: 40,
          },
          {
            answerText: "Some of the time",
            value: 60,
          },
          {
            answerText: "A little of the time",
            value: 80,
          },
          {
            answerText: "None of the time",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            During the <b>past 4 weeks</b>, how much of the time has
            <b>your physical health or emotional problems</b> interfered with
            your social activities (like visiting with friends, relatives,
            etc.)?
          </span>
        ),
        answers: [
          {
            answerText: "All of the time",
            value: 0,
          },
          {
            answerText: "Most of the time",
            value: 25,
          },
          {
            answerText: "Some of the time",
            value: 50,
          },
          {
            answerText: "A little of the time",
            value: 75,
          },
          {
            answerText: "None of the time",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>I seem to get sick a little easier than other people</span>
        ),
        answers: [
          {
            answerText: "Definitely true",
            value: 0,
          },
          {
            answerText: "Mostly true",
            value: 25,
          },
          {
            answerText: "Don't know",
            value: 50,
          },
          {
            answerText: "Mostly false",
            value: 75,
          },
          {
            answerText: "Definitely false",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>I am as healthy as anybody I know</span>,
        answers: [
          {
            answerText: "Definitely true",
            value: 100,
          },
          {
            answerText: "Mostly true",
            value: 75,
          },
          {
            answerText: "Don't know",
            value: 50,
          },
          {
            answerText: "Mostly false",
            value: 25,
          },
          {
            answerText: "Definitely false",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>I expect my health to get worse</span>,
        answers: [
          {
            answerText: "Definitely true",
            value: 0,
          },
          {
            answerText: "Mostly true",
            value: 25,
          },
          {
            answerText: "Don't know",
            value: 50,
          },
          {
            answerText: "Mostly false",
            value: 75,
          },
          {
            answerText: "Definitely false",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>My health is excellent</span>,
        answers: [
          {
            answerText: "Definitely true",
            value: 100,
          },
          {
            answerText: "Mostly true",
            value: 75,
          },
          {
            answerText: "Don't know",
            value: 50,
          },
          {
            answerText: "Mostly false",
            value: 25,
          },
          {
            answerText: "Definitely false",
            value: 0,
          },
        ],
      },
    ],
    headers: [
      <span>
        The following items are about activities you might do during a typical
        day. Does <b>your health now limit you</b> in these activities? If so,
        how much?
      </span>,
      <span>
        During the <b>past 4 weeks</b>, have you had any of the following
        problems with your work or other regular daily activities
        <b>as a result of your physical health?</b>
      </span>,
      <span>
        During the <b>past 4 weeks</b>, have you had any of the following
        problems with your work or other regular daily activities{" "}
        <b>as a result of any emotional problems</b> (such as feeling depressed
        or anxious)?
      </span>,
      <span>
        These questions are about how you feel and how things have been with you{" "}
        <b>during the past 4 weeks.</b> For each question, please give the one
        answer that comes closest to the way you have been feeling.
        <br />
        How much of the time during the <b>past 4 weeks...</b>
      </span>,
      <span>
        How TRUE or FALSE is <b>each</b> of the following statements for you.
      </span>,
    ],
  },
  {
    language: "Tiếng Việt",
    questionsAndAnswers: [
      {
        questionText: <span>Nói chung, bạn sẽ nói sức khỏe của bạn là:</span>,
        answers: [
          {
            answerText: "Tuyệt vời",
            value: 100,
          },
          {
            answerText: "Rất tốt",
            value: 75,
          },
          {
            answerText: "Tốt",
            value: 50,
          },
          {
            answerText: "Hơi",
            value: 25,
          },
          {
            answerText: "kém",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>So với một năm trước</b>, bạn đánh giá sức khỏe của bạn nói chung{" "}
            <b>bây giờ</b>như thế nào?
          </span>
        ),
        answers: [
          {
            answerText: "Bây giờ tốt hơn nhiều so với một năm trước",
            value: 100,
          },
          {
            answerText: "Bây giờ tốt hơn năm trước một chút",
            value: 75,
          },
          {
            answerText: "Bây giờ vẫn giống như một năm trước",
            value: 50,
          },
          {
            answerText: "Bây giờ có phần nào tệ hơn một năm trước",
            value: 25,
          },
          {
            answerText: "Bây giờ tệ hơn nhiều so với một năm trước",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Các hoạt động mạnh mẽ</b>, như chạy, nâng vật nặng, tham gia các
            môn thể thao vất vả
          </span>
        ),
        answers: [
          {
            answerText: "Có, hạn chế rất nhiều",
            value: 0,
          },

          {
            answerText: "Có, hạn chế một chút",
            value: 50,
          },
          {
            answerText: "Không, không hề hạn chế chút nào",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Các hoạt động vừa phải</b>, chẳng hạn như di chuyển một cái bàn,
            đẩy một máy hút bụi, chơi bowling hoặc chơi đánh gôn
          </span>
        ),
        answers: [
          {
            answerText: "Có, hạn chế rất nhiều",
            value: 0,
          },

          {
            answerText: "Có, hạn chế một chút",
            value: 50,
          },
          {
            answerText: "Không, không hề hạn chế chút nào",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Nâng hoặc mang vật tư hoặc hàng tạp hóa</span>,
        answers: [
          {
            answerText: "Có, hạn chế rất nhiều",
            value: 0,
          },

          {
            answerText: "Có, hạn chế một chút",
            value: 50,
          },
          {
            answerText: "Không, không hề hạn chế chút nào",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Leo lên <b>nhiều</b> tầng cầu thang
          </span>
        ),
        answers: [
          {
            answerText: "Có, hạn chế rất nhiều",
            value: 0,
          },

          {
            answerText: "Có, hạn chế một chút",
            value: 50,
          },
          {
            answerText: "Không, không hề hạn chế chút nào",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Leo lên <b>một</b> tầng cầu thang
          </span>
        ),
        answers: [
          {
            answerText: "Có, hạn chế rất nhiều",
            value: 0,
          },

          {
            answerText: "Có, hạn chế một chút",
            value: 50,
          },
          {
            answerText: "Không, không hề hạn chế chút nào",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Vặn mình, quỳ hoặc cúi xuống</span>,
        answers: [
          {
            answerText: "Có, hạn chế rất nhiều",
            value: 0,
          },

          {
            answerText: "Có, hạn chế một chút",
            value: 50,
          },
          {
            answerText: "Không, không hề hạn chế chút nào",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Đi bộ <b>hơn một dặm</b> (khoảng 2km trở lên)
          </span>
        ),
        answers: [
          {
            answerText: "Có, hạn chế rất nhiều",
            value: 0,
          },

          {
            answerText: "Có, hạn chế một chút",
            value: 50,
          },
          {
            answerText: "Không, không hề hạn chế chút nào",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Đi bộ <b>vài khu nhà</b>
          </span>
        ),
        answers: [
          {
            answerText: "Có, hạn chế rất nhiều",
            value: 0,
          },

          {
            answerText: "Có, hạn chế một chút",
            value: 50,
          },
          {
            answerText: "Không, không hề hạn chế chút nào",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Đi bộ <b>một khu nhà</b>
          </span>
        ),
        answers: [
          {
            answerText: "Có, hạn chế rất nhiều",
            value: 0,
          },

          {
            answerText: "Có, hạn chế một chút",
            value: 50,
          },
          {
            answerText: "Không, không hề hạn chế chút nào",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Tự mình <b>tắm</b> hoặc tự mình mặc quần áo
          </span>
        ),
        answers: [
          {
            answerText: "Có, hạn chế rất nhiều",
            value: 0,
          },

          {
            answerText: "Có, hạn chế một chút",
            value: 50,
          },
          {
            answerText: "Không, không hề hạn chế chút nào",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Giảm <b>lượng thời gian</b> mà bạn dành cho công việc hoặc các hoạt
            động khác
          </span>
        ),
        answers: [
          {
            answerText: "Có",
            value: 0,
          },

          {
            answerText: "Không",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Accomplished less</b> so với điều bạn muốn
          </span>
        ),
        answers: [
          {
            answerText: "Có",
            value: 0,
          },

          {
            answerText: "Không",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Bị hạn chế trong các <b>thể loại</b> công việc hoặc các hoạt động
            khác
          </span>
        ),
        answers: [
          {
            answerText: "Có",
            value: 0,
          },

          {
            answerText: "Không",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Gặp <b>khó khăn</b> khi thực hiện công việc thường xuyên hoặc các
            hoạt động khác (ví dụ: phải nỗ lực thêm)
          </span>
        ),
        answers: [
          {
            answerText: "Có",
            value: 0,
          },

          {
            answerText: "Không",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Giảm <b>lượng thời gian</b> mà bạn dành cho công việc hoặc các hoạt
            động khác
          </span>
        ),
        answers: [
          {
            answerText: "Có",
            value: 0,
          },

          {
            answerText: "Không",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Đạt được ít hơn</b> so với điều bạn muốn
          </span>
        ),
        answers: [
          {
            answerText: "Có",
            value: 0,
          },

          {
            answerText: "Không",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Không làm công việc hoặc các hoạt động khác <b>cẩn thận</b> như
            thông thường
          </span>
        ),
        answers: [
          {
            answerText: "Có",
            value: 0,
          },

          {
            answerText: "Không",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Trong <b>4 tuần </b> qua, sức khỏe thể chất hoặc các vấn đề tình cảm
            nào của bạn đã can thiệp vào các hoạt động xã hội bình thường của
            bạn với gia đình, bạn bè, hàng xóm hoặc các nhóm ở mức độ nào?
          </span>
        ),
        answers: [
          {
            answerText: "Không hề có",
            value: 100,
          },
          {
            answerText: "Có một chút",
            value: 75,
          },
          {
            answerText: "Vừa phải",
            value: 50,
          },
          {
            answerText: "Khá nhiều",
            value: 25,
          },
          {
            answerText: "Rất nhiều",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            Bạn đã chịu bao nhiêu đau đớn <b>về cơ thể</b> trong <b>4 tuần</b>
            qua?
          </span>
        ),
        answers: [
          {
            answerText: "Không hề có",
            value: 100,
          },
          {
            answerText: "Rất nhẹ",
            value: 80,
          },
          {
            answerText: "Nhẹ",
            value: 60,
          },
          {
            answerText: "Vừa phải",
            value: 40,
          },
          {
            answerText: "Nặng",
            value: 20,
          },
          {
            answerText: "Rất nghiêm trọng",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            Trong <b>4 tuần</b>, qua, cơn đau đã ảnh hưởng thế nào đến công việc
            bình thường của bạn (bao gồm cả việc làm và công việc quanh nhà)?
          </span>
        ),
        answers: [
          {
            answerText: "Không hề có",
            value: 100,
          },
          {
            answerText: "Có một chút ",
            value: 75,
          },
          {
            answerText: "Vừa phải",
            value: 50,
          },
          {
            answerText: "Khá nhiều",
            value: 25,
          },
          {
            answerText: "Rất nhiều",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Bạn có cảm thấy tràn đầy khí lực</span>,
        answers: [
          {
            answerText: "Toàn bộ thời gian",
            value: 100,
          },
          {
            answerText: "Hầu hết thời gian",
            value: 80,
          },
          {
            answerText: "Một chút thời gian",
            value: 60,
          },
          {
            answerText: "Thỉnh thoảng",
            value: 40,
          },
          {
            answerText: "Họa hoằn",
            value: 20,
          },
          {
            answerText: "Không hề",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Bạn có từng là một người rất lo lắng không?</span>,
        answers: [
          {
            answerText: "Toàn bộ thời gian",
            value: 0,
          },
          {
            answerText: "Hầu hết thời gian",
            value: 20,
          },
          {
            answerText: "Một chút thời gian",
            value: 40,
          },
          {
            answerText: "Thỉnh thoảng",
            value: 60,
          },
          {
            answerText: "Họa hoằn",
            value: 80,
          },
          {
            answerText: "Không hề",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Có khi nào bạn từng cảm thấy rất buồn chán mà không có gì có thể làm
            bạn vui lên?
          </span>
        ),
        answers: [
          {
            answerText: "Toàn bộ thời gian",
            value: 0,
          },
          {
            answerText: "Hầu hết thời gian",
            value: 20,
          },
          {
            answerText: "Một chút thời gian",
            value: 40,
          },
          {
            answerText: "Thỉnh thoảng",
            value: 60,
          },
          {
            answerText: "Họa hoằn",
            value: 80,
          },
          {
            answerText: "Không hề",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Bạn đã cảm thấy bình tĩnh và yên bình?</span>,
        answers: [
          {
            answerText: "Toàn bộ thời gian",
            value: 100,
          },
          {
            answerText: "Hầu hết thời gian",
            value: 80,
          },
          {
            answerText: "Một chút thời gian",
            value: 60,
          },
          {
            answerText: "Thỉnh thoảng",
            value: 40,
          },
          {
            answerText: "Họa hoằn",
            value: 20,
          },
          {
            answerText: "Không hề",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Bạn có nhiều năng lượng không?</span>,
        answers: [
          {
            answerText: "Toàn bộ thời gian",
            value: 100,
          },
          {
            answerText: "Hầu hết thời gian",
            value: 80,
          },
          {
            answerText: "Một chút thời gian",
            value: 60,
          },
          {
            answerText: "Thỉnh thoảng",
            value: 40,
          },
          {
            answerText: "Họa hoằn",
            value: 20,
          },
          {
            answerText: "Không hề",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>Có bao giờ bạn cảm thấy chán nản và thất vọng không?</span>
        ),
        answers: [
          {
            answerText: "Toàn bộ thời gian",
            value: 0,
          },
          {
            answerText: "Hầu hết thời gian",
            value: 20,
          },
          {
            answerText: "Một chút thời gian",
            value: 40,
          },
          {
            answerText: "Thỉnh thoảng",
            value: 60,
          },
          {
            answerText: "Họa hoằn",
            value: 80,
          },
          {
            answerText: "Không hề",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Bạn có cảm thấy mệt mỏi rã rời không?</span>,
        answers: [
          {
            answerText: "Toàn bộ thời gian",
            value: 0,
          },
          {
            answerText: "Hầu hết thời gian",
            value: 20,
          },
          {
            answerText: "Một chút thời gian",
            value: 40,
          },
          {
            answerText: "Thỉnh thoảng",
            value: 60,
          },
          {
            answerText: "Họa hoằn",
            value: 80,
          },
          {
            answerText: "Không hề",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>Bạn đã từng bao giờ là một người hạnh phúc chưa?</span>
        ),
        answers: [
          {
            answerText: "Toàn bộ thời gian",
            value: 100,
          },
          {
            answerText: "Hầu hết thời gian",
            value: 80,
          },
          {
            answerText: "Một chút thời gian",
            value: 60,
          },
          {
            answerText: "Thỉnh thoảng",
            value: 40,
          },
          {
            answerText: "Họa hoằn",
            value: 20,
          },
          {
            answerText: "Không hề",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Bạn có cảm thấy mệt mỏi không?</span>,
        answers: [
          {
            answerText: "Toàn bộ thời gian",
            value: 0,
          },
          {
            answerText: "Hầu hết thời gian",
            value: 20,
          },
          {
            answerText: "Một chút thời gian",
            value: 40,
          },
          {
            answerText: "Thỉnh thoảng",
            value: 60,
          },
          {
            answerText: "Họa hoằn",
            value: 80,
          },
          {
            answerText: "Không hề",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Trong <b>4 tuần qua</b>, các vấn đề về sức khỏe thể chất hoặc các
            vấn đề về cảm xúc của bạn đã can thiệp vào các hoạt động xã hội của
            bạn (như thăm bạn bè, người thân, v.v.) bao nhiêu thời gian?
          </span>
        ),
        answers: [
          {
            answerText: "Toàn bộ thời gian",
            value: 0,
          },
          {
            answerText: "Hầu hết thời gian",
            value: 25,
          },
          {
            answerText: "Some of the time",
            value: 50,
          },
          {
            answerText: "Họa hoằn",
            value: 75,
          },
          {
            answerText: "Không hề",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Tôi dường như hơi dễ bị bệnh</span>,
        answers: [
          {
            answerText: "Rất đúng",
            value: 0,
          },
          {
            answerText: "Gần đúng",
            value: 25,
          },
          {
            answerText: "Không biết",
            value: 50,
          },
          {
            answerText: "Gần như sai",
            value: 75,
          },
          {
            answerText: "Hoàn toàn sai",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Tôi khỏe mạnh như bất kỳ ai tôi biết</span>,
        answers: [
          {
            answerText: "Rất đúng",
            value: 100,
          },
          {
            answerText: "Gần đúng",
            value: 75,
          },
          {
            answerText: "Không biết",
            value: 50,
          },
          {
            answerText: "Gần như sai",
            value: 25,
          },
          {
            answerText: "Hoàn toàn sai",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>Tôi hy vọng sức khỏe của tôi sẽ trở nên tồi tệ hơn</span>
        ),
        answers: [
          {
            answerText: "Rất đúng",
            value: 0,
          },
          {
            answerText: "Gần đúng",
            value: 25,
          },
          {
            answerText: "Không biết",
            value: 50,
          },
          {
            answerText: "Gần như sai",
            value: 75,
          },
          {
            answerText: "Hoàn toàn sai",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Sức khỏe của tôi rất tuyệt vời</span>,
        answers: [
          {
            answerText: "Rất đúng",
            value: 100,
          },
          {
            answerText: "Gần đúng",
            value: 75,
          },
          {
            answerText: "Không biết",
            value: 50,
          },
          {
            answerText: "Gần như sai",
            value: 25,
          },
          {
            answerText: "Hoàn toàn sai",
            value: 0,
          },
        ],
      },
    ],
    headers: [
      <span>
        Các mục sau đây là về các hoạt động mà bạn có thể làm trong một ngày
        thông thường. <b>Sức khỏe của bạn bây giờ có hạn chế bạn </b>trong các
        hoạt động này không? Nếu có, thì bao nhiêu?
      </span>,
      <span>
        Trong <b>4 tuần</b>qua, bạn có gặp phải bất kỳ vấn đề nào sau đây với
        công việc của bạn hoặc các hoạt động thường xuyên khác hàng ngày
        <b>do sức khỏe thể chất của bạn không?</b>
      </span>,
      <span>
        Trong <b>4 tuần</b>, qua, bạn có gặp phải bất kỳ vấn đề nào sau đây với
        công việc của bạn hoặc các hoạt động thường xuyên khác hàng ngày
        <b>do sức khỏe thể chất của bạn không?</b>?
      </span>,
      <span>
        Những câu hỏi này là về việc bạn cảm thấy như thế nào và mọi thứ đã ở
        bên bạn <b>trong 4 tuần vừa qua</b> như thế nào. Đối với mỗi câu hỏi,
        xin vui lòng đưa ra một câu trả lời gần nhất với cách mà bạn đã cảm nhận
        được.
        <br />
        Bao nhiêu thời gian trong <b>4 tuần vừa qua ...</b>
      </span>,
      <span>
        ĐÚNG hoặc SAI như thế nào là <b>mỗi</b> câu sau đây giành cho bạn?
      </span>,
    ],
  },
  {
    language: "Italiano",
    questionsAndAnswers: [
      {
        questionText: (
          <span>In generale diresti che il tuo stato di salute è</span>
        ),
        answers: [
          {
            answerText: "Eccellente",
            value: 100,
          },
          {
            answerText: "Molto buono",
            value: 75,
          },
          {
            answerText: "Buono",
            value: 50,
          },
          {
            answerText: "Piuttosto",
            value: 25,
          },
          {
            answerText: "cattivo",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Se paragonato ad un anno fa</b>, come considereresti il tuo
            general stato di salute <b>oggi</b>?
          </span>
        ),
        answers: [
          {
            answerText: "Molto meglio di un anno fa",
            value: 100,
          },
          {
            answerText: "Piuttosto meglio ora rispetto ad un anno fa",
            value: 75,
          },
          {
            answerText: "Più o  meno lo stesso che un anno fa",
            value: 50,
          },
          {
            answerText: "Piuttosto peggio rispetto ad un anno fa",
            value: 25,
          },
          {
            answerText: "Molto peggio ora rispetto ad un anno fa",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Attivitá vigorose</b> come correre, sollevare oggetti pesanti,
            partecipare a sport faticosi
          </span>
        ),
        answers: [
          {
            answerText: "Sí, mi limita molto",
            value: 0,
          },

          {
            answerText: "Sí, mi limita un po’",
            value: 50,
          },
          {
            answerText: "No, non mi limita affatto",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Attivitá moderate</b>, come spostare un tavolo, passare
            l’aspirapolvere, giocare a bowling o giocare a golf.
          </span>
        ),
        answers: [
          {
            answerText: "Sí, mi limita molto",
            value: 0,
          },

          {
            answerText: "Sí, mi limita un po’",
            value: 50,
          },
          {
            answerText: "No, non mi limita affatto",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Sollevare o portare la spesa </span>,
        answers: [
          {
            answerText: "Sí, mi limita molto",
            value: 0,
          },

          {
            answerText: "Sí, mi limita un po’",
            value: 50,
          },
          {
            answerText: "No, non mi limita affatto",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Salire <b>diverse</b> rampe di scale
          </span>
        ),
        answers: [
          {
            answerText: "Sí, mi limita molto",
            value: 0,
          },

          {
            answerText: "Sí, mi limita un po’",
            value: 50,
          },
          {
            answerText: "No, non mi limita affatto",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Salire <b>una</b> rampa di scale
          </span>
        ),
        answers: [
          {
            answerText: "Sí, mi limita molto",
            value: 0,
          },

          {
            answerText: "Sí, mi limita un po’",
            value: 50,
          },
          {
            answerText: "No, non mi limita affatto",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Piegarsi, inginocchiarsi o abbassarsi. </span>,
        answers: [
          {
            answerText: "Sí, mi limita molto",
            value: 0,
          },

          {
            answerText: "Sí, mi limita un po’",
            value: 50,
          },
          {
            answerText: "No, non mi limita affatto",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Camminare <b>per oltre un miglio</b> (un Km e mezzo)
          </span>
        ),
        answers: [
          {
            answerText: "Sí, mi limita molto",
            value: 0,
          },

          {
            answerText: "Sí, mi limita un po’",
            value: 50,
          },
          {
            answerText: "No, non mi limita affatto",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Camminare per <b>diversi isolati</b>
          </span>
        ),
        answers: [
          {
            answerText: "Sí, mi limita molto",
            value: 0,
          },

          {
            answerText: "Sí, mi limita un po’",
            value: 50,
          },
          {
            answerText: "No, non mi limita affatto",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Camminare per <b>un isolato</b>
          </span>
        ),
        answers: [
          {
            answerText: "Sí, mi limita molto",
            value: 0,
          },

          {
            answerText: "Sí, mi limita un po’",
            value: 50,
          },
          {
            answerText: "No, non mi limita affatto",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            <b>Fare il bagno</b> o vestirti
          </span>
        ),
        answers: [
          {
            answerText: "Sí, mi limita molto",
            value: 0,
          },

          {
            answerText: "Sí, mi limita un po’",
            value: 50,
          },
          {
            answerText: "No, non mi limita affatto",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Ridurre il <b>tempo speso</b> nel lavoro o in altre attivitá
          </span>
        ),
        answers: [
          {
            answerText: "Sí",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Hai <b>portato a termine</b> meno compiti meno di quello che avresti
            voluto.
          </span>
        ),
        answers: [
          {
            answerText: "Sí",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Eri limitato nel <b>genere</b> di lavoro o altre attivitá
          </span>
        ),
        answers: [
          {
            answerText: "Sí",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Avevi<b>difficoltá</b> ad eseguire il lavoro o altre attivitá (per
            esempio: hai dovuto sforzarti di più)
          </span>
        ),
        answers: [
          {
            answerText: "Sí",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Riduzione del <b>tempo speso</b> sul lavoro o in altre attivitá
          </span>
        ),
        answers: [
          {
            answerText: "Sí",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Hai <b>portato a termine</b> meno compiti di quello che avresti
            voluto
          </span>
        ),
        answers: [
          {
            answerText: "Sí",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Non hai fatto il lavoro o altre attivitá con la consueta{" "}
            <b>accuratezza</b>
          </span>
        ),
        answers: [
          {
            answerText: "Sí",
            value: 0,
          },

          {
            answerText: "No",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Nelle passate <b>4 settimane</b>, fino a che punto il tuo stato di
            salute o i tuoi problem emozionali hanno interferito con le tue
            attivitá sociali usuali con la tua famiglia, gli amici, i vicini di
            casa o con gruppi?
          </span>
        ),
        answers: [
          {
            answerText: "Per nulla",
            value: 100,
          },
          {
            answerText: "Un po’",
            value: 75,
          },
          {
            answerText: "Moderatamente",
            value: 50,
          },
          {
            answerText: "Abbastanza",
            value: 25,
          },
          {
            answerText: "Estremamente",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            Quanto dolore fisico hai avuto nelle passate
            <b>4 settiman?</b>
          </span>
        ),
        answers: [
          {
            answerText: "Nessuno",
            value: 100,
          },
          {
            answerText: "Poco",
            value: 80,
          },
          {
            answerText: "Leggero",
            value: 60,
          },
          {
            answerText: "Moderato ",
            value: 40,
          },
          {
            answerText: "Forte",
            value: 20,
          },
          {
            answerText: "Molto forte",
            value: 0,
          },
        ],
      },
      {
        questionText: (
          <span>
            Nelle passate <b>4 settimane</b>, quanto ha interferito il{" "}
            <b>dolore</b> con il tuo lavoro usuale (includendo sia l’impiego che
            il lavoro fatto in casa)?
          </span>
        ),
        answers: [
          {
            answerText: "Per nulla",
            value: 100,
          },
          {
            answerText: "Un po’",
            value: 75,
          },
          {
            answerText: "Moderatamente",
            value: 50,
          },
          {
            answerText: "Abbastanza",
            value: 25,
          },
          {
            answerText: "Estremamente",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Ti sei sentito pieno di vigore?</span>,
        answers: [
          {
            answerText: "Tutto il tempo",
            value: 100,
          },
          {
            answerText: "La maggior parte del tempo",
            value: 80,
          },
          {
            answerText: "Per buona parte del tempo",
            value: 60,
          },
          {
            answerText: "A volte",
            value: 40,
          },
          {
            answerText: "Poche volte",
            value: 20,
          },
          {
            answerText: "Mai",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Sei stato molto nervoso? </span>,
        answers: [
          {
            answerText: "Tutto il tempo",
            value: 0,
          },
          {
            answerText: "La maggior parte del tempo",
            value: 20,
          },
          {
            answerText: "Per buona parte del tempo",
            value: 40,
          },
          {
            answerText: "A volte",
            value: 60,
          },
          {
            answerText: "Poche volte",
            value: 80,
          },
          {
            answerText: "Mai",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Ti sei sentito cosí giù di corda che niente poteva tirarti su?
          </span>
        ),
        answers: [
          {
            answerText: "Tutto il tempo",
            value: 0,
          },
          {
            answerText: "La maggior parte del tempo",
            value: 20,
          },
          {
            answerText: "Per buona parte del tempo",
            value: 40,
          },
          {
            answerText: "A volte",
            value: 60,
          },
          {
            answerText: "Poche volte",
            value: 80,
          },
          {
            answerText: "Mai",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Ti sei sentito calmo e pacifico?</span>,
        answers: [
          {
            answerText: "Tutto il tempo",
            value: 100,
          },
          {
            answerText: "La maggior parte del tempo",
            value: 80,
          },
          {
            answerText: "Per buona parte del tempo",
            value: 60,
          },
          {
            answerText: "A volte",
            value: 40,
          },
          {
            answerText: "Poche volte",
            value: 20,
          },
          {
            answerText: "Mai",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Avevi molta energia?</span>,
        answers: [
          {
            answerText: "Tutto il tempo",
            value: 100,
          },
          {
            answerText: "La maggior parte del tempo",
            value: 80,
          },
          {
            answerText: "Per buona parte del tempo",
            value: 60,
          },
          {
            answerText: "A volte",
            value: 40,
          },
          {
            answerText: "Poche volte",
            value: 20,
          },
          {
            answerText: "Mai",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Ti sei sentito scoraggiato e depresso?</span>,
        answers: [
          {
            answerText: "Tutto il tempo",
            value: 0,
          },
          {
            answerText: "La maggior parte del tempo",
            value: 20,
          },
          {
            answerText: "Per buona parte del tempo",
            value: 40,
          },
          {
            answerText: "A volte",
            value: 60,
          },
          {
            answerText: "Poche volte",
            value: 80,
          },
          {
            answerText: "Mai",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Ti sei sentito esausto?</span>,
        answers: [
          {
            answerText: "Tutto il tempo",
            value: 0,
          },
          {
            answerText: "La maggior parte del tempo",
            value: 20,
          },
          {
            answerText: "Per buona parte del tempo",
            value: 40,
          },
          {
            answerText: "A volte",
            value: 60,
          },
          {
            answerText: "Poche volte",
            value: 80,
          },
          {
            answerText: "Mai",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Sei stato felice?</span>,
        answers: [
          {
            answerText: "Tutto il tempo",
            value: 100,
          },
          {
            answerText: "La maggior parte del tempo",
            value: 80,
          },
          {
            answerText: "Per buona parte del tempo",
            value: 60,
          },
          {
            answerText: "A volte",
            value: 40,
          },
          {
            answerText: "Poche volte",
            value: 20,
          },
          {
            answerText: "Mai",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Ti sei sentito stanco?</span>,
        answers: [
          {
            answerText: "Tutto il tempo",
            value: 0,
          },
          {
            answerText: "La maggior parte del tempo",
            value: 20,
          },
          {
            answerText: "Per buona parte del tempo",
            value: 40,
          },
          {
            answerText: "A volte",
            value: 60,
          },
          {
            answerText: "Poche volte",
            value: 80,
          },
          {
            answerText: "Mai",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>
            Nelle passate <b>4 settimane</b>, per quanto tempo il tuo stato di
            salute o i tuoi problemi emozionali hanno interferito con le tue
            attivitá sociali (far visita ad amici, parenti, ecc.)?
          </span>
        ),
        answers: [
          {
            answerText: "Tutto il tempo",
            value: 0,
          },
          {
            answerText: "La maggior parte del tempo",
            value: 25,
          },
          {
            answerText: "A volte",
            value: 50,
          },
          {
            answerText: "Poche volte",
            value: 75,
          },
          {
            answerText: "Mai",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>Sembra che io mi stia ammalando più facilmente</span>
        ),
        answers: [
          {
            answerText: "Decisamente vero",
            value: 0,
          },
          {
            answerText: "Perlopiù vero",
            value: 25,
          },
          {
            answerText: "Non saprei",
            value: 50,
          },
          {
            answerText: "Perlopiù falso",
            value: 75,
          },
          {
            answerText: "Decisamente falso",
            value: 100,
          },
        ],
      },
      {
        questionText: (
          <span>Sono tanto in salute quanto coloro che conosco</span>
        ),
        answers: [
          {
            answerText: "Decisamente vero",
            value: 100,
          },
          {
            answerText: "Perlopiù vero",
            value: 75,
          },
          {
            answerText: "Non saprei",
            value: 50,
          },
          {
            answerText: "Perlopiù falso",
            value: 25,
          },
          {
            answerText: "Decisamente falso",
            value: 0,
          },
        ],
      },
      {
        questionText: <span>Mi aspetto che la mia salute peggiori</span>,
        answers: [
          {
            answerText: "Decisamente vero",
            value: 0,
          },
          {
            answerText: "Perlopiù vero",
            value: 25,
          },
          {
            answerText: "Non saprei",
            value: 50,
          },
          {
            answerText: "Perlopiù falso",
            value: 75,
          },
          {
            answerText: "Decisamente falso",
            value: 100,
          },
        ],
      },
      {
        questionText: <span>Il mio stato di salute è eccellente</span>,
        answers: [
          {
            answerText: "Decisamente vero",
            value: 100,
          },
          {
            answerText: "Perlopiù vero",
            value: 75,
          },
          {
            answerText: "Non saprei",
            value: 50,
          },
          {
            answerText: "Perlopiù falso",
            value: 25,
          },
          {
            answerText: "Decisamente falso",
            value: 0,
          },
        ],
      },
    ],
    headers: [
      <span>
        Le voci che seguono riguardano attivitá che potresti fare in un giorno
        tipico. <b>La tua salute attuale ti limita</b> in queste attivitá? Se è
        cosí quanto ti limita?
      </span>,
      <span>
        Nelle passate <b>4 settimane</b>, hai avuto qualcuno dei seguenti
        problem con il tuo lavoro o altre attivitá giornaliere
        <b>come conseguenza della tua salute fisica?</b>
      </span>,
      <span>
        Nelle passate <b>4 settimane</b>, hai avuto qualcuno dei seguenti
        problemi nel tuo lavoro o nelle tue attivitá giornaliere
        <b>come conseguenza di problemi emozionali</b> (come sentirsi depresso o
        ansioso)?
      </span>,
      <span>
        Queste domande riguardano come ti senti e come ti sono andate le cose{" "}
        <b>nelle passate 4 settimane.</b> Per ciascuna domanda, per favore, dai
        la risposta che è più vicina al modo in cui ti senti. feeling.
        <br />
        Per quanto tempo nelle <b>passate 4 settimane...</b>
      </span>,
      <span>Quanto VERE o FALSE sono le seguenti affermazioni per te?</span>,
    ],
  },
  spanishQuestionnaire,
  chineseQuestionnaire
];

// export const rand36QuestionsBase = [
//   {
//     questionText: <span>In general, would you say your health is:</span>,
//     answers: [
//       {
//         answerText: "Excellent",
//         value: 100
//       },
//       {
//         answerText: "Very good",
//         value: 75
//       },
//       {
//         answerText: "Good",
//         value: 50
//       },
//       {
//         answerText: "Fair",
//         value: 25
//       },
//       {
//         answerText: "Poor",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>Compared to one year ago</b>, how would you rate your health in
//         general <b>now</b>?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Much better than one year ago",
//         value: 100
//       },
//       {
//         answerText: "Somewhat better than one year ago",
//         value: 75
//       },
//       {
//         answerText: "About the same",
//         value: 50
//       },
//       {
//         answerText: "Somewhat worse now than one year ago",
//         value: 25
//       },
//       {
//         answerText: "Much worse now than one year ago",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>Vigorous activities</b>, such as running, lifting heavy objects,
//         participating in strenuous sports
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes, limited a lot",
//         value: 0
//       },

//       {
//         answerText: "Yes, limited a little",
//         value: 50
//       },
//       {
//         answerText: "No, not limited at all",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>Moderate activities</b>, such as moving a table, pushing a vacuum
//         cleaner, bowling, or playing golf
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes, limited a lot",
//         value: 0
//       },

//       {
//         answerText: "Yes, limited a little",
//         value: 50
//       },
//       {
//         answerText: "No, not limited at all",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Lifting or carrying groceries</span>,
//     answers: [
//       {
//         answerText: "Yes, limited a lot",
//         value: 0
//       },

//       {
//         answerText: "Yes, limited a little",
//         value: 50
//       },
//       {
//         answerText: "No, not limited at all",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Climbing <b>several</b> flights of stairs
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes, limited a lot",
//         value: 0
//       },

//       {
//         answerText: "Yes, limited a little",
//         value: 50
//       },
//       {
//         answerText: "No, not limited at all",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Climbing <b>one</b> flight of stairs
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes, limited a lot",
//         value: 0
//       },

//       {
//         answerText: "Yes, limited a little",
//         value: 50
//       },
//       {
//         answerText: "No, not limited at all",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Bending, kneeling, or stooping</span>,
//     answers: [
//       {
//         answerText: "Yes, limited a lot",
//         value: 0
//       },

//       {
//         answerText: "Yes, limited a little",
//         value: 50
//       },
//       {
//         answerText: "No, not limited at all",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Walking <b>more than a mile</b>
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes, limited a lot",
//         value: 0
//       },

//       {
//         answerText: "Yes, limited a little",
//         value: 50
//       },
//       {
//         answerText: "No, not limited at all",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Walking <b>several blocks</b>
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes, limited a lot",
//         value: 0
//       },

//       {
//         answerText: "Yes, limited a little",
//         value: 50
//       },
//       {
//         answerText: "No, not limited at all",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Walking <b>one block</b>
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes, limited a lot",
//         value: 0
//       },

//       {
//         answerText: "Yes, limited a little",
//         value: 50
//       },
//       {
//         answerText: "No, not limited at all",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>Bathing</b> or dressing yourself
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes, limited a lot",
//         value: 0
//       },

//       {
//         answerText: "Yes, limited a little",
//         value: 50
//       },
//       {
//         answerText: "No, not limited at all",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Cut down on the <b>amount of time</b> you spent on work or other
//         activities
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes",
//         value: 0
//       },

//       {
//         answerText: "No",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>Accomplished less</b> than you would like
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes",
//         value: 0
//       },

//       {
//         answerText: "No",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Were limited in the <b>kind</b> of work or other activities
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes",
//         value: 0
//       },

//       {
//         answerText: "No",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Had <b>difficulty</b> performing the work or other activities (for
//         example, it took extra effort)
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes",
//         value: 0
//       },

//       {
//         answerText: "No",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Cut down the <b>amount of time</b> you spent on work or other activities
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes",
//         value: 0
//       },

//       {
//         answerText: "No",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>Accomplished less</b> than you would like
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes",
//         value: 0
//       },

//       {
//         answerText: "No",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Didn't do work or other activities as <b>carefully</b> as usual
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Yes",
//         value: 0
//       },

//       {
//         answerText: "No",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         During the <b>past 4 weeks,</b> to what extent has your physical health
//         or emotional problems interfered with your normal social activities with
//         family, friends, neighbors, or groups?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Not at all",
//         value: 100
//       },
//       {
//         answerText: "Slightly",
//         value: 75
//       },
//       {
//         answerText: "Moderately",
//         value: 50
//       },
//       {
//         answerText: "Quite a bit",
//         value: 25
//       },
//       {
//         answerText: "Extremely",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         How much <b>bodily</b> pain have you had during the <b>past 4 weeks?</b>
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "None",
//         value: 100
//       },
//       {
//         answerText: "Very mild",
//         value: 80
//       },
//       {
//         answerText: "Mild",
//         value: 60
//       },
//       {
//         answerText: "Moderate",
//         value: 40
//       },
//       {
//         answerText: "Severe",
//         value: 20
//       },
//       {
//         answerText: "Very severe",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         During the <b>past 4 weeks</b>, how much did <b>pain</b> interfere with
//         your normal work (including both work outside the home and housework)?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Not at all",
//         value: 100
//       },
//       {
//         answerText: "Slightly",
//         value: 75
//       },
//       {
//         answerText: "Moderately",
//         value: 50
//       },
//       {
//         answerText: "Quite a bit",
//         value: 25
//       },
//       {
//         answerText: "Extremely",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: <span>Did you feel full of pep?</span>,
//     answers: [
//       {
//         answerText: "All of the time",
//         value: 100
//       },
//       {
//         answerText: "Most of the time",
//         value: 80
//       },
//       {
//         answerText: "A good bit of the time",
//         value: 60
//       },
//       {
//         answerText: "Some of the time",
//         value: 40
//       },
//       {
//         answerText: "A little of the time",
//         value: 20
//       },
//       {
//         answerText: "None of the time",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: <span>Have you been a very nervous person?</span>,
//     answers: [
//       {
//         answerText: "All of the time",
//         value: 0
//       },
//       {
//         answerText: "Most of the time",
//         value: 20
//       },
//       {
//         answerText: "A good bit of the time",
//         value: 40
//       },
//       {
//         answerText: "Some of the time",
//         value: 60
//       },
//       {
//         answerText: "A little of the time",
//         value: 80
//       },
//       {
//         answerText: "None of the time",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Have you felt so down in the dumps that nothing could cheer you up?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "All of the time",
//         value: 0
//       },
//       {
//         answerText: "Most of the time",
//         value: 20
//       },
//       {
//         answerText: "A good bit of the time",
//         value: 40
//       },
//       {
//         answerText: "Some of the time",
//         value: 60
//       },
//       {
//         answerText: "A little of the time",
//         value: 80
//       },
//       {
//         answerText: "None of the time",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Have you felt calm and peaceful?</span>,
//     answers: [
//       {
//         answerText: "All of the time",
//         value: 100
//       },
//       {
//         answerText: "Most of the time",
//         value: 80
//       },
//       {
//         answerText: "A good bit of the time",
//         value: 60
//       },
//       {
//         answerText: "Some of the time",
//         value: 40
//       },
//       {
//         answerText: "A little of the time",
//         value: 20
//       },
//       {
//         answerText: "None of the time",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: <span>Did you have a lot of energy?</span>,
//     answers: [
//       {
//         answerText: "All of the time",
//         value: 100
//       },
//       {
//         answerText: "Most of the time",
//         value: 80
//       },
//       {
//         answerText: "A good bit of the time",
//         value: 60
//       },
//       {
//         answerText: "Some of the time",
//         value: 40
//       },
//       {
//         answerText: "A little of the time",
//         value: 20
//       },
//       {
//         answerText: "None of the time",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: <span>Have you felt downhearted and blue?</span>,
//     answers: [
//       {
//         answerText: "All of the time",
//         value: 0
//       },
//       {
//         answerText: "Most of the time",
//         value: 20
//       },
//       {
//         answerText: "A good bit of the time",
//         value: 40
//       },
//       {
//         answerText: "Some of the time",
//         value: 60
//       },
//       {
//         answerText: "A little of the time",
//         value: 80
//       },
//       {
//         answerText: "None of the time",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Did you feel worn out?</span>,
//     answers: [
//       {
//         answerText: "All of the time",
//         value: 0
//       },
//       {
//         answerText: "Most of the time",
//         value: 20
//       },
//       {
//         answerText: "A good bit of the time",
//         value: 40
//       },
//       {
//         answerText: "Some of the time",
//         value: 60
//       },
//       {
//         answerText: "A little of the time",
//         value: 80
//       },
//       {
//         answerText: "None of the time",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Have you been a happy person?</span>,
//     answers: [
//       {
//         answerText: "All of the time",
//         value: 100
//       },
//       {
//         answerText: "Most of the time",
//         value: 80
//       },
//       {
//         answerText: "A good bit of the time",
//         value: 60
//       },
//       {
//         answerText: "Some of the time",
//         value: 40
//       },
//       {
//         answerText: "A little of the time",
//         value: 20
//       },
//       {
//         answerText: "None of the time",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: <span>Did you feel tired?</span>,
//     answers: [
//       {
//         answerText: "All of the time",
//         value: 0
//       },
//       {
//         answerText: "Most of the time",
//         value: 20
//       },
//       {
//         answerText: "A good bit of the time",
//         value: 40
//       },
//       {
//         answerText: "Some of the time",
//         value: 60
//       },
//       {
//         answerText: "A little of the time",
//         value: 80
//       },
//       {
//         answerText: "None of the time",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         During the <b>past 4 weeks</b>, how much of the time has
//         <b>your physical health or emotional problems</b> interfered with your
//         social activities (like visiting with friends, relatives, etc.)?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "All of the time",
//         value: 0
//       },
//       {
//         answerText: "Most of the time",
//         value: 25
//       },
//       {
//         answerText: "Some of the time",
//         value: 50
//       },
//       {
//         answerText: "A little of the time",
//         value: 75
//       },
//       {
//         answerText: "None of the time",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>I seem to get sick a little easier than other people</span>
//     ),
//     answers: [
//       {
//         answerText: "Definitely true",
//         value: 0
//       },
//       {
//         answerText: "Mostly true",
//         value: 25
//       },
//       {
//         answerText: "Don't know",
//         value: 50
//       },
//       {
//         answerText: "Mostly false",
//         value: 75
//       },
//       {
//         answerText: "Definitely false",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>I am as healthy as anybody I know</span>,
//     answers: [
//       {
//         answerText: "Definitely true",
//         value: 100
//       },
//       {
//         answerText: "Mostly true",
//         value: 75
//       },
//       {
//         answerText: "Don't know",
//         value: 50
//       },
//       {
//         answerText: "Mostly false",
//         value: 25
//       },
//       {
//         answerText: "Definitely false",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: <span>I expect my health to get worse</span>,
//     answers: [
//       {
//         answerText: "Definitely true",
//         value: 0
//       },
//       {
//         answerText: "Mostly true",
//         value: 25
//       },
//       {
//         answerText: "Don't know",
//         value: 50
//       },
//       {
//         answerText: "Mostly false",
//         value: 75
//       },
//       {
//         answerText: "Definitely false",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>My health is excellent</span>,
//     answers: [
//       {
//         answerText: "Definitely true",
//         value: 100
//       },
//       {
//         answerText: "Mostly true",
//         value: 75
//       },
//       {
//         answerText: "Don't know",
//         value: 50
//       },
//       {
//         answerText: "Mostly false",
//         value: 25
//       },
//       {
//         answerText: "Definitely false",
//         value: 0
//       }
//     ]
//   }
// ];

// export const questionHeaders = [
//   <span>
//     The following items are about activities you might do during a typical day.
//     Does <b>your health now limit you</b> in these activities? If so, how much?
//   </span>,
//   <span>
//     During the <b>past 4 weeks</b>, have you had any of the following problems
//     with your work or other regular daily activities
//     <b>as a result of your physical health?</b>
//   </span>,
//   <span>
//     During the <b>past 4 weeks</b>, have you had any of the following problems
//     with your work or other regular daily activities{" "}
//     <b>as a result of any emotional problems</b> (such as feeling depressed or
//     anxious)?
//   </span>,
//   <span>
//     These questions are about how you feel and how things have been with you{" "}
//     <b>during the past 4 weeks.</b> For each question, please give the one
//     answer that comes closest to the way you have been feeling.
//     <br />
//     How much of the time during the <b>past 4 weeks...</b>
//   </span>,
//   <span>
//     How TRUE or FALSE is <b>each</b> of the following statements for you.
//   </span>
// ];

// export const rand36QuestionsBaseViet = [
//   {
//     questionText: <span>Nói chung, bạn sẽ nói sức khỏe của bạn là:</span>,
//     answers: [
//       {
//         answerText: "Tuyệt vời",
//         value: 100
//       },
//       {
//         answerText: "Rất tốt",
//         value: 75
//       },
//       {
//         answerText: "Tốt",
//         value: 50
//       },
//       {
//         answerText: "Hơi",
//         value: 25
//       },
//       {
//         answerText: "kém",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>So với một năm trước</b>, bạn đánh giá sức khỏe của bạn nói chung{" "}
//         <b>bây giờ</b>như thế nào?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Bây giờ tốt hơn nhiều so với một năm trước",
//         value: 100
//       },
//       {
//         answerText: "Bây giờ tốt hơn năm trước một chút",
//         value: 75
//       },
//       {
//         answerText: "Bây giờ vẫn giống như một năm trước",
//         value: 50
//       },
//       {
//         answerText: "Bây giờ có phần nào tệ hơn một năm trước",
//         value: 25
//       },
//       {
//         answerText: "Bây giờ tệ hơn nhiều so với một năm trước",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>Các hoạt động mạnh mẽ</b>, như chạy, nâng vật nặng, tham gia các môn
//         thể thao vất vả
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có, hạn chế rất nhiều",
//         value: 0
//       },

//       {
//         answerText: "Có, hạn chế một chút",
//         value: 50
//       },
//       {
//         answerText: "Không, không hề hạn chế chút nào",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>Các hoạt động vừa phải</b>, chẳng hạn như di chuyển một cái bàn, đẩy
//         một máy hút bụi, chơi bowling hoặc chơi đánh gôn
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có, hạn chế rất nhiều",
//         value: 0
//       },

//       {
//         answerText: "Có, hạn chế một chút",
//         value: 50
//       },
//       {
//         answerText: "Không, không hề hạn chế chút nào",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Nâng hoặc mang vật tư hoặc hàng tạp hóa</span>,
//     answers: [
//       {
//         answerText: "Có, hạn chế rất nhiều",
//         value: 0
//       },

//       {
//         answerText: "Có, hạn chế một chút",
//         value: 50
//       },
//       {
//         answerText: "Không, không hề hạn chế chút nào",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Leo lên <b>nhiều</b> tầng cầu thang
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có, hạn chế rất nhiều",
//         value: 0
//       },

//       {
//         answerText: "Có, hạn chế một chút",
//         value: 50
//       },
//       {
//         answerText: "Không, không hề hạn chế chút nào",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Leo lên <b>một</b> tầng cầu thang
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có, hạn chế rất nhiều",
//         value: 0
//       },

//       {
//         answerText: "Có, hạn chế một chút",
//         value: 50
//       },
//       {
//         answerText: "Không, không hề hạn chế chút nào",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Vặn mình, quỳ hoặc cúi xuống</span>,
//     answers: [
//       {
//         answerText: "Có, hạn chế rất nhiều",
//         value: 0
//       },

//       {
//         answerText: "Có, hạn chế một chút",
//         value: 50
//       },
//       {
//         answerText: "Không, không hề hạn chế chút nào",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Đi bộ <b>hơn một dặm</b> (khoảng 2km trở lên)
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có, hạn chế rất nhiều",
//         value: 0
//       },

//       {
//         answerText: "Có, hạn chế một chút",
//         value: 50
//       },
//       {
//         answerText: "Không, không hề hạn chế chút nào",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Đi bộ <b>vài khu nhà</b>
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có, hạn chế rất nhiều",
//         value: 0
//       },

//       {
//         answerText: "Có, hạn chế một chút",
//         value: 50
//       },
//       {
//         answerText: "Không, không hề hạn chế chút nào",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Đi bộ <b>một khu nhà</b>
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có, hạn chế rất nhiều",
//         value: 0
//       },

//       {
//         answerText: "Có, hạn chế một chút",
//         value: 50
//       },
//       {
//         answerText: "Không, không hề hạn chế chút nào",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Tự mình <b>tắm</b> hoặc tự mình mặc quần áo
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có, hạn chế rất nhiều",
//         value: 0
//       },

//       {
//         answerText: "Có, hạn chế một chút",
//         value: 50
//       },
//       {
//         answerText: "Không, không hề hạn chế chút nào",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Giảm <b>lượng thời gian</b> mà bạn dành cho công việc hoặc các hoạt động
//         khác
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có",
//         value: 0
//       },

//       {
//         answerText: "Không",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>Accomplished less</b> so với điều bạn muốn
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có",
//         value: 0
//       },

//       {
//         answerText: "Không",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Bị hạn chế trong các <b>thể loại</b> công việc hoặc các hoạt động khác
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có",
//         value: 0
//       },

//       {
//         answerText: "Không",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Gặp <b>khó khăn</b> khi thực hiện công việc thường xuyên hoặc các hoạt
//         động khác (ví dụ: phải nỗ lực thêm)
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có",
//         value: 0
//       },

//       {
//         answerText: "Không",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Giảm <b>lượng thời gian</b> mà bạn dành cho công việc hoặc các hoạt động
//         khác
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có",
//         value: 0
//       },

//       {
//         answerText: "Không",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         <b>Đạt được ít hơn</b> so với điều bạn muốn
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có",
//         value: 0
//       },

//       {
//         answerText: "Không",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Không làm công việc hoặc các hoạt động khác <b>cẩn thận</b> như thông
//         thường
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Có",
//         value: 0
//       },

//       {
//         answerText: "Không",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Trong <b>4 tuần </b> qua, sức khỏe thể chất hoặc các vấn đề tình cảm nào
//         của bạn đã can thiệp vào các hoạt động xã hội bình thường của bạn với
//         gia đình, bạn bè, hàng xóm hoặc các nhóm ở mức độ nào?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Không hề có",
//         value: 100
//       },
//       {
//         answerText: "Có một chút",
//         value: 75
//       },
//       {
//         answerText: "Vừa phải",
//         value: 50
//       },
//       {
//         answerText: "Khá nhiều",
//         value: 25
//       },
//       {
//         answerText: "Rất nhiều",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Bạn đã chịu bao nhiêu đau đớn <b>về cơ thể</b> trong <b>4 tuần</b>qua?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Không hề có",
//         value: 100
//       },
//       {
//         answerText: "Rất nhẹ",
//         value: 80
//       },
//       {
//         answerText: "Nhẹ",
//         value: 60
//       },
//       {
//         answerText: "Vừa phải",
//         value: 40
//       },
//       {
//         answerText: "Nặng",
//         value: 20
//       },
//       {
//         answerText: "Rất nghiêm trọng",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Trong <b>4 tuần</b>, qua, cơn đau đã ảnh hưởng thế nào đến công việc
//         bình thường của bạn (bao gồm cả việc làm và công việc quanh nhà)?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Không hề có",
//         value: 100
//       },
//       {
//         answerText: "Có một chút ",
//         value: 75
//       },
//       {
//         answerText: "Vừa phải",
//         value: 50
//       },
//       {
//         answerText: "Khá nhiều",
//         value: 25
//       },
//       {
//         answerText: "Rất nhiều",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: <span>Bạn có cảm thấy tràn đầy khí lực</span>,
//     answers: [
//       {
//         answerText: "Toàn bộ thời gian",
//         value: 100
//       },
//       {
//         answerText: "Hầu hết thời gian",
//         value: 80
//       },
//       {
//         answerText: "Một chút thời gian",
//         value: 60
//       },
//       {
//         answerText: "Thỉnh thoảng",
//         value: 40
//       },
//       {
//         answerText: "Họa hoằn",
//         value: 20
//       },
//       {
//         answerText: "Không hề",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: <span>Bạn có từng là một người rất lo lắng không?</span>,
//     answers: [
//       {
//         answerText: "Toàn bộ thời gian",
//         value: 0
//       },
//       {
//         answerText: "Hầu hết thời gian",
//         value: 20
//       },
//       {
//         answerText: "Một chút thời gian",
//         value: 40
//       },
//       {
//         answerText: "Thỉnh thoảng",
//         value: 60
//       },
//       {
//         answerText: "Họa hoằn",
//         value: 80
//       },
//       {
//         answerText: "Không hề",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Có khi nào bạn từng cảm thấy rất buồn chán mà không có gì có thể làm bạn
//         vui lên?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Toàn bộ thời gian",
//         value: 0
//       },
//       {
//         answerText: "Hầu hết thời gian",
//         value: 20
//       },
//       {
//         answerText: "Một chút thời gian",
//         value: 40
//       },
//       {
//         answerText: "Thỉnh thoảng",
//         value: 60
//       },
//       {
//         answerText: "Họa hoằn",
//         value: 80
//       },
//       {
//         answerText: "Không hề",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Bạn đã cảm thấy bình tĩnh và yên bình?</span>,
//     answers: [
//       {
//         answerText: "Toàn bộ thời gian",
//         value: 100
//       },
//       {
//         answerText: "Hầu hết thời gian",
//         value: 80
//       },
//       {
//         answerText: "Một chút thời gian",
//         value: 60
//       },
//       {
//         answerText: "Thỉnh thoảng",
//         value: 40
//       },
//       {
//         answerText: "Họa hoằn",
//         value: 20
//       },
//       {
//         answerText: "Không hề",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: <span>Bạn có nhiều năng lượng không?</span>,
//     answers: [
//       {
//         answerText: "Toàn bộ thời gian",
//         value: 100
//       },
//       {
//         answerText: "Hầu hết thời gian",
//         value: 80
//       },
//       {
//         answerText: "Một chút thời gian",
//         value: 60
//       },
//       {
//         answerText: "Thỉnh thoảng",
//         value: 40
//       },
//       {
//         answerText: "Họa hoằn",
//         value: 20
//       },
//       {
//         answerText: "Không hề",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>Có bao giờ bạn cảm thấy chán nản và thất vọng không?</span>
//     ),
//     answers: [
//       {
//         answerText: "Toàn bộ thời gian",
//         value: 0
//       },
//       {
//         answerText: "Hầu hết thời gian",
//         value: 20
//       },
//       {
//         answerText: "Một chút thời gian",
//         value: 40
//       },
//       {
//         answerText: "Thỉnh thoảng",
//         value: 60
//       },
//       {
//         answerText: "Họa hoằn",
//         value: 80
//       },
//       {
//         answerText: "Không hề",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Bạn có cảm thấy mệt mỏi rã rời không?</span>,
//     answers: [
//       {
//         answerText: "Toàn bộ thời gian",
//         value: 0
//       },
//       {
//         answerText: "Hầu hết thời gian",
//         value: 20
//       },
//       {
//         answerText: "Một chút thời gian",
//         value: 40
//       },
//       {
//         answerText: "Thỉnh thoảng",
//         value: 60
//       },
//       {
//         answerText: "Họa hoằn",
//         value: 80
//       },
//       {
//         answerText: "Không hề",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Bạn đã từng bao giờ là một người hạnh phúc chưa?</span>,
//     answers: [
//       {
//         answerText: "Toàn bộ thời gian",
//         value: 100
//       },
//       {
//         answerText: "Hầu hết thời gian",
//         value: 80
//       },
//       {
//         answerText: "Một chút thời gian",
//         value: 60
//       },
//       {
//         answerText: "Thỉnh thoảng",
//         value: 40
//       },
//       {
//         answerText: "Họa hoằn",
//         value: 20
//       },
//       {
//         answerText: "Không hề",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: <span>Bạn có cảm thấy mệt mỏi không?</span>,
//     answers: [
//       {
//         answerText: "Toàn bộ thời gian",
//         value: 0
//       },
//       {
//         answerText: "Hầu hết thời gian",
//         value: 20
//       },
//       {
//         answerText: "Một chút thời gian",
//         value: 40
//       },
//       {
//         answerText: "Thỉnh thoảng",
//         value: 60
//       },
//       {
//         answerText: "Họa hoằn",
//         value: 80
//       },
//       {
//         answerText: "Không hề",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>
//         Trong <b>4 tuần qua</b>, các vấn đề về sức khỏe thể chất hoặc các vấn đề
//         về cảm xúc của bạn đã can thiệp vào các hoạt động xã hội của bạn (như
//         thăm bạn bè, người thân, v.v.) bao nhiêu thời gian?
//       </span>
//     ),
//     answers: [
//       {
//         answerText: "Toàn bộ thời gian",
//         value: 0
//       },
//       {
//         answerText: "Hầu hết thời gian",
//         value: 25
//       },
//       {
//         answerText: "Some of the time",
//         value: 50
//       },
//       {
//         answerText: "Họa hoằn",
//         value: 75
//       },
//       {
//         answerText: "Không hề",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Tôi dường như hơi dễ bị bệnh</span>,
//     answers: [
//       {
//         answerText: "Rất đúng",
//         value: 0
//       },
//       {
//         answerText: "Gần đúng",
//         value: 25
//       },
//       {
//         answerText: "Không biết",
//         value: 50
//       },
//       {
//         answerText: "Gần như sai",
//         value: 75
//       },
//       {
//         answerText: "Hoàn toàn sai",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Tôi khỏe mạnh như bất kỳ ai tôi biết</span>,
//     answers: [
//       {
//         answerText: "Rất đúng",
//         value: 100
//       },
//       {
//         answerText: "Gần đúng",
//         value: 75
//       },
//       {
//         answerText: "Không biết",
//         value: 50
//       },
//       {
//         answerText: "Gần như sai",
//         value: 25
//       },
//       {
//         answerText: "Hoàn toàn sai",
//         value: 0
//       }
//     ]
//   },
//   {
//     questionText: (
//       <span>Tôi hy vọng sức khỏe của tôi sẽ trở nên tồi tệ hơn</span>
//     ),
//     answers: [
//       {
//         answerText: "Rất đúng",
//         value: 0
//       },
//       {
//         answerText: "Gần đúng",
//         value: 25
//       },
//       {
//         answerText: "Không biết",
//         value: 50
//       },
//       {
//         answerText: "Gần như sai",
//         value: 75
//       },
//       {
//         answerText: "Hoàn toàn sai",
//         value: 100
//       }
//     ]
//   },
//   {
//     questionText: <span>Sức khỏe của tôi rất tuyệt vời</span>,
//     answers: [
//       {
//         answerText: "Rất đúng",
//         value: 100
//       },
//       {
//         answerText: "Gần đúng",
//         value: 75
//       },
//       {
//         answerText: "Không biết",
//         value: 50
//       },
//       {
//         answerText: "Gần như sai",
//         value: 25
//       },
//       {
//         answerText: "Hoàn toàn sai",
//         value: 0
//       }
//     ]
//   }
// ];

// export const questionHeadersViet = [
//   <span>
//     Các mục sau đây là về các hoạt động mà bạn có thể làm trong một ngày thông
//     thường. <b>Sức khỏe của bạn bây giờ có hạn chế bạn </b>trong các hoạt động
//     này không? Nếu có, thì bao nhiêu?
//   </span>,
//   <span>
//     Trong <b>4 tuần</b>qua, bạn có gặp phải bất kỳ vấn đề nào sau đây với công
//     việc của bạn hoặc các hoạt động thường xuyên khác hàng ngày
//     <b>do sức khỏe thể chất của bạn không?</b>
//   </span>,
//   <span>
//     Trong <b>4 tuần</b>, qua, bạn có gặp phải bất kỳ vấn đề nào sau đây với công
//     việc của bạn hoặc các hoạt động thường xuyên khác hàng ngày
//     <b>do sức khỏe thể chất của bạn không?</b>?
//   </span>,
//   <span>
//     Những câu hỏi này là về việc bạn cảm thấy như thế nào và mọi thứ đã ở bên
//     bạn <b>trong 4 tuần vừa qua</b> như thế nào. Đối với mỗi câu hỏi, xin vui
//     lòng đưa ra một câu trả lời gần nhất với cách mà bạn đã cảm nhận được.
//     <br />
//     Bao nhiêu thời gian trong <b>4 tuần vừa qua ...</b>
//   </span>,
//   <span>
//     ĐÚNG hoặc SAI như thế nào là <b>mỗi</b> câu sau đây giành cho bạn?
//   </span>
// ];
