import React, { useState, useEffect } from "react";
import { RandChart } from "../rand36/index.tsx";
import axios from "axios";
import LoadingMsg from "../LoadingMsg";
import { useSelector } from "react-redux";

import { fetchUsers } from "../../api/index.ts";
import { aggregateSurveys } from "../../utils/index.ts";

// Styles components
import Form from "react-bootstrap/Form";

const GraphBrowser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [users, setUsers] = useState({});
  const [selectedUsers, setSelectedUsers] = useState({});

  const store = useSelector((store) => store);

  const loadUsersFromDB = async () => {
    setIsLoading(true);
    const centerId = store.auth.user.id;
    const results = await fetchUsers(centerId);
    let users = {};
    results.forEach(
      (user) => user.surveyCount > 0 && (users[user.id] = user.pid)
    );
    setUsers(users);
    setSelectedUsers(users);
    await fetchSurveys(store.auth.user.id);
    setIsLoading(false);
  };

  const selectAllUsers = () => {
    if (Object.entries(selectedUsers).length === Object.entries(users).length) {
      setSelectedUsers({});
      setSelectedSurveys([]);
    } else {
      const aggregatedSurveys = aggregateSurveys(surveys);
      setSelectedUsers(users);
      setSelectedSurveys(aggregatedSurveys);
    }
  };

  const fetchSurveys = async (id) => {
    setIsLoading(true);

    const query = `/api/v1/surveys?center=${id}`;
    const res = await axios.get(query);
    const surveys = res.data.data.map((survey) => ({
      answers: survey.answers,
      isVisible: true,
      surveyNo: survey.surveyNo,
      user: survey.user,
    }));

    setSurveys(surveys);
    const aggregatedSurveys = aggregateSurveys(surveys);
    setSelectedSurveys(aggregatedSurveys);
    setIsLoading(false);
  };

  const toggleSelectedPID = (selectedEntry) => {
    const newUsers = { ...selectedUsers };
    if (newUsers[selectedEntry[0]]) delete newUsers[selectedEntry[0]];
    else newUsers[selectedEntry[0]] = selectedEntry[1];
    setSelectedUsers(newUsers);
    updateSelectedSurveys(newUsers);
  };

  const updateSelectedSurveys = (users) => {
    let newSurveys = surveys.filter((survey) => {
      return users[survey.user] && survey;
    });
    newSurveys = aggregateSurveys(newSurveys);
    setSelectedSurveys(newSurveys);
  };

  useEffect(() => {
    loadUsersFromDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingMsg />
      ) : (
        <>
          {Object.entries(users).length > 0 && (
            <>
              <p>Available PIDS:</p>

              <Form.Check
                checked={
                  Object.entries(selectedUsers).length ===
                  Object.entries(users).length
                }
                onClick={selectAllUsers}
                label="Select all users"
              />

              {Object.entries(users).map((entry) => (
                <span onClick={() => toggleSelectedPID(entry)}>
                  <Form.Check
                    key={entry[0]}
                    checked={selectedUsers[entry[0]] === entry[1]}
                    type="checkbox"
                    label={entry[1]}
                    inline
                  />
                </span>
              ))}
              <RandChart
                title={`Surveys for ${store.auth.user.name}`}
                answers={selectedSurveys}
              />
            </>
          )}
          {!Object.entries(users).length && (
            <>
              <h1>No graphs.</h1>
              <p>
                There are no surveys submitted yet to pull graph data from.
                Check the PID Browser to create new PIDs and issue surveys to
                participants.
              </p>
            </>
          )}
        </>
      )}
    </>
  );
};

export default GraphBrowser;
