import React from "react";

type Props = {
  options: { value: number; label: string }[];
  currentSelection: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue: string;
  style?: {};
};

export const Select = ({
  options,
  currentSelection,
  handleChange,
  defaultValue,
  ...otherProps
}: Props) => {
  return (
    <select
      value={currentSelection}
      onChange={handleChange}
      style={{ margin: "1rem auto" }}
      {...otherProps}
    >
      {defaultValue ? (
        <option value="-" defaultChecked>
          {defaultValue}
        </option>
      ) : (
        <option value="-" defaultChecked>
          -
        </option>
      )}
      {options.map((option, key) => (
        <option key={key} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
