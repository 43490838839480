import React, { useState } from "react";
import { useForm } from "../hooks/useForm";
import axios from "axios";
import { Message } from "./layout/Message";
import { Link } from "react-router-dom";

// Style components
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const ResetPassword = ({ props }) => {
  const [values, setValues] = useForm({ password: "", password2: "" });
  const [message, setMessage] = useState("");
  const [updatedPwd, setUpdatedPwd] = useState(false);
  const [loading, setLoading] = useState(false);

  const isLongPwd = () => values.password.length >= 6;
  const PwdsMatch = () => values.password === values.password2;

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!isLongPwd()) setMessage("Password too short");
    else if (!PwdsMatch()) setMessage("passwords do not match");
    else await changePwdInDB();
    setLoading(false);
  };

  const changePwdInDB = async () => {
    try {
      const str = `/api/v1/adminAuth/resetpassword/${props.match.params.id}`;
      const body = JSON.stringify({ password: values.password });
      const config = { headers: { "Content-Type": "application/json" } };
      const res = await axios.put(str, body, config);
      if (!res.data.success) throw new Error("Server error occurred");
      setMessage("Password updated!");
      setUpdatedPwd(true);
    } catch (err) {
      return setMessage(
        "Token expired. Please submit new password reset request.",
        "danger"
      );
    }
  };

  return (
    <>
      {message && <Message msg={message} setMsg={setMessage} />}
      {!updatedPwd ? (
        <Form className="px-3 py-4">
          <Form.Group>
            <Form.Label>New password</Form.Label>
            <Form.Control
              value={values.password}
              onChange={setValues}
              name="password"
              type="password"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm password</Form.Label>
            <Form.Control
              value={values.password2}
              onChange={setValues}
              name="password2"
              type="password"
            />
          </Form.Group>
          <Button
            variant="success"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Update password</span>
            )}
          </Button>
        </Form>
      ) : (
        <Link
          to="/"
          style={{ display: "block", margin: "2rem auto", fontSize: "1.2rem" }}
        >
          Return to login
        </Link>
      )}
    </>
  );
};

export default ResetPassword;
