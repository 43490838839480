import React from "react";
import { Link } from "react-router-dom";
import { Container, Nav, Row } from "react-bootstrap";
import CenterAvatar from "../layout/CenterAvatar";

export default () => {
  const links = [
    {
      body: "Dashboard",
      url: "/manager"
    },
    {
      body: "Instructions",
      url: "/manager/guide"
    },
    {
      body: "PID Browser",
      url: "/manager/pid-browser"
    },
    {
      body: "Graph Browser",
      url: "/manager/graph-browser"
    },
    {
      body: "Account Settings",
      url: "/manager/settings"
    }
  ];
  return (
    <Container className="mx-auto">
      <Row className="mx-auto">
        <CenterAvatar />
        <Nav
          variant="pills"
          defaultActiveKey="/manager"
          className="flex-column mx-auto"
        >
          <h2 className="text-center">Manager Dashboard</h2>
          {links.map(link => (
            <Nav.Item key={link.url}>
              <Nav.Link
                as={Link}
                href={link.url}
                to={link.url}
                className="text-center"
              >
                {link.body}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Row>
    </Container>
  );
};
