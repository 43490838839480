import React from "react";
import PIDRow from "./PIDRow";

import { User } from "./PIDBrowserWrapper";

// Style components
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import { fetchUsers } from "../../api";

interface Props {
  users: Array<User>;
  fetchUsersFunc: () => void;
}

export default ({ users, fetchUsersFunc }: Props) => {
  return (
    <Col className="mx-auto my-2">
      <Table striped bordered>
        <thead>
          <tr>
            <th>PID</th>
            <th>Next Survey</th>
            <th>Survey Link</th>
            {/* <th>Consents</th> */}
            <th>Delete PID</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user: User, i) => {
            return (
              <PIDRow user={user} key={i} fetchUsersFunc={fetchUsersFunc} />
            );
          })}
        </tbody>
      </Table>
    </Col>
  );
};
