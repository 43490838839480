import React from "react";
import { directorDashboardLinks } from "../../../data/directorDashboardLinks";
import { Link } from "react-router-dom";
import { Container, Card, Row } from "react-bootstrap";
import { permissions } from "../../../data/types/permissions";
import { dashboardCard } from "../../../data/managerDashboardLinks";

type Props = {
  permissions: permissions;
};

export const DirectorDashboard: React.FC<Props> = ({ permissions }) => {
  const arr: dashboardCard[] =
    permissions === "director" ? directorDashboardLinks : [];
  return (
    <Container>
      <Row>
        {arr.map((link) => {
          return (
            <Card
              className="mt-5 mx-3"
              style={{ width: "25rem" }}
              key={link.url}
            >
              <Card.Header as="h2">{link.header}</Card.Header>
              <Card.Body>
                <Card.Text>{link.body}</Card.Text>
                <Card.Link as={Link} href={link.url} to={link.url}>
                  {link.urlText}
                </Card.Link>
              </Card.Body>
            </Card>
          );
        })}
      </Row>
    </Container>
  );
};
