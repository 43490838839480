import React, { Component } from "react";
import Container from "react-bootstrap/Container";

import styled from "styled-components";

const Styles = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  bottom: 0;
`;

export class Footer extends Component {
  render() {
    return (
      <Styles>
        <Container className="text-center mt-5">
          Copyright {"\u00A9"} {new Date().getFullYear()} Foundation for
          Advancements in Science and Education (FASE)
        </Container>
      </Styles>
    );
  }
}

export default Footer;
