import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../hooks/useForm";
import Select from "../../components/admin/Select";
import { login } from "../../actions/auth";
import axios from "axios";

// Style components
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";

export default () => {
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingCenters, setFetchingCenters] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState();

  const [values, handleChange] = useForm({ password: "" });

  const store = useSelector((store) => store);
  const dispatch = useDispatch();

  const fetchCenters = async () => {
    const query = "/api/v1/centers/?select=name";
    setFetchingCenters(true);
    const res = await axios.get(query);
    const centers = res.data.data;
    setCenters(centers);
    setFetchingCenters(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    await dispatch(login(selectedCenter, values.password));
    setLoading(false);
  };

  useEffect(() => {
    fetchCenters();
  }, []);

  if (store.auth.isAuthenticated && store.auth.user) {
    const userRole = store.auth.user.role;
    if (userRole === "admin") return <Redirect to="/admin" />;
    if (userRole === "manager") return <Redirect to="/es/manager" />;
    return <Redirect to="/user" />;
  } else
    return (
      <Card className="mx-auto mt-5 w-100">
        <Card.Header as="h1">Administrador de inicio de sesión</Card.Header>
        <Card.Body>
          <Form className="px-3 py-4">
            <Select
              arr={centers}
              selectedItem={selectedCenter}
              handleChange={(e) => setSelectedCenter(e.target.value)}
              defaultMsg="Seleccionar centror"
              defaultLabel="Tu centror"
              isFetching={fetchingCenters}
              defaultLoadingMsg="Centros de carga ...."
            />
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Introducir la contraseñad"
                value={values.password}
                name="password"
                onChange={handleChange}
              />
            </Form.Group>
            <Button
              variant="success"
              type="submit"
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                onSubmit([values.centerName, values.password]);
              }}
            >
              <span>Iniciar sesión</span>
            </Button>{" "}
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Form>
        </Card.Body>
      </Card>
    );
};
