import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import GraphBrowser from "../../components/admin/GraphBrowser";
// import { RegisterAdmin } from "../../components/register-director/index";
import RegisterManager from "../../components/admin/RegisterManager";
import { DirectorLogin } from "../../pages/director/login";

import { directorLinks, Quickbar } from "../../components/quickbar";

// styles components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// pages
import settings from "../../pages/director/settings.component";
import instructions from "../../pages/admin/instructions.component";

// types
import { authObj } from "../../data/types/";
import { DirectorDashboard } from "../../pages/director/dashboard";
import { CenterCreator } from "../../pages/director/center-creator";

interface Props {
  auth: authObj;
}

const DirectorRoutes: React.FC<Props> = ({ auth }) => {
  const isLoggedIn = !!auth.user;
  const isDirector = isLoggedIn ? auth.user.role === "director" : false;

  if (!isDirector && !auth.loading) {
    return <DirectorLogin />;
  } else if (isDirector) {
    const permissions = auth.user.role;
    return (
      <Router>
        <Container className="mt-5">
          <Row>
            <Col xs lg="3">
              <Route
                path="/director"
                render={(_) => (
                  <Quickbar role={permissions} links={directorLinks} />
                )}
              />
            </Col>
            <Col lg="8">
              <Switch>
                <Route
                  path="/director"
                  render={(_) => (
                    <DirectorDashboard permissions={permissions} />
                  )}
                  exact
                />
                <Route
                  path="/director/graph-browser"
                  component={GraphBrowser}
                />
                <Route
                  path="/director/register-manager"
                  component={RegisterManager}
                />
                <Route
                  path="/director/register-center"
                  render={(_) => <CenterCreator />}
                />
                {/* <Route
                  path="/director/register-director"
                  component={RegisterAdmin}
                /> */}
                <Route path="/director/settings" component={settings} />
                <Route path="/admin/guide" component={instructions} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </Router>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export const DirectorRouter = connect(mapStateToProps, {})(DirectorRoutes);
