import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

// styles
import Table from "react-bootstrap/Table";

interface centerObj {}

interface surveyObj {
  surveyNo: number;
  center: string;
}

interface Props {
  centers: object;
  surveys: surveyObj[];
  maxSurveyNo: number;
}

const times = (x: number) => (f: () => any) => {
  if (x > 0) {
    f();
    times(x - 1)(f);
  }
};

const arrToNum = (n: number) => {
  const arr = [];
  for (let i = 0; i < n; i++) arr.push(i + 1);
  return arr;
};

export const CentersReportTable = ({
  centers,
  surveys,
  maxSurveyNo,
}: Props) => {
  console.log("SURVEYS ARRAY: ", surveys);
  maxSurveyNo = maxSurveyNo > 4 ? maxSurveyNo : 4;
  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>Center Name</th>
          {arrToNum(maxSurveyNo).map((n) => (
            <th>{n}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.entries(centers).map(([centerId, centerName]) => (
          <tr>
            <td>{centerName}</td>
            {arrToNum(maxSurveyNo).map((currentNum) => {
              let count = 0;
              surveys.forEach((survey) => {
                if (
                  survey.surveyNo === currentNum &&
                  survey.center === centerId
                )
                  count += 1;
              });
              return <td>{count}</td>;
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
