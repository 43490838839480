import React, { useState, useContext } from "react";
import { useForm } from "../../hooks/useForm";
import axios from "axios";
import { AlertContext } from "../../context/AlertContext";

// Styles
import { Card, Form, Button } from "react-bootstrap";

export default () => {
  const [values, handleChange] = useForm({
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { addMsg } = useContext(AlertContext);

  const [passwordLoading, setPasswordLoading] = useState(false);
  // const user = useSelector((state) => state.auth.user);

  // Email validation function
  // const isValidEmail = (email) => {
  //   const re = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  //   return re.test(email.toLowerCase());
  // };

  const updatePassword = async () => {
    setPasswordLoading(true);
    const arePasswordsValidated = validatePasswords();
    if (!arePasswordsValidated.success) {
      addMsg(arePasswordsValidated.msg);
    } else {
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      const res = await axios.put(
        "/api/v1/adminAuth/updatepassword",
        body,
        config
      );
      console.log(res);
      if (res.data.success) addMsg("Password updated successfully!");
      else addMsg(res.data.error);
    }
    setPasswordLoading(false);
  };

  const validatePasswords = () => {
    if (
      !values.currentPassword ||
      !values.newPassword ||
      !values.confirmPassword
    ) {
      return { success: false, msg: "Please fill out all password fields" };
    }
    if (values.newPassword !== values.confirmPassword) {
      return {
        success: false,
        msg: "New password does not match confirmation password",
      };
    } else {
      return { success: true };
    }
  };

  return (
    <Card header="Change Password" isLoading={passwordLoading} className="p-3">
      <Card.Title>Reset Password</Card.Title>
      <Form>
        <Form.Group controlId="formBasicPassword">
          <Form.Control
            placeholder="Current password"
            className="mb-3"
            value={values.currentPassword}
            name="currentPassword"
            type="password"
            onChange={handleChange}
          />
          <Form.Control
            placeholder="New password"
            className="mb-3"
            value={values.newPassword}
            name="newPassword"
            type="password"
            onChange={handleChange}
          />
          <Form.Control
            placeholder="Confirm new password"
            className="mb-3"
            value={values.confirmPassword}
            name="confirmPassword"
            type="password"
            onChange={handleChange}
          />
        </Form.Group>
        <Button onClick={updatePassword} className="button__flat">
          Update Password
        </Button>
      </Form>
    </Card>
  );
};
