import React from "react";

import Button from "react-bootstrap/Button";

type Props = {
  answerText: string;
  value: number;
  onChange: (value: number) => void;
  selected: boolean;
};

export const Answer = ({ answerText, value, onChange, selected }: Props) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onChange(value);
  };

  return (
    <span>
      <Button
        block
        onClick={handleClick}
        disabled={selected}
        className="my-3"
        variant={selected ? "outline-primary" : "primary"}
      >
        <span>{answerText}</span>
      </Button>
    </span>
  );
};

// REQUIRES
// obj.answerText (string)
// obj.vaLue (integer)
// obj.onChange (function)
// obj.selected (boolean)

export default Answer;
