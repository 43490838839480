import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

type Props = {
  show: boolean;
  header: string;
  setShow: (b: boolean) => void;
  onHide?: () => void;
  lang?: string;
  rAlign?: boolean;
  JSXComponent: React.ComponentType;
};

const WomModal = ({
  show,
  header,
  setShow,
  JSXComponent,
  onHide,
  rAlign,
  lang,
}: Props) => {
  const handleClose = () => {
    onHide && onHide();
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} align={rAlign ? "right" : "left"}>
      <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <JSXComponent />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose()}>
          {lang === "es" ? "Cerrar" : lang === "tw" ? "關閉" : "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WomModal;
