import { dashboardCard } from "./managerDashboardLinks";

const adminDashboardLinks: dashboardCard[] = [
  {
    header: "Register Director",
    body: "Register Directors to create new centers under them",
    url: "/admin/register-director",
    urlText: "Register Director",
  },
  {
    header: "Graph Browser",
    body: "Browse graphs from centers around the world",
    url: "/admin/graph-browser",
    urlText: "Graph Browser",
  },
  {
    header: "Account Settings",
    body: "Reset password for your account",
    url: "/admin/settings",
    urlText: "Account settings",
  },
];

export default adminDashboardLinks;
