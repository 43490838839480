import React from "react";

import { User } from "./PIDBrowserWrapper";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

interface Props {
  user: User;
}

const DeleteUserModal = ({ user }: Props) => {
  return (
    <div>
      <div>
        <h3>Manage Consent Permissions</h3>
        <h5>PID: {user.pid}</h5>
        <p>
          Manually change consent permissions for past surveys for this
          participant. Only use this if the participant has asked to have their
          previous survey authorizations retroactively changed.
        </p>
        <InputGroup className="mb-3">
          <Form.Check type="checkbox" /> Survey #1
        </InputGroup>
        <InputGroup className="mb-3">
          <Form.Check type="checkbox" /> Survey #2
        </InputGroup>
        <InputGroup className="mb-3">
          <Form.Check type="checkbox" /> Survey #3
        </InputGroup>
        <InputGroup className="mb-3">
          <Form.Check type="checkbox" /> Survey #4
        </InputGroup>
        <Button variant="primary">SAVE</Button>
      </div>
    </div>
  );
};

export default DeleteUserModal;
