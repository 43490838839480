import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

// components
import { Alert } from "../../../components/alert";

// styles
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

// types
import { variant } from "../../../components/alert/Alert";
import { permissions } from "../../../data/types/permissions";

type consentRegionType = "general" | "eu";

export const CenterCreator: React.FC = () => {
  const directorId = useSelector((state: any) => state.auth.user.id);

  const role: permissions = "manager";
  const [name, setName] = useState("");
  const [consentRegion, setConsentRegion] = useState<consentRegionType>(
    "general"
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [file, setFile] = useState(null as any);
  const [message, setMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState("primary" as variant);

  const fileChange = (e: any) => {
    // const files: any = e.target.files[0];
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!name || !file || !password) {
      setMessage("You must fill out all fields");
      setMessageVariant("danger");
      return;
    }

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      setMessageVariant("danger");
      return;
    }

    const formData = new FormData();
    formData.append("upload", file);
    formData.append("name", name);
    formData.append("password", password);
    formData.append("role", role);
    formData.append("director", directorId);
    formData.append("consentRegion", consentRegion);

    try {
      const res = await axios.post("/api/v1/centers", formData);

      console.log(res);

      setMessage("Center created");
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.msg);
        setMessageVariant("danger");
      }
    }
  };

  return (
    <Container style={{ maxWidth: "50rem" }}>
      {!!message && (
        <Alert message={message} dismissible={true} variant={messageVariant} />
      )}
      <Form encType="multipart/form-data" onSubmit={onSubmit}>
        <h3>REGISTER CENTER</h3>
        <Form.Group>
          <Form.Label>Center Name</Form.Label>
          <FormControl
            type="text"
            placeholder="Enter center name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Passowrd</Form.Label>
          <FormControl
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm password</Form.Label>
          <FormControl
            type="password"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Consent Region</Form.Label>
          <FormControl
            as="select"
            defaultValue="general"
            value={consentRegion}
            onChange={(e) =>
              setConsentRegion(e.target.value as consentRegionType)
            }
          >
            <option>general</option>
            <option>eu</option>
          </FormControl>
        </Form.Group>
        <Form.Group>
          <Form.Label>Center Logo</Form.Label>
          <Form.File
            type="file"
            name="file"
            id="file"
            accept="image/*"
            onChange={fileChange}
          />
        </Form.Group>

        <Button type="submit">REGISTER</Button>
      </Form>
    </Container>
  );
};
