import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";

const RandChart = ({ title, answers }) => {
  const generateRandCatagories = (arr) => {
    const newArr = [
      Math.round(
        ((arr[2] || 0) +
          (arr[3] || 0) +
          (arr[4] || 0) +
          (arr[5] || 0) +
          (arr[6] || 0) +
          (arr[7] || 0) +
          (arr[8] || 0) +
          (arr[9] || 0) +
          (arr[10] || 0) +
          (arr[11] || 0)) /
          10
      ),
      Math.round(
        ((arr[12] || 0) + (arr[13] || 0) + (arr[14] || 0) + (arr[15] || 0)) / 4
      ),
      Math.round(((arr[16] || 0) + (arr[17] || 0) + (arr[18] || 0)) / 3 || 1),
      Math.round(
        ((arr[22] || 0) + (arr[26] || 0) + (arr[28] || 0) + (arr[30] || 0)) / 4
      ),
      Math.round(
        ((arr[23] || 0) +
          (arr[24] || 0) +
          (arr[25] || 0) +
          (arr[27] || 0) +
          (arr[29] || 0)) /
          5
      ),
      Math.round(((arr[19] || 0) + (arr[31] || 0)) / 2 || 1),
      Math.round(((arr[20] || 0) + (arr[21] || 0)) / 2 || 1),
      Math.round(
        ((arr[0] || 0) +
          (arr[32] || 0) +
          (arr[33] || 0) +
          (arr[34] || 0) +
          (arr[35] || 0)) /
          5
      ),
    ];
    const mappedArr = newArr.map((x) => {
      if (!x) return 1;
      else return x;
    });
    return mappedArr;
  };

  const options = {
    chart: {
      background: "#fcfdff",
      foreColor: "#333",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        autoSelected: "zoom",
      },
    },
    colors: ["#537287", "#008ffb", "#F0A202", "#D5CFE1", "#F05D5E"],
    xaxis: {
      categories: [
        "Funcionamiento físico",
        "Salud física",
        "Problemas emocionales",
        "Energía / fatiga",
        "El bienestar emocional",
        "Funcionamiento social",
        "Dolor",
        "Salud general",
      ],
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 100,
    },
    legend: {
      showForSingleSeries: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "75%",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        if (value > 4) return value;
        else return "";
      },
    },
    title: {
      text: title,
      align: "center",
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: "1.2rem",
      },
    },
  };

  let series = [
    {
      name: "Promedios del rand estadounidense",
      data: [71, 53, 66, 52, 70, 79, 71, 57],
    },
  ];

  answers.forEach((graphObj, key) => {
    if (graphObj.isVisible) {
      const randCatagories = generateRandCatagories(graphObj.answers);
      series.push({
        name: `Encuesta ${key + 1}`,
        data: [
          randCatagories[0],
          randCatagories[1],
          randCatagories[2],
          randCatagories[3],
          randCatagories[4],
          randCatagories[5],
          randCatagories[6],
          randCatagories[7],
        ],
      });
    }
  });

  return (
    <div className="chartContainer">
      <Chart
        options={options}
        series={series}
        type="bar"
        height="350"
        width="100%"
      />
    </div>
  );
};

RandChart.propTypes = {
  title: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(Number).isRequired, // length 36
};

export default RandChart;
