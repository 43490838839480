import React from "react";
import { PIDBrowserWrapper } from "../../../components/pid-browser/tw/index.ts";

export default () => {
  return (
    <div>
      <PIDBrowserWrapper />
    </div>
  );
};
