import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useForm } from "../../hooks/useForm";

export default ({ loading, onSubmit }) => {
  const [values, handleChange] = useForm({
    centerName: "",
    password: "",
  });

  return (
    <Form className="px-3 py-4">
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={values.centername}
          name="centerName"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Enter password"
          value={values.password}
          name="password"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Text>
          Forgot password? <a href="/forgot-password">Click here</a>
        </Form.Text>
      </Form.Group>
      <Button
        variant="success"
        type="submit"
        disabled={loading}
        onClick={(e) => {
          e.preventDefault();
          onSubmit([values.centerName, values.password]);
        }}
      >
        <span>Login</span>
      </Button>{" "}
      {loading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </Form>
  );
};
