import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import LoadingMsg from "../../LoadingMsg";
import { ParticipantsSurveysContext } from "../../../context/ParticipantsSurveysContext";

// components
import PIDBrowser from "./PIDBrowser";
import Pagination from "./Pagination";

// Styling
import { Button, Row } from "react-bootstrap";

export type User = {
  pid: number;
  surveyCount: number;
  isNew: boolean;
  id?: string;
  name?: number;
  surveyToken?: string;
  director: string;
};

export default () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;

  const centerId = useSelector((state: any) => state.auth.user.id);
  const director = useSelector((state: any) => state.auth.user.director);
  const { users, addUser, addUsers } = useContext(ParticipantsSurveysContext);

  const createNewPID = async () => {
    const config = { headers: { "Content-Type": "application/json" } };
    const body = JSON.stringify({
      role: "deidentified",
      center: centerId,
      director,
    });
    const res = await axios.post("/api/v1/auth/register", body, config);
    const newUser = res.data.data;
    newUser.isNew = true;
    addUser(newUser);
  };

  const fetchUsers = async () => {
    setLoading(true);
    const query = `/api/v1/centers/${centerId}/users`;
    const res = await axios.get(query);
    const users = res.data.data.filter((user: User) => user.pid);
    users.forEach((user: User) => (user.name = user.pid));
    addUsersToStore(users);
    setLoading(false);
  };

  const addUsersToStore = (users: Array<User>) => {
    addUsers(users);
  };

  useEffect(() => {
    if (users.length === 0) fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      {!loading ? (
        <>
          <Row className="m-3">
            <Button onClick={createNewPID}>Generar nuevo PID +</Button>
            <Button onClick={() => fetchUsers()} size="sm" className="ml-auto">
              Actualizar
            </Button>
          </Row>
          {currentUsers.length > 0 ? (
            <>
              <PIDBrowser users={currentUsers} fetchUsersFunc={fetchUsers} />
              <Pagination
                usersPerPage={usersPerPage}
                totalUsers={users.length}
                currentPage={currentPage}
                paginate={paginate}
              />
            </>
          ) : (
            <>
              <h1>Sin PIDs</h1>
              <p>
                ¡Genere un nuevo PID arriba para registrar a su primer
                participante!
              </p>
            </>
          )}
        </>
      ) : (
        <LoadingMsg lang="es" />
      )}
    </div>
  );
};
