import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { login } from "../../actions/adminAuth";
import LoginForm from "../../components/login/AdminLoginForm";

export default () => {
  const store = useSelector(store => store);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const onSubmit = async ([email, password]) => {
    setLoading(true);
    await dispatch(login(email, password));
    setLoading(false);
  };

  if (store.auth.isAuthenticated && store.auth.user) {
    const userRole = store.auth.user.role;
    console.log(store.auth)
    if (userRole === "admin") return <Redirect to="/admin" />;
    if (userRole === "director") return <Redirect to="/admin" />;
    if (userRole === "manager") return <Redirect to="/manager" />;
    return <Redirect to="/user" />;
  } else
    return (
      <>
        <Card className="mx-auto mt-5 w-100">
          <Card.Header as="h1">ADMIN LOGIN</Card.Header>
          <Card.Body>
            <LoginForm onSubmit={onSubmit} loading={loading} />
          </Card.Body>
        </Card>
      </>
    );
};
