import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const SurveySection = () => {
  const [formData, setFormData] = useState({
    role: "deidentified",
    value: "",
    email: ""
  });
  const [user, setUser] = useState(null);
  const [noUser, setNoUser] = useState(false);
  const [surveyURL, setSurveyURL] = useState(null);

  const centerId = useSelector(state => state.auth.user.center);

  const { value } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const searchUser = async e => {
    e.preventDefault();
    const user = await axios.get(
      `/api/v1/users?center=${centerId}&pid=${value}`
    );
    if (user.data.data[0]) {
      setUser(user.data.data[0]);
      setNoUser(false);
    } else {
      setUser(null);
      setNoUser(true);
    }
  };

  const startSurvey = async e => {
    e.preventDefault();
    const protocol = window.location.protocol;
    const host = window.location.host;
    const gwPass = await axios.post(`/api/v1/auth/survey/${user.id}`);
    const surveyTokenURL = `${protocol}//${host}/survey/${gwPass.data.data}`;
    console.log(gwPass.data.data);
    setSurveyURL(surveyTokenURL);
  };

  return (
    <>
      <div
        style={{
          margin: "1rem auto",
          width: "30rem",
          maxWidth: "95%"
        }}
      >
        <p>
          1. Search for participant via PID{<br />}
          2. Create a link by clicking CREATE SURVEY LINK{<br />}
          3. You can email this link to participants for remote surveys, or go
          to the link in your browser to administer a local survey
          {<br />}
          {<br />}
          Once generated, a link has 48 hours until it expires. At which point,
          a new link will need to be generated.
        </p>
      </div>
      <form className="form form--card form--card--wide">
        <h3 className="form__title">SELECT USER</h3>
        <div className="form__section" style={{ margin: "1rem auto" }}>
          <label className="form__label">PID</label>
          <input
            className="form__input"
            value={value}
            onChange={onChange}
            name="value"
          />
        </div>
        {!user && (
          <button
            onClick={searchUser}
            className="btn btn--sm btn--formSubmit"
            style={{ maxWidth: "750px" }}
          >
            SEARCH
          </button>
        )}
        {noUser && (
          <p style={{ opacity: "0.7", textAlign: "center", margin: "1.5rem" }}>
            No results found
          </p>
        )}
        {user && (
          <>
            <div className="row__title" style={{ marginTop: "1.5rem" }}>
              Found: {user.pid}
            </div>
            <div className="row__text">Next survey: {user.surveyCount + 1}</div>
            <p style={{ margin: "1rem auto 0rem auto" }}>Survey Link:</p>
            {!surveyURL ? (
              <button onClick={startSurvey} className="btn btn--sm">
                <span className="btn__text">CREATE SURVEY LINK</span>
              </button>
            ) : (
              <div
                style={{
                  margin: "1rem auto",
                  fontSize: "0.9rem",
                  width: "23rem",
                  maxWidth: "85%",
                  textAlign: "center",
                  wordWrap: "break-word"
                }}
              >
                <a href={surveyURL} className="link">
                  {surveyURL}
                </a>
              </div>
            )}
          </>
        )}
      </form>
    </>
  );
};

export default SurveySection;
