import React from "react";
import Card from "react-bootstrap/Card";
import ResetPassword from "../../components/ResetPassword.jsx";

export default (props) => {
  return (
    <>
      <Card className="mt-5">
        <Card.Header as="h1">RESET PASSWORD</Card.Header>
        <Card.Body>
          <ResetPassword props={props} />
        </Card.Body>
      </Card>
    </>
  );
};
