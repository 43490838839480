import React from "react";
import Answer from "./Answer.component";

import { answer } from "../../data/randsf36";

type Props = {
  selectAnswer: (idx: number, value: number) => void;
  questionIndex: number;
  currentValue: number;
  questionText: JSX.Element;
  answers: answer[];
};

export const Question = ({
  selectAnswer,
  questionIndex,
  currentValue,
  questionText,
  answers,
}: Props) => {
  const handleChange = (value: number) => {
    selectAnswer(questionIndex, value);
  };

  const mapAnswers = (As: answer[]) => {
    const answers = As.map((a: answer, i: number) => {
      return (
        <Answer
          key={i}
          answerText={a.answerText}
          value={a.value}
          onChange={handleChange}
          selected={currentValue === a.value}
        />
      );
    });
    return answers;
  };

  return (
    <div className="questionBody">
      {questionIndex + 1}. {questionText}
      <div className="questionAnswers">{mapAnswers(answers)}</div>
    </div>
  );
};
