import React from 'react'
import Settings from '../../../components/manager/TwSettings.jsx'

export default () => {
  return (
    <div>
     <Settings /> 
    </div>
  )
}
