import React from "react";
import adminDashboardLinks from "../../data/adminDashboardLinks.tsx";
import { directorDashboardLinks } from "../../data/directorDashboardLinks.tsx";
import { Link } from "react-router-dom";
import { Container, Card, Row } from "react-bootstrap";

export default ({ permissions }) => {
  const arr =
    permissions === "admin" ? adminDashboardLinks : directorDashboardLinks;
  console.log("arr:", permissions)
  return (
    <Container>
      <Row>
        {arr.map((link) => {
          return (
            <Card
              className="mt-5 mx-3"
              style={{ width: "25rem" }}
              key={link.url}
            >
              <Card.Header as="h2">{link.header}</Card.Header>
              <Card.Body>
                <Card.Text>{link.body}</Card.Text>
                <Card.Link as={Link} href={link.url} to={link.url}>
                  {link.urlText}
                </Card.Link>
              </Card.Body>
            </Card>
          );
        })}
      </Row>
    </Container>
  );
};
