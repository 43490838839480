export type dashboardCard = {
  header: string;
  body: string;
  url: string;
  urlText: string;
  newTab?: boolean;
};

const managerDashboardLinks: dashboardCard[] = [
  {
    header: "Instructions Page",
    body:
      "Read up on how to securely store participant information, administer surveys and create graphs for participants with our tools.",
    url: "/manager/guide",
    urlText: "Help page",
  },
  {
    header: "Participant Center",
    body:
      "Create new Participant IDs, generate new survey links, and get other general information about all center participants.",
    url: "/manager/pid-browser",
    urlText: "PID Center",
  },
  {
    header: "Graph Browser",
    body:
      "View all participant graphs for your center. Compare to earlier surveys, view aggregate scores, and execute advanced searches.",
    url: "/manager/graph-browser",
    urlText: "Graph Broswer",
  },
  {
    header: "Demo Survey",
    body:
      "Fill out and submit a demo RAND SF-36 survey for presentational purposes that won't be saved after use.",
    url: "/demo",
    urlText: "Demonstration",
    newTab: true,
  },
];

export default managerDashboardLinks;
