import React, { useState } from "react";
import { Message } from "../layout/Message";
import axios from "axios";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

// TYPES
// import { adminObj } from "../../data/types";

export const RegisterDirector = () => {
  // const [role, setRole] = useState("director");
  const role = "director"
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const roleIsValid = (s: string): boolean => s === "director" || s === "admin";
  const nameIsValid = (s: string): boolean => s.length > 5;
  const passwordIsValid = (s: string): boolean => s.length > 7;
  const confirmPasswordIsValid = (
    password: string,
    confirmPassword: string
  ): boolean => confirmPassword === password;

  // Email validation function
  const emailIsValid = (email: string): boolean => {
    const re = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return re.test(email.toLowerCase());
  };

  const [message, setMessage] = useState("");

  const onSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!name || !password || !email) {
      return setMessage("You must fill out all the data");
    }

    if (password !== confirmPassword) {
      return setMessage("Passwords do not match");
    }

    const formData = { name, email, password, role };

    try {
      const res = await axios.post("/api/v1/directors", formData);
      console.log(res);
      if (!res.data.success) setMessage(res.data.msg);
      else setMessage("Director created successfully");
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.msg || err.response.data.error);
      }
    }
  };

  return (
    <Container style={{ maxWidth: "50rem" }}>
      {message && <Message msg={message} setMsg={setMessage} />}
      <Form encType="multipart/form-data" onSubmit={onSubmit}>
        <h3>REGISTER DIRECTOR</h3>
        <Form.Group>
          <Form.Label>Sector Name</Form.Label>
          <FormControl
            required
            type="text"
            placeholder="Enter sector name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isValid={nameIsValid(name)}
            isInvalid={!nameIsValid(name) && name.length > 0}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Name must be greater than 5 characters
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <FormControl
            required
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isValid={emailIsValid(email) && email.length > 0}
            isInvalid={!emailIsValid(email)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Invalid email
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Passowrd</Form.Label>
          <FormControl
            required
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            isValid={passwordIsValid(password)}
            isInvalid={!passwordIsValid(password) && password.length > 0}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Password must be longer than 7 characters
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm password</Form.Label>
          <FormControl
            type="password"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            isValid={
              confirmPasswordIsValid(password, confirmPassword) &&
              confirmPassword.length > 0
            }
            isInvalid={!confirmPasswordIsValid(password, confirmPassword)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Passwords do not match
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          {/* <Select
            arr={[{ name: "director", value: "director" }]}
            selectedItem={role}
            handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setRole(e.target.value)
            }
            defaultMsg="Select role"
            defaultLabel="Role"
            isFetching={false}
            defaultLoadingMsg="Loading roles..."
            isValid={roleIsValid(role)}
          /> */}
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Select valid role
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          type="submit"
          disabled={
            !emailIsValid(email) ||
            !nameIsValid(name) ||
            !passwordIsValid(password) ||
            !confirmPasswordIsValid(password, confirmPassword) ||
            !roleIsValid(role)
          }
        >
          REGISTER
        </Button>
      </Form>
    </Container>
  );
};
