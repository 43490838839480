export type dashboardCard = {
  header: string;
  body: string;
  url: string;
  urlText: string;
  newTab?: boolean;
};

const managerDashboardLinks: dashboardCard[] = [
  {
    header: "Página de instrucciones",
    body:
      "Obtenga información sobre cómo almacenar de forma segura la información de los participantes, administrar encuestas y crear gráficos para los participantes con nuestras herramientas.",
    url: "/es/manager/guide",
    urlText: "Página de ayuda",
  },
  {
    header: "Centro participanter",
    body:
      "Cree nuevos ID de participante, genere nuevos enlaces de encuestas y obtenga otra información general sobre todos los participantes del centro.",
    url: "/es/manager/pid-browser",
    urlText: "Centro PID",
  },
  {
    header: "Navegador de gráficosr",
    body:
      "Vea todos los gráficos de participantes de su centro. Compare con encuestas anteriores, vea puntuaciones agregadas y ejecute búsquedas avanzadas.",
    url: "/es/manager/graph-browser",
    urlText: "Navegador de gráficosr",
  },
  {
    header: "Encuesta de demostracióny",
    body:
      "Complete y envíe una encuesta RAND SF-36 de demostración con fines de presentación que no se guardará después de su uso.",
    url: "/demo",
    urlText: "Demostración",
    newTab: true,
  },
];

export default managerDashboardLinks;
