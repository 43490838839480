import React from "react";
import { RestoredValorConsent } from "./RestoredValorConsent";
import DefaultConsent from "./DefaultConsent";

export default ({ location }) => {
  const region = location.search;
  const language = region === "?region=eu" ? "eu" : null;
  if (language === "English") return <RestoredValorConsent />;
  if (language === "Tiếng Việt") return <RestoredValorConsent />;
  if (language === "Italiano") return <RestoredValorConsent />;
  else return <DefaultConsent />;
};
