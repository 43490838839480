import React, { useState } from "react";
import axios from "axios";
// import axios from "axios";

import { User } from "./PIDBrowserWrapper";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

interface Props {
  user: User;
}

const DeleteUserModal = ({ user }: Props) => {
  const [pidField, setPidField] = useState("");
  const [confirmPid, setConfirmPid] = useState("");
  const [secondConfirmPid, setSecondConfirmPid] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const PidsMatch = () =>
    pidField === user.pid.toString() &&
    confirmPid === user.pid.toString() &&
    secondConfirmPid === user.pid.toString();

  const deletePID = async () => {
    setLoading(true);
    const res = await axios.delete(`/api/v1/users/${user.id}`);
    console.log("RES: ", res);
    setSuccess(res.data.success);
    setLoading(false);
  };

  return (
    <div>
      {success === true ? (
        <div style={{ textAlign: "right" }}>
          <p>PID 刪除成功。關閉此窗口，頁面將刷新。</p>
        </div>
      ) : (
        <div style={{ textAlign: "right" }}>
          <h3>您確定要刪除以下用戶嗎？</h3>
          <h5>PID：{user.pid}</h5>
          <p>
            此操作無法撤消，並刪除 PID
            和他們填寫的所有調查。如果您確定，請輸入以下指定的 PID 進行確認：
          </p>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={"輸入PID"}
              value={pidField}
              onChange={(e) => setPidField(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={"確認PID"}
              value={confirmPid}
              onChange={(e) => setConfirmPid(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={"再次確認PID"}
              value={secondConfirmPid}
              onChange={(e) => setSecondConfirmPid(e.target.value)}
            />
          </InputGroup>
          {PidsMatch() && (
            <Button variant="danger" onClick={(_) => deletePID()}>
              {loading ? (
                <Spinner
                  animation="border"
                  role="loading"
                  size="sm"
                  aria-hidden="true"
                />
              ) : (
                <span>刪除</span>
              )}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default DeleteUserModal;
