import React from "react";

import randQuestionnaire from "../randsf36";

const spanishQuestionnaire: randQuestionnaire = {
  language: "Español",
  questionsAndAnswers: [
    {
      questionText: <span>En general, diría que su salud es:</span>,
      answers: [
        {
          answerText: "Excelente",
          value: 100,
        },
        {
          answerText: "Muy buena",
          value: 75,
        },
        {
          answerText: "Buena",
          value: 50,
        },
        {
          answerText: "Regular",
          value: 25,
        },
        {
          answerText: "Mala",
          value: 0,
        },
      ],
    },
    {
      questionText: (
        <span>
          <b>Comparado con hace un año</b>, ¿cómo calificaría su salud en
          general ahora?
        </span>
      ),
      answers: [
        {
          answerText: "Mucho mejor ahora que hace un año",
          value: 100,
        },
        {
          answerText: "Algo mejor ahora que hace un año",
          value: 75,
        },
        {
          answerText: "Más o menos lo mismo que hace un año",
          value: 50,
        },
        {
          answerText: "Algo peor ahora que hace un año",
          value: 25,
        },
        {
          answerText: "Mucho peor ahora que hace un año",
          value: 0,
        },
      ],
    },
    {
      questionText: (
        <span>
          <b>Actividades vigorosas</b>, como correr, levantar objetos pesados,
          participar en deportes vigorosos
        </span>
      ),
      answers: [
        {
          answerText: "Sí, me limita mucho",
          value: 0,
        },

        {
          answerText: "Sí, me limita un poco",
          value: 50,
        },
        {
          answerText: "No, no me limita an absoluto",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          <b>Actividades moderadas</b>, como mover una mesa, empujar una
          aspiradora, jugar bolos o golf.
        </span>
      ),
      answers: [
        {
          answerText: "Sí, me limita mucho",
          value: 0,
        },

        {
          answerText: "Sí, me limita un poco",
          value: 50,
        },
        {
          answerText: "No, no me limita an absoluto",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>Levantar o cargar suministros o comestibles</span>,
      answers: [
        {
          answerText: "Sí, me limita mucho",
          value: 0,
        },

        {
          answerText: "Sí, me limita un poco",
          value: 50,
        },
        {
          answerText: "No, no me limita an absoluto",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Subir <b>varios</b> tramos de escaleras
        </span>
      ),
      answers: [
        {
          answerText: "Sí, me limita mucho",
          value: 0,
        },

        {
          answerText: "Sí, me limita un poco",
          value: 50,
        },
        {
          answerText: "No, no me limita an absoluto",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Subir <b>un</b> tramo de escaleras
        </span>
      ),
      answers: [
        {
          answerText: "Sí, me limita mucho",
          value: 0,
        },
        {
          answerText: "Sí, me limita un poco",
          value: 50,
        },
        {
          answerText: "No, no me limita an absoluto",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>Doblarse, arrodillarse o agacharse</span>,
      answers: [
        {
          answerText: "Sí, me limita mucho",
          value: 0,
        },
        {
          answerText: "Sí, me limita un poco",
          value: 50,
        },
        {
          answerText: "No, no me limita an absoluto",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Caminar <b>más de una milla (2 km)</b>
        </span>
      ),
      answers: [
        {
          answerText: "Sí, me limita mucho",
          value: 0,
        },

        {
          answerText: "Sí, me limita un poco",
          value: 50,
        },
        {
          answerText: "No, no me limita an absoluto",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Caminar <b>varias cuadras</b>
        </span>
      ),
      answers: [
        {
          answerText: "Sí, me limita mucho",
          value: 0,
        },

        {
          answerText: "Sí, me limita un poco",
          value: 50,
        },
        {
          answerText: "No, no me limita an absoluto",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Caminando <b>una cuadra</b>
        </span>
      ),
      answers: [
        {
          answerText: "Sí, me limita mucho",
          value: 0,
        },

        {
          answerText: "Sí, me limita un poco",
          value: 50,
        },
        {
          answerText: "No, no me limita an absoluto",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          <b>Bañarse</b> o vestirse usted mismo
        </span>
      ),
      answers: [
        {
          answerText: "Sí, me limita mucho",
          value: 0,
        },

        {
          answerText: "Sí, me limita un poco",
          value: 50,
        },
        {
          answerText: "No, no me limita an absoluto",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Redujo la <b>cantidad de tiempo</b> que pasa en el trabajo u otras
          actividades
        </span>
      ),
      answers: [
        {
          answerText: "Sí",
          value: 0,
        },

        {
          answerText: "No",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          <b>Logró</b> menos de lo que le gustaría
        </span>
      ),
      answers: [
        {
          answerText: "Sí",
          value: 0,
        },

        {
          answerText: "No",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Estaban limitados en el <b>tipo</b> de trabajo u otras actividades
        </span>
      ),
      answers: [
        {
          answerText: "Sí",
          value: 0,
        },

        {
          answerText: "No",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Tuvo <b>dificultad</b> para realizar el trabajo regular u otras
          actividades (por ejemplo: requirió esfuerzo adicional)
        </span>
      ),
      answers: [
        {
          answerText: "Sí",
          value: 0,
        },

        {
          answerText: "No",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Redujo la <b>cantidad de tiempo</b> que pasa en el trabajo u otras
          actividades
        </span>
      ),
      answers: [
        {
          answerText: "Sí",
          value: 0,
        },

        {
          answerText: "No",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          <b>Logró menos</b> de lo que le gustaría
        </span>
      ),
      answers: [
        {
          answerText: "Sí",
          value: 0,
        },

        {
          answerText: "No",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          No hizo el trabajo u otras actividades tan <b>cuidadosamente</b> como
          de costumbre
        </span>
      ),
      answers: [
        {
          answerText: "Sí",
          value: 0,
        },

        {
          answerText: "No",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Durante las últimas <b>4 semanas</b>, ¿hasta qué punto su salud física
          o problemas emocionales han interferido con sus actividades sociales
          normales con su familia, amigos, vecinos o grupos?
        </span>
      ),
      answers: [
        {
          answerText: "En absoluto",
          value: 100,
        },
        {
          answerText: "Un poco",
          value: 75,
        },
        {
          answerText: "Moderadamente",
          value: 50,
        },
        {
          answerText: "Bastante",
          value: 25,
        },
        {
          answerText: "Extremadamente",
          value: 0,
        },
      ],
    },
    {
      questionText: (
        <span>
          ¿Cuánto dolor <b>corporal</b> ha tenido en las últimas{" "}
          <b>4 semanas</b>?
        </span>
      ),
      answers: [
        {
          answerText: "Ninguno",
          value: 100,
        },
        {
          answerText: "Muy leve",
          value: 80,
        },
        {
          answerText: "Leve",
          value: 60,
        },
        {
          answerText: "Moderada",
          value: 40,
        },
        {
          answerText: "Severa",
          value: 20,
        },
        {
          answerText: "Muy severa",
          value: 0,
        },
      ],
    },
    {
      questionText: (
        <span>
          Durante las últimas <b>4 semanas</b>, ¿en qué medida el <b>dolor</b>{" "}
          interfirió con su trabajo normal (incluyendo el empleo y el trabajo en
          la casa)?
        </span>
      ),
      answers: [
        {
          answerText: "En absoluto",
          value: 100,
        },
        {
          answerText: "Un poco",
          value: 75,
        },
        {
          answerText: "Moderadamente",
          value: 50,
        },
        {
          answerText: "Bastante",
          value: 25,
        },
        {
          answerText: "Extremadamente",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>¿Te sentiste lleno de energía?</span>,
      answers: [
        {
          answerText: "Todo el tiempo",
          value: 100,
        },
        {
          answerText: "La mayor parte del tiempo",
          value: 80,
        },
        {
          answerText: "Una buena parte del tiempo",
          value: 60,
        },
        {
          answerText: "Algunas veces",
          value: 40,
        },
        {
          answerText: "Un poco de tiempo",
          value: 20,
        },
        {
          answerText: "Ninguna de las veces",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>¿Has sido una persona muy nerviosa?</span>,
      answers: [
        {
          answerText: "Todo el tiempo",
          value: 0,
        },
        {
          answerText: "La mayor parte del tiempo",
          value: 20,
        },
        {
          answerText: "Una buena parte del tiempo",
          value: 40,
        },
        {
          answerText: "Algunas veces",
          value: 60,
        },
        {
          answerText: "Un poco de tiempo",
          value: 80,
        },
        {
          answerText: "Ninguna de las veces",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>¿Te has sentido tan deprimido que nada podría animarte?</span>
      ),
      answers: [
        {
          answerText: "Todo el tiempo",
          value: 0,
        },
        {
          answerText: "La mayor parte del tiempo",
          value: 20,
        },
        {
          answerText: "Una buena parte del tiempo",
          value: 40,
        },
        {
          answerText: "Algunas veces",
          value: 60,
        },
        {
          answerText: "Un poco de tiempo",
          value: 80,
        },
        {
          answerText: "Ninguna de las veces",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>¿Te has sentido tranquilo y en paz?</span>,
      answers: [
        {
          answerText: "Todo el tiempo",
          value: 100,
        },
        {
          answerText: "La mayor parte del tiempo",
          value: 80,
        },
        {
          answerText: "Una buena parte del tiempo",
          value: 60,
        },
        {
          answerText: "Algunas veces",
          value: 40,
        },
        {
          answerText: "Un poco de tiempo",
          value: 20,
        },
        {
          answerText: "Ninguna de las veces",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>¿Tenías mucha energía?</span>,
      answers: [
        {
          answerText: "Todo el tiempo",
          value: 100,
        },
        {
          answerText: "La mayor parte del tiempo",
          value: 80,
        },
        {
          answerText: "Una buena parte del tiempo",
          value: 60,
        },
        {
          answerText: "Algunas veces",
          value: 40,
        },
        {
          answerText: "Un poco de tiempo",
          value: 20,
        },
        {
          answerText: "Ninguna de las veces",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>¿Te has sentido deprimido y triste?</span>,
      answers: [
        {
          answerText: "Todo el tiempo",
          value: 0,
        },
        {
          answerText: "La mayor parte del tiempo",
          value: 20,
        },
        {
          answerText: "Una buena parte del tiempo",
          value: 40,
        },
        {
          answerText: "Algunas veces",
          value: 60,
        },
        {
          answerText: "Un poco de tiempo",
          value: 80,
        },
        {
          answerText: "Ninguna de las veces",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>¿Te sentiste agotado?</span>,
      answers: [
        {
          answerText: "Todo el tiempo",
          value: 0,
        },
        {
          answerText: "La mayor parte del tiempo",
          value: 20,
        },
        {
          answerText: "Una buena parte del tiempo",
          value: 40,
        },
        {
          answerText: "Algunas veces",
          value: 60,
        },
        {
          answerText: "Un poco de tiempo",
          value: 80,
        },
        {
          answerText: "Ninguna de las veces",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>¿Has sido una persona feliz?</span>,
      answers: [
        {
          answerText: "Todo el tiempo",
          value: 100,
        },
        {
          answerText: "La mayor parte del tiempo",
          value: 80,
        },
        {
          answerText: "Una buena parte del tiempo",
          value: 60,
        },
        {
          answerText: "Algunas veces",
          value: 40,
        },
        {
          answerText: "Un poco de tiempo",
          value: 20,
        },
        {
          answerText: "Ninguna de las veces",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>¿Se sintió cansado?</span>,
      answers: [
        {
          answerText: "Todo el tiempo",
          value: 0,
        },
        {
          answerText: "La mayor parte del tiempo",
          value: 20,
        },
        {
          answerText: "Una buena parte del tiempo",
          value: 40,
        },
        {
          answerText: "Algunas veces",
          value: 60,
        },
        {
          answerText: "Un poco de tiempo",
          value: 80,
        },
        {
          answerText: "Ninguna de las veces",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          Durante las <b>últimas 4 semanas</b>, ¿cuánto tiempo ha interferido
          <b>su salud física o problemas emocionales</b> con sus actividades
          sociales (como visitar amigos, parientes, etc.)?
        </span>
      ),
      answers: [
        {
          answerText: "Todo el tiempo",
          value: 0,
        },
        {
          answerText: "La mayor parte del tiempo",
          value: 25,
        },
        {
          answerText: "Algunas veces",
          value: 50,
        },
        {
          answerText: "Un poco de tiempo",
          value: 75,
        },
        {
          answerText: "Ninguna de las veces",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>Parece que me enfermo un poco más fácil</span>,
      answers: [
        {
          answerText: "Definitivamente cierto",
          value: 0,
        },
        {
          answerText: "Mayormente cierto",
          value: 25,
        },
        {
          answerText: "No lo sé",
          value: 50,
        },
        {
          answerText: "Mayormente falso",
          value: 75,
        },
        {
          answerText: "Definitivamente falso",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>Estoy tan saludable como cualquiera que conozco</span>
      ),
      answers: [
        {
          answerText: "Definitivamente cierto",
          value: 100,
        },
        {
          answerText: "Mayormente cierto",
          value: 75,
        },
        {
          answerText: "No lo sé",
          value: 50,
        },
        {
          answerText: "Mayormente falso",
          value: 25,
        },
        {
          answerText: "Definitivamente falso",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>Espero que mi salud empeore</span>,
      answers: [
        {
          answerText: "Definitivamente cierto",
          value: 0,
        },
        {
          answerText: "Mayormente cierto",
          value: 25,
        },
        {
          answerText: "No lo sé",
          value: 50,
        },
        {
          answerText: "Mayormente falso",
          value: 75,
        },
        {
          answerText: "Definitivamente falso",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>Mi salud es excelente</span>,
      answers: [
        {
          answerText: "Definitivamente cierto",
          value: 100,
        },
        {
          answerText: "Mayormente cierto",
          value: 75,
        },
        {
          answerText: "No lo sé",
          value: 50,
        },
        {
          answerText: "Mayormente falso",
          value: 25,
        },
        {
          answerText: "Definitivamente falso",
          value: 0,
        },
      ],
    },
  ],
  headers: [
    <span>
      Los siguientes puntos se refieren a las actividades que usted puede hacer
      durante un día típico. <b>¿Su salud lo limita ahora</b> en estas
      actividades? Si es así, ¿cuánto?
    </span>,
    <span>
      Durante las últimas <b>4 semanas</b>, ¿ha tenido alguno de los siguientes
      problemas con su trabajo u otras actividades diarias regulares
      <b>como resultado de su salud física?</b>
    </span>,
    <span>
      Durante las últimas <b>4 semanas</b>, ¿ha tenido alguno de los siguientes
      problemas con su trabajo u otras actividades diarias regulares{" "}
      <b>como resultado de algún problema emocional</b> (como sentirse deprimido
      o ansioso)?
    </span>,
    <span>
      Estas preguntas son sobre cómo se siente y cómo han sido las cosas con usted <b>durante las últimas 4 semanas.</b> Para cada pregunta, por favor dé la respuesta que más se aproxime a la forma en que se ha estado sintiendo.
      <br />
      ¿Cuánto tiempo durante las <b>últimas 4 semanas...?</b>
    </span>,
    <span>
      ¿Qué tan VERDADERO o FALSO es <b>cada una</b> de las siguientes afirmaciones para usted?
    </span>,
  ],
};

export default spanishQuestionnaire;
