import React from "react";
import NotFound from "../../pages/not-found/index.tsx";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import SurveySection from "../manager/SurveySection";
import GraphBrowserUser from "../admin/GraphBrowserUser";
import Quickbar from "./Quickbar.jsx";

// styles components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// pages
import managerDashboard from "../../pages/manager/managerDashboard.tsx";
import { PidBrowser } from "../../pages/manager/pid-browser/index.ts";
import Settings from "../../pages/manager/Settings";
import instructions from "../../pages/manager/instructions";

const ManagerRoute = () => {
  const auth = useSelector((state) => state.auth);
  const isManager = auth.user ? auth.user.role === "manager" : false;

  if (!isManager && !auth.loading) {
    return <Redirect to="/" />;
  } else if (isManager) {
    return (
      <Router>
        <Container className="mt-5">
          <Row>
            <Col xs lg="3">
              <Route path={"/manager"} component={Quickbar} />
            </Col>
            <Col lg="8">
              <Switch>
                <Route path="/manager" component={managerDashboard} exact />
                <Route path="/manager/guide" component={instructions} exact />
                <Route
                  path="/manager/graph-browser"
                  component={GraphBrowserUser}
                  exact
                />
                <Route
                  path="/manager/survey-center"
                  component={SurveySection}
                  exact
                />
                <Route
                  path="/manager/pid-browser"
                  component={PidBrowser}
                  exact
                />
                <Route path="/manager/settings" component={Settings} exact />
                <Route path="/manager" component={NotFound} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </Router>
    );
  } else {
    return <div></div>;
  }
};

export default ManagerRoute;
