import React from "react";

import randQuestionnaire from "../randsf36";

const chineseQuestionnaire: randQuestionnaire = {
  language: "中國人",
  questionsAndAnswers: [
    {
      questionText: <span>總體來講，您的健康狀況是：</span>,
      answers: [
        {
          answerText: "非常好",
          value: 100,
        },
        {
          answerText: "很好",
          value: 75,
        },
        {
          answerText: "好",
          value: 50,
        },
        {
          answerText: "一般",
          value: 25,
        },
        {
          answerText: "差",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>跟1年以前比您覺得自己的健康狀況是：</span>,
      answers: [
        {
          answerText: "比1年前好多了",
          value: 100,
        },
        {
          answerText: "比1年前好一些",
          value: 75,
        },
        {
          answerText: "跟1年前差不多",
          value: 50,
        },
        {
          answerText: "比1年前差一些",
          value: 25,
        },
        {
          answerText: "比1年前差多了",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>重體力活動。如跑步舉重、參加劇烈運動等：</span>,
      answers: [
        {
          answerText: "限制很大",
          value: 0,
        },

        {
          answerText: "有些限制",
          value: 50,
        },
        {
          answerText: "毫無限制",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>適度的活動。如移動一張桌子、掃地、打太極拳、做簡單體操等：</span>
      ),
      answers: [
        {
          answerText: "限制很大",
          value: 0,
        },

        {
          answerText: "有些限制",
          value: 50,
        },
        {
          answerText: "毫無限制",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>手提日用品。如買菜、購物等：</span>,
      answers: [
        {
          answerText: "限制很大",
          value: 0,
        },

        {
          answerText: "有些限制",
          value: 50,
        },
        {
          answerText: "毫無限制",
          value: 100,
        },
      ],
    },
    /////////////////////////////////////////////////////
    {
      questionText: <span>上幾層樓梯：</span>,
      answers: [
        {
          answerText: "限制很大",
          value: 0,
        },

        {
          answerText: "有些限制",
          value: 50,
        },
        {
          answerText: "毫無限制",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>上一層樓梯：</span>,
      answers: [
        {
          answerText: "限制很大",
          value: 0,
        },
        {
          answerText: "有些限制",
          value: 50,
        },
        {
          answerText: "毫無限制",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>彎腰、屈膝、下蹲：</span>,
      answers: [
        {
          answerText: "限制很大",
          value: 0,
        },
        {
          answerText: "有些限制",
          value: 50,
        },
        {
          answerText: "毫無限制",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>步行1600米以上的路程：</span>,
      answers: [
        {
          answerText: "限制很大",
          value: 0,
        },

        {
          answerText: "有些限制",
          value: 50,
        },
        {
          answerText: "毫無限制",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>步行800米的路程：</span>,
      answers: [
        {
          answerText: "限制很大",
          value: 0,
        },

        {
          answerText: "限制很大",
          value: 50,
        },
        {
          answerText: "限制很大",
          value: 100,
        },
      ],
    },
    /////////////////////////////////////////////////////
    {
      questionText: <span>步行100米的路程：</span>,
      answers: [
        {
          answerText: "限制很大",
          value: 0,
        },

        {
          answerText: "有些限制",
          value: 50,
        },
        {
          answerText: "毫無限制",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>自己洗澡、穿衣：</span>,
      answers: [
        {
          answerText: "限制很大",
          value: 0,
        },
        {
          answerText: "有些限制",
          value: 50,
        },
        {
          answerText: "毫無限制",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>減少了工作或其他活動時間：</span>,
      answers: [
        {
          answerText: "是",
          value: 0,
        },

        {
          answerText: "不是",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>本來想要做的事情只能完成一部分：</span>,
      answers: [
        {
          answerText: "是",
          value: 0,
        },

        {
          answerText: "不是",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>想要做的工作或活動種類受到限制：</span>,
      answers: [
        {
          answerText: "是",
          value: 0,
        },

        {
          answerText: "不是",
          value: 100,
        },
      ],
    },
    ///////////////////////////////////////////////////
    {
      questionText: (
        <span>完成工作或其他活動困難增多（比如需要額外的努力）：</span>
      ),
      answers: [
        {
          answerText: "是",
          value: 0,
        },

        {
          answerText: "不是",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>減少了工作或活動時間：</span>,
      answers: [
        {
          answerText: "是",
          value: 0,
        },

        {
          answerText: "不是",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>本來想要做的事情只能完成一部分：</span>,
      answers: [
        {
          answerText: "是",
          value: 0,
        },

        {
          answerText: "不是",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>做事情不如平時仔細：</span>,
      answers: [
        {
          answerText: "是",
          value: 0,
        },

        {
          answerText: "不是",
          value: 100,
        },
      ],
    },
    {
      questionText: (
        <span>
          在過去4個星期裡，您的健康或情緒不好在多大程度上影響了您與家人、朋友、鄰居或集體的正常社會交往？
        </span>
      ),
      answers: [
        {
          answerText: "完全沒有影響",
          value: 100,
        },
        {
          answerText: "有一點影響",
          value: 75,
        },
        {
          answerText: "中等影響",
          value: 50,
        },
        {
          answerText: "影響很大",
          value: 25,
        },
        {
          answerText: "影響非常大",
          value: 0,
        },
      ],
    },
    ////////////////////////////////////////////
    {
      questionText: <span>在過去4個星期裡，您有身體疼痛嗎？</span>,
      answers: [
        {
          answerText: "完全沒有疼痛",
          value: 100,
        },
        {
          answerText: "有一點疼痛",
          value: 80,
        },
        {
          answerText: "輕度疼痛",
          value: 60,
        },
        {
          answerText: "中度疼痛",
          value: 40,
        },
        {
          answerText: "嚴重疼痛",
          value: 20,
        },
        {
          answerText: "很嚴重疼痛",
          value: 0,
        },
      ],
    },
    {
      questionText: (
        <span>在過去4個星期裡，您的身體疼痛影響了您的工作和家務嗎？</span>
      ),
      answers: [
        {
          answerText: "完全沒有影響",
          value: 100,
        },
        {
          answerText: "有一點影響",
          value: 75,
        },
        {
          answerText: "中等影響",
          value: 50,
        },
        {
          answerText: "影響很大",
          value: 25,
        },
        {
          answerText: "影響非常大",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>您覺得生活充實：</span>,
      answers: [
        {
          answerText: "所有的時間",
          value: 100,
        },
        {
          answerText: "大部分時間",
          value: 80,
        },
        {
          answerText: "比較多時間",
          value: 60,
        },
        {
          answerText: "一部分時間",
          value: 40,
        },
        {
          answerText: "小部分時間",
          value: 20,
        },
        {
          answerText: "小部分時間",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>您是一個敏感的人：</span>,
      answers: [
        {
          answerText: "所有的時間",
          value: 0,
        },
        {
          answerText: "大部分時間",
          value: 20,
        },
        {
          answerText: "比較多時間",
          value: 40,
        },
        {
          answerText: "一部分時間",
          value: 60,
        },
        {
          answerText: "小部分時間",
          value: 80,
        },
        {
          answerText: "沒有這種感覺",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>您的情緒非常不好，什麼事都不能使您高興起來：</span>,
      answers: [
        {
          answerText: "所有的時間",
          value: 0,
        },
        {
          answerText: "大部分時間",
          value: 20,
        },
        {
          answerText: "比較多時間",
          value: 40,
        },
        {
          answerText: "一部分時間",
          value: 60,
        },
        {
          answerText: "小部分時間",
          value: 80,
        },
        {
          answerText: "沒有這種感覺",
          value: 100,
        },
      ],
    },
    ////////////////////////////////////////////////////
    {
      questionText: <span>您的心理很平靜：</span>,
      answers: [
        {
          answerText: "所有的時間",
          value: 100,
        },
        {
          answerText: "大部分時間",
          value: 80,
        },
        {
          answerText: "比較多時間",
          value: 60,
        },
        {
          answerText: "一部分時間",
          value: 40,
        },
        {
          answerText: "小部分時間",
          value: 20,
        },
        {
          answerText: "沒有這種感覺",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>您做事精力充沛：</span>,
      answers: [
        {
          answerText: "所有的時間",
          value: 100,
        },
        {
          answerText: "大部分時間",
          value: 80,
        },
        {
          answerText: "比較多時間",
          value: 60,
        },
        {
          answerText: "一部分時間",
          value: 40,
        },
        {
          answerText: "小部分時間",
          value: 20,
        },
        {
          answerText: "沒有這種感覺",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>您的情緒低落：</span>,
      answers: [
        {
          answerText: "所有的時間",
          value: 0,
        },
        {
          answerText: "大部分時間",
          value: 20,
        },
        {
          answerText: "比較多時間",
          value: 40,
        },
        {
          answerText: "一部分時間",
          value: 60,
        },
        {
          answerText: "小部分時間",
          value: 80,
        },
        {
          answerText: "沒有這種感覺",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>您覺得筋疲力盡：</span>,
      answers: [
        {
          answerText: "所有的時間",
          value: 0,
        },
        {
          answerText: "大部分時間",
          value: 20,
        },
        {
          answerText: "比較多時間",
          value: 40,
        },
        {
          answerText: "一部分時間",
          value: 60,
        },
        {
          answerText: "小部分時間",
          value: 80,
        },
        {
          answerText: "沒有這種感覺",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>您是個快樂的人：</span>,
      answers: [
        {
          answerText: "所有的時間",
          value: 100,
        },
        {
          answerText: "大部分時間",
          value: 80,
        },
        {
          answerText: "比較多時間",
          value: 60,
        },
        {
          answerText: "一部分時間",
          value: 40,
        },
        {
          answerText: "小部分時間",
          value: 20,
        },
        {
          answerText: "沒有這種感覺",
          value: 0,
        },
      ],
    },
    ////////////////////////////////////////////////
    {
      questionText: <span>您感覺厭煩：</span>,
      answers: [
        {
          answerText: "所有的時間",
          value: 0,
        },
        {
          answerText: "大部分時間",
          value: 20,
        },
        {
          answerText: "比較多時間",
          value: 40,
        },
        {
          answerText: "一部分時間",
          value: 60,
        },
        {
          answerText: "小部分時間",
          value: 80,
        },
        {
          answerText: "沒有這種感覺",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>不健康影響了您的社會活動（如走親訪友）：</span>,
      answers: [
        {
          answerText: "所有的時間",
          value: 0,
        },
        {
          answerText: "大部分時間",
          value: 25,
        },
        {
          answerText: "一部分時間",
          value: 50,
        },
        {
          answerText: "小部分時間",
          value: 75,
        },
        {
          answerText: "沒有這種感覺",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>我好像比別人容易生病：</span>,
      answers: [
        {
          answerText: "絕對正確",
          value: 0,
        },
        {
          answerText: "大部分正確",
          value: 25,
        },
        {
          answerText: "不能肯定",
          value: 50,
        },
        {
          answerText: "大部分錯誤",
          value: 75,
        },
        {
          answerText: "絕對錯誤",
          value: 100,
        },
      ],
    },
    {
      questionText: <span>我跟周圍人一樣健康：</span>,
      answers: [
        {
          answerText: "絕對正確",
          value: 100,
        },
        {
          answerText: "大部分正確",
          value: 75,
        },
        {
          answerText: "不能肯定",
          value: 50,
        },
        {
          answerText: "大部分錯誤",
          value: 25,
        },
        {
          answerText: "絕對錯誤",
          value: 0,
        },
      ],
    },
    {
      questionText: <span>我認為我的健康狀況在變壞：</span>,
      answers: [
        {
          answerText: "絕對正確",
          value: 0,
        },
        {
          answerText: "大部分正確",
          value: 25,
        },
        {
          answerText: "不能肯定",
          value: 50,
        },
        {
          answerText: "大部分錯誤",
          value: 75,
        },
        {
          answerText: "絕對錯誤",
          value: 100,
        },
      ],
    },
    /////////////////////////////////////////////////////////
    {
      questionText: <span>我的健康狀況非常好：</span>,
      answers: [
        {
          answerText: "絕對正確",
          value: 100,
        },
        {
          answerText: "大部分正確",
          value: 75,
        },
        {
          answerText: "不能肯定",
          value: 50,
        },
        {
          answerText: "大部分錯誤",
          value: 25,
        },
        {
          answerText: "絕對錯誤",
          value: 0,
        },
      ],
    },
  ],
  headers: [
    <span>
      以下這些問題都和日常活動有關。請您想一想，您的健康狀況是否限制了這些活動？如果有限制，程度如何？
    </span>,
    <span>
      在過去4個星期裡，您的工作和日常活動有無因為身體健康的原因而出現以下這些問題？
    </span>,
    <span>
      在過去4個星期裡，您的工作和日常活動有無因為情緒的原因（如壓抑或憂慮）而出現以下這些問題？
    </span>,
    <span>
      以下這些問題是關於過去4個星期裡您自己的感覺，對每一條問題所說的事情，您的情況是什麼樣的？
      <br />
      在過去4個星期裡有多少時間…
    </span>,
    <span>請看下列每一條問題，哪一種答案最符合您的情況？</span>,
  ],
};

export default chineseQuestionnaire;
