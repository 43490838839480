import React, { useEffect } from "react";

// layout imports
import Header from "./components/Header/Header";
import Footer from "./components/footer/footer.component.tsx";

// import Login from "./components/auth/Login";
import Alert from "./components/layout/Alert";

// routes imports
import AdminRoute from "./components/routes/AdminRoute";
import ManagerRoute from "./components/routes/ManagerRoute";
import { DirectorRouter } from "./routes/DirectorRoutes";

// pages imports
import login from "./pages/login";
import NotFound from "./pages/not-found/index.tsx";
import ComingSoon from "./pages/coming-soon/index.tsx";
import ResetPassword from "./pages/reset-password/reset-password.component.jsx";
import ForgotPassword from "./pages/forgotPassword";

// Spanish pages
import es_login from "./pages/es_US/login";
import EsManagerRoute from "./components/routes/EsManagerRoute";

// Taiwanese pages
import tw_login from "./pages/zh_TW/login";
import TwManagerRoute from "./components/routes/TwManagerRoute";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Demo from "./pages/demo";
import { Surveys } from "./components/surveys/Surveys";
import AlertContextProvider from "./context/AlertContext";
import ParticipantsSurveysContextProvider from "./context/ParticipantsSurveysContext";
import AlertOLD from "./components/layout/AlertOLD";
import ConsentWrapper from "./components/consents/ConsentWrapper";

// style components
import Container from "react-bootstrap/Container";
import 'react-calendar/dist/Calendar.css';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <ParticipantsSurveysContextProvider>
      <AlertContextProvider>
        <Provider store={store}>
          <Router>
            <Header />
            <Container className="my-3">
              <Alert />
              <AlertOLD />
              <Switch>
                <Route path="/" component={login} exact />
                <Route path="/demo" component={Demo} />
                <Route path="/demonstration" component={Demo} />
                <Route path="/centers" component={ComingSoon} />
                <Route path="/about" component={ComingSoon} />
                <Route path="/contact-us" component={ComingSoon} />
                <Route path="/login" component={login} exact />
                <Route path="/user" component={ComingSoon} />
                <Route path="/manager/" component={ManagerRoute} />
                <Route path="/admin" component={AdminRoute} />
                <Route path="/director" component={DirectorRouter} />
                <Route path="/survey/:surveyId" component={Surveys} />
                <Route
                  path="/forgot-password"
                  component={ForgotPassword}
                  exact
                />
                <Route path="/forgot-password/:id" component={ResetPassword} />
                <Route path="/consent/:language" component={ConsentWrapper} />
                <Route path="/es/manager" component={EsManagerRoute} />
                <Route path="/es" component={es_login} />
                {/* Taiwanese routes */}
                <Route path="/tw/manager" component={TwManagerRoute} />
                <Route path="/tw" component={tw_login} />
                <Route path="/" component={NotFound} />
              </Switch>
            </Container>
            <Footer />
          </Router>
        </Provider>
      </AlertContextProvider>
    </ParticipantsSurveysContextProvider>
  );
};

export default App;
