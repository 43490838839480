import React from "react";

// styles components
import Container from "react-bootstrap/Container";

export default () => {
  return (
    <Container className="mb-5">
      <h1>Routine Outcome monitoring Instructions</h1>
      <div>
        <h2>Words</h2>
        <div>
          <h4>PID:</h4>
          <p>
            Participant Identification Number, a four-digit number assigned to a
            participant by our system to identify them for surveys using the
            Routine Outcome Monitoring tools
          </p>
        </div>
        <div>
          <h4>RAND SF-36:</h4>
          <p>
            A 36-question survey created by RAND as part of the Medical Outcome
            Study.
          </p>
        </div>
        <div>
          <h4>ROM Managers</h4>
          <p>
            Accounts tied specifically to an individual Narconon location. ROM
            Managers are able to create new PIDs for participants, administer
            new surveys securely over the Web, and view and print personal
            progress of participants through the Narconon program.
          </p>
        </div>
        <div>
          <h4>ROM Admins</h4>
          <p>
            Accounts that have the ability to create new ROM Manager logins, and
            view aggregate data for all ROM Manager accounts.
          </p>
        </div>
      </div>
      <div>
        <h2>Introduction</h2>
        <p>
          The Routine Outcome Monitoring tool provides practitioners and survey
          participants a way to track their quality of life in eight major
          categories. Site Managers are able to anonymously sign up new
          participants and have them fill out the survey and generate instant
          graphic representation of the results.{" "}
        </p>
        <p>
          ROM Admins are able to create new manager accounts as well as view
          aggregate graph data for centers across the world.
        </p>
      </div>
      <div>
        <h2>Admin Dashboard</h2>
        <p>
          The Admin Dashboard is the central hub for your admin account. It has
          links to the manager account creator, the Graph Browser, to create
          graphs outlining overall Narconon aggregate graphs, as well as
          individual graphs, as well as a link to your account settings.
        </p>
      </div>
      <div>
        <h2>Center Registration</h2>
        <p>
          The Center Registration page allows an admin to create a brand new ROM
          Manager login for a Narconon. The admin will need to provide a center
          name, password, and location logo for new centers being created. It's
          important to note that each ROM Manager account created is tied to one
          Narconon center, and each Narconon center can only have one ROM
          Manager account.
        </p>
        <p>
          The admin will need to enter the center name for the new login. This
          can be anything, but the center name is usually styled in the form of
          "Narconon LOCATION". For instance, Narconon South Dekota, or Narconon
          Toronto. The center name is treated as a username or account name, and
          must be entered by the ROM Manager when they log in exactly as is
          entered by the ROM Admin when creating the account.
        </p>
        <p>
          When creating an account, the admin will also create a password for
          the new ROM Manager account. Managers can change this password in
          their own Account Settings when they've logged into, so it's best to
          create a simple password and have the manager change it to something
          personal, and impossible to guess, when they first log in.
        </p>
        <p>
          It is up to the ROM Admin creating the manager account to securely
          send the center name and password to the terminal responsible for that
          manager account.
        </p>
        <p>
          Lastly, a center logo is required to create an account. This will
          display on the manager dashboard of the account when they log in.
        </p>
      </div>
      <div>
        <h2>Survey Center</h2>
        <p>
          The Survey Center allows you to view and save graphs taken from
          participant survey data across the eight RAND SF-36 survey catagories.
          When in the Survey Center, select a Narconon center from the drop-down
          menu which you wish to view, and a graph will come up with their
          results. The default surveys displayed are the RAND US population
          norms, and any surveys the participants have filled out. Clicking the
          labels at the bottom of the graph will disable that survey and hide it
          from the graph. Clicking a disabled survey will re-enable it again and
          display it on the graph. This gives you full control of comparing
          whichever surveys you want for optimal analysis.
        </p>
        <p>
          To save graphs on your local machine, select the hamburger menu in the
          top right of the graph frame (the three veritical bars) and choose
          which format you would live to save the results as.
        </p>
      </div>
      <div>
        <h2>Account Settings</h2>
        <p>
          The Account Settings page allows you to update your profile settings,
          such as your password for your account.
        </p>
      </div>
    </Container>
  );
};
