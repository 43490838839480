import React from "react";
import { Link } from "react-router-dom";

// styles components
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";

export default () => {
  const links = [
    {
      body: "Dashboard",
      url: "/admin",
    },
    {
      body: "Instructions",
      url: "/admin/guide",
    },
    {
      body: "Register Center",
      url: "/admin/register-center",
    },
    {
      body: "Graph Browser",
      url: "/admin/graph-browser",
    },
    {
      body: "Account Settings",
      url: "/admin/settings",
    },
  ];
  return (
    <Card className="mx-auto p-2">
      <Row className="mx-auto">
        <Nav
          variant="pills"
          defaultActiveKey="/manager"
          className="flex-column mx-auto"
        >
          <h2 className="text-center">Admin Dashboard</h2>
          {links.map((link) => (
            <Nav.Item key={link.url}>
              <Nav.Link
                as={Link}
                href={link.url}
                to={link.url}
                className="text-center"
              >
                {link.body}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Row>
    </Card>
  );
};
