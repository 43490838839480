import React, { createContext, useState } from "react";

export const AlertContext = createContext();

const AlertContextProvider = props => {
  const [msg, setMsg] = useState(null);
  const addMsg = msg => setMsg(msg);
  const delMsg = () => setMsg(null)
  return (
    <AlertContext.Provider value={{ msg, addMsg, delMsg }}>
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
