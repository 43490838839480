import React from "react";
import Chart from "react-apexcharts";

import { calculateRandScores } from "./utils";
import { graphObj } from "./types";

interface Props {
  title: string;
  answers: graphObj[];
}

const RandChart = ({ title, answers }: Props) => {
  const options = {
    chart: {
      background: "#fcfdff",
      foreColor: "#333",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        autoSelected: "zoom",
      },
    },
    colors: ["#537287", "#008ffb", "#F0A202", "#D5CFE1", "#F05D5E"],
    xaxis: {
      categories: [
        "Physical Functioning",
        "Physical Health",
        "Emotional Problems",
        "Energy/fatigue",
        "Emotional well-being",
        "Social functioning",
        "Pain",
        "General health",
      ],
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 100,
    },
    legend: {
      showForSingleSeries: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "75%",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value: number) {
        if (value > 4) return value;
        else return "";
      },
    },
    title: {
      text: title,
      align: "center",
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: "1.2rem",
      },
    },
  };

  let series = [
    {
      name: "US Rand Averages",
      data: [71, 53, 66, 52, 70, 79, 71, 57],
    },
  ];

  answers.forEach((graphObj, key) => {
    if (graphObj.isVisible) {
      const randCatagories = calculateRandScores(graphObj.answers);
      series.push({
        name: `Survey ${key + 1}`,
        data: [
          randCatagories[0],
          randCatagories[1],
          randCatagories[2],
          randCatagories[3],
          randCatagories[4],
          randCatagories[5],
          randCatagories[6],
          randCatagories[7],
        ],
      });
    }
  });

  return (
    <div className="chartContainer">
      <Chart
        options={options}
        series={series}
        type="bar"
        height="350"
        width="100%"
      />
    </div>
  );
};

export default RandChart;
