import React from "react";
import { PIDBrowserWrapper } from "../../../components/pid-browser/";

export const PidBrowser = () => {
  return (
    <div>
      <PIDBrowserWrapper />
    </div>
  );
};
