import axios from "axios";
import {
  // REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT
} from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

// Load User
export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/api/v1/auth/me");
    console.log("res",res)
    dispatch({
      type: USER_LOADED,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Register User
export const register = ({
  fname = null,
  lname = null,
  email = null,
  password = null,
  role = null,
  center = null
}) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ fname, lname, email, password, role, center });

  try {
    const res = await axios.post("/api/v1/auth/register", body, config);

    // dispatch({
    //   type: REGISTER_SUCCESS,
    //   payload: res.data
    // });
    return { success: true, data: res };
  } catch (err) {
    const errors = err.response.data.error.split(",");

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error, "danger")));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

// Login User
export const login = (centerName, password) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ centerName, password });

  try {
    const res = await axios.post("/api/v1/auth/login", body, config);
    if (!res.data.success) {
      dispatch(setAlert(res.data.error, "danger"))
      dispatch({
        type: LOGIN_FAIL
      });
    } else {
      console.log("res: ", res)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      console.log("data: ", res.data)

      dispatch(loadUser());
    }
  } catch (err) {
    const errors = err.response.data.error.split(",");

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Logout / Clear Profile
export const logout = () => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  await axios.get("/api/v1/auth/logout", config);

  dispatch({ type: LOGOUT });
};
