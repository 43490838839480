import React, { useState, useEffect } from "react";
import DisplayCenters from "./DisplayCenters";
import axios from "axios";
import { useForm } from "../../hooks/useForm";
import { Message } from "../layout/Message";

const RegisterManager = () => {
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [values, handleChange] = useForm({
    fname: "Manager",
    lname: "manager",
    password: "",
    password2: "",
    email: ""
  });
  const [message, setMessage] = useState("");

  // const store = useSelector(store => store);

  const fetchCenters = async () => {
    setIsLoading(true);
    const query = "/api/v1/centers/?select=name";
    const res = await axios.get(query);
    const centers = res.data.data;
    setIsLoading(false);
    setCenters(centers);
  };

  const handleCenterChange = e => {
    const center = findCenterFromCenters(e.target.value);
    if (center) setSelectedCenter(center);
  };

  const findCenterFromCenters = v => centers.find(c => c.name === v);

  const onSubmit = async e => {
    e.preventDefault();
    if (selectedCenter) addManagerToDB();
  };

  const addManagerToDB = async () => {
    const config = { headers: { "Content-Type": "application/json" } };
    const body = JSON.stringify({
      role: "manager",
      center: selectedCenter.id,
      fname: values.fname,
      lname: values.lname,
      email: values.email.toLowerCase(),
      password: values.password
    });
    const res = await axios.post("/api/v1/auth/register", body, config);
    if (res.data.success) return setMessage("Manager created");
    else return setMessage("There was an error creating the manager");
  };

  useEffect(() => {
    fetchCenters();
  }, []);

  return (
    <>
      {message && <Message msg={message} setMsg={setMessage} />}
      {isLoading ? (
        <h1>Loading data...</h1>
      ) : (
        <form className="form form--card form--card--wide">
          <h3 className="form__title">REGISTER MANAGER</h3>
          <div className="form--card__fieldset">
            <div className="form__section">
              <label className="form__label">Select Center</label>
              <DisplayCenters
                centers={centers}
                selectedCenter={selectedCenter}
                handleChange={handleCenterChange}
              />
            </div>
            <div className="form__section">
              <div className="form__label">Email</div>
              <input
                className="form__input"
                value={values.email}
                onChange={handleChange}
                name="email"
              />
            </div>
            <div className="form__section">
              <div className="form__label">Password</div>
              <input
                className="form__input"
                value={values.password}
                onChange={handleChange}
                name="password"
                type="password"
              />
            </div>
            <div className="form__section">
              <div className="form__label">Confirm password</div>
              <input
                className="form__input"
                value={values.password2}
                onChange={handleChange}
                name="password2"
                type="password"
              />
            </div>
          </div>
          <button onClick={onSubmit} className="btn btn--sm btn--formSubmit">
            REGISTER
          </button>
        </form>
      )}
    </>
  );
};

export default RegisterManager;
