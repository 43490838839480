import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import fallbackUrl from "../../assets/images/defaultCenterAvatar.png";

export default () => {
  const store = useSelector((store) => store);

  const protocol = window.location.protocol;
  const centerId = store.auth.user.id;
  const host = window.location.host;

  const [url, setUrl] = useState(
    `${protocol}//${host}/api/v1/centers/${centerId}/uploads`
  );

  return (
    <span>
      <Image
        src={url}
        onError={() => setUrl(fallbackUrl)}
        alt="profile image"
        className="mx-auto"
        fluid
        thumbnail
      />
    </span>
  );
};
